/* eslint-disable react/no-unknown-property */
import React from 'react'
import { InlineLoading } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { VictoryGroup, VictoryGroupProps, VictoryLine } from 'victory'
import { Box, Flex } from 'src/next/components'
import BoundingSize from 'src/next/components/BoundingSize'
import theme from 'src/next/themes/victory-carbon-theme'

const noDataLine = [
  { x: 0, y: 0 },
  { x: 1, y: 0 },
]

export interface SparklineProps extends VictoryGroupProps {
  isLoading?: boolean
  width?: number
  height?: number
}

export const Sparkline = ({
  isLoading,
  data,
  width,
  height,
  ...props
}: SparklineProps) => {
  const { t } = useTranslation()

  return (
    <BoundingSize
      defaultWidth={width}
      defaultHeight={height}
      render={({ width, height }) => {
        if (!width && !height) {
          return null
        }

        return (
          <Box position="relative">
            <VictoryGroup
              data={data?.length ? data : noDataLine}
              theme={theme}
              width={width}
              height={height}
              padding={0}
              domainPadding={1}
              {...props}
              y={data?.length ? props.y : 'y'}
            >
              <VictoryLine />
            </VictoryGroup>
            {isLoading || !data?.length ? (
              <Flex
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                justifyContent="center"
                alignItems="center"
              >
                {isLoading ? (
                  <InlineLoading
                    status="active"
                    iconDescription={t('Common.Loading')}
                    description={t('Common.Loading')}
                  />
                ) : !data?.length ? (
                  <div
                    css={css`
                      color: var(--cds-text-secondary);
                    `}
                  >
                    {t('Common.NoDataAvailable')}
                  </div>
                ) : null}
              </Flex>
            ) : null}
          </Box>
        )
      }}
    />
  )
}
