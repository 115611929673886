/* eslint-disable react/no-unknown-property */
import { useEffect, useMemo, useState } from 'react'
import { Button } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { InfiniteData } from 'react-query'
import {
  ListManagementResourcesResponse,
  ManagementResourceCapacity,
  ManagementResourceType,
  ManagementResource,
} from '@cloudnatix-types/dashboard'
import { useClusters, useManagementResources } from 'src/api'
import { DatePickerMenu, Flex, maxDate, Link } from 'src/next/components'
import DataTable, {
  DataTableNestedPagination,
  useTableControls,
} from 'src/next/components/DataTable'
import { FeatureInfo } from 'src/next/components/FeatureInfo'
import {
  InlineNotification,
  ResetFiltersLink,
} from 'src/next/components/InlineNotification'
import { MiddleTruncate } from 'src/next/components/MiddleTruncate'
import {
  TableFilterToolbarActions,
  useTableFilter,
} from 'src/next/components/TableFilter'
import { WorkloadLabels } from 'src/next/components/Workloads/WorkloadsTable/components'
import { useGetTimeframe, useOrgSelection } from 'src/next/hooks'
import { Timeframe, TimeIntervals } from 'src/next/types'
import {
  bytesToUserFriendlySize,
  currencyFormat,
  millicoresToUserFriendlySizeLong,
} from 'src/next/utils'
import {
  ResourceAllocateModal,
  useResourceAllocateModal,
} from './ResourcesAllocateModal'

const CapacityLabel = ({
  cpuMillicores,
  memoryBytes,
  diskBytes,
}: ManagementResourceCapacity) => {
  const { t } = useTranslation()

  return (
    <>
      <pre>
        {millicoresToUserFriendlySizeLong(Number(cpuMillicores) || 0)}{' '}
        {t('Common.CPU')}
      </pre>
      <pre>
        {bytesToUserFriendlySize(memoryBytes || 0)} {t('Common.Mem')}
      </pre>
      <pre>
        {bytesToUserFriendlySize(diskBytes || 0)} {t('Common.Disk')}
      </pre>
    </>
  )
}

export const useFormatRows = (
  data: InfiniteData<ListManagementResourcesResponse> | undefined,
  page: number,
) => {
  const { t } = useTranslation()

  const currentPageData = data?.pages?.[page - 1] || {}

  const resources = useMemo(
    () => currentPageData.resources || [],
    [currentPageData.resources],
  )

  const getResourceLink = (resource: ManagementResource): string => {
    switch (resource.type) {
      case ManagementResourceType.CLUSTER:
        return `/app/clusters/${resource.uuid}`
      case ManagementResourceType.COMPUTE_INSTANCE:
        return `/app/vm-workload/${encodeURIComponent(resource.uuid!)}`
      default:
        return ''
    }
  }

  const formattedRows = useMemo(
    () =>
      resources.map(resource => {
        return {
          ...resource,
          id: resource.uuid!,
          name: (
            <Link to={getResourceLink(resource)}>
              <MiddleTruncate text={resource.name || ''} />
            </Link>
          ),
          spend: t('Common.Value/hr', {
            value: currencyFormat(resource.spend || 0),
          }),
          labels: resource.labels?.length ? (
            <WorkloadLabels labels={resource.labels} />
          ) : (
            ''
          ),
          capacity: <CapacityLabel {...resource.resourceCapacity} />,
        }
      }) || [],
    [resources],
  )

  return formattedRows
}

export const ResourcesTable = () => {
  const { t } = useTranslation()

  // Pin the current time on the first render so that useGetTimeframe() returns
  // the same interval while the result table with pagination is used by the
  // user.
  const [pinnedEnd, setPinnedEnd] = useState<Date>(() => new Date())

  const [timeInterval, setTimeInterval] = useState<TimeIntervals>('days')

  const [dates, setDates] = useState<Timeframe[]>([])
  const datePicked = dates.length !== 0

  const { startTimeNs, endTimeNs } = useGetTimeframe({
    timeInterval,
    start: datePicked ? dates[0] : undefined,
    end: datePicked ? dates[1] : pinnedEnd,
  })

  // Even when the mode is switched between 2 non-custom modes (e.g. 2 hours
  // and 2 days), `DatePickerMenu` sets a new empty array to trigger this
  // useEffect().
  useEffect(() => {
    if (datePicked) {
      return
    }

    setPinnedEnd(new Date())
  }, [dates])

  const [orgUuid] = useOrgSelection()
  const headers = useHeaders()

  const allocateModalProps = useResourceAllocateModal()

  const { pagination, orderBy } = useTableControls(
    'admin-resources-table-page-size',
  )

  const { resetPage, setPage, ...dataTablePaginationProps } = pagination

  const { data } = useClusters()

  const { filters, methods, reset, activeFiltersCount } = useTableFilter()

  // reset pagination when filters change
  methods.watch(() => resetPage())

  // Set cluster uuid when cluster name is active filter
  if (filters.clusterName) {
    filters.clusterUuid = data?.clusters?.find(
      c => c.name === filters.clusterName,
    )?.uuid
  }

  // When cluster name is set, cluster uuid is needed for the resources api call. Disable the query till cluster uuid is set.
  const hasClusterFilter = !!filters.clusterUuid || !filters.clusterName

  const query = useManagementResources(
    {
      filter: {
        orgUuid,
        startTimeNs: startTimeNs.toString(),
        endTimeNs: endTimeNs.toString(),
        ...filters,
      },
      ...(orderBy.value && { orderBy: orderBy.value }),
      pageSize: pagination.pageSize,
    },
    {
      enabled: hasClusterFilter,
    },
  )

  const formattedRows = useFormatRows(query.data, pagination.page)

  return (
    <>
      <Flex flexDirection="column" overflow="auto">
        <DataTable
          storageKey="admin-resources-table"
          size="xl"
          headers={headers}
          rows={formattedRows}
          isLoading={query.isFetching}
          pageSize={pagination.pageSize}
          orderBy={orderBy}
          setPage={setPage}
          toolbar={
            <Flex justifyContent="space-between" width={1}>
              <FeatureInfo tooltipText={t('Resources.FeatureInfo')} />
              <Flex>
                <TableFilterToolbarActions />
                <Flex>
                  <DatePickerMenu
                    id="date-time-picker-menu"
                    setDates={setDates}
                    datePickerType="range"
                    dates={dates}
                    maxDate={maxDate}
                    withTimeInput
                    setTimeInterval={setTimeInterval}
                    timeInterval={timeInterval}
                  />
                </Flex>
              </Flex>
            </Flex>
          }
          {...{
            primaryAction: (
              <Button onClick={() => allocateModalProps.setOpen(true)}>
                {t('Resources.Allocation.CreateRule')}
              </Button>
            ),
          }}
        />
        {(!query.isFetching && !formattedRows?.length) || query.isError ? (
          <InlineNotification title={t('Resources.NoData')}>
            <ResetFiltersLink
              activeFiltersCount={activeFiltersCount}
              reset={reset}
            />
          </InlineNotification>
        ) : (
          <DataTableNestedPagination
            {...dataTablePaginationProps}
            query={query}
          />
        )}
      </Flex>
      {allocateModalProps.open ? (
        <ResourceAllocateModal {...allocateModalProps} />
      ) : null}
    </>
  )
}

const useHeaders = () => {
  const { t } = useTranslation()

  return useMemo(
    () => [
      {
        key: 'name',
        header: t('Organizations.DataTable.Name'),
        sort: 'name',
      },
      { key: 'type', header: t('Organizations.Type'), sort: 'type' },
      {
        key: 'orgName',
        header: t('Resources.OrgOwner'),
      },
      { key: 'csp', header: t('Organizations.DataTable.Csp'), sort: 'csp' },
      { key: 'region', header: t('Common.Region'), sort: 'region' },
      {
        key: 'resourceClass',
        header: t('Resources.ResourceClass'),
        sort: 'resource_class',
      },
      {
        key: 'resourceOwnerName',
        header: t('Resources.ResourceOwnerName'),
      },
      { key: 'labels', header: t('Organizations.Labels') },
      { key: 'spend', header: t('Common.Spend'), sort: 'spend' },
      {
        key: 'capacity',
        header: t('Common.Capacity'),
        sort: 'cpu_millicores',
      },
    ],
    [t],
  )
}
