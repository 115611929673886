import React, { useCallback, useMemo, useRef } from 'react'
import dayjs from 'dayjs'
import maxBy from 'lodash/maxBy'
import {
  AreaGraphProps,
  GraphConfig,
  GraphDataPoint,
  GraphUnit,
} from 'src/next/components/Graphs'
import { Graph, GraphProps } from 'src/next/components/Graphs/Graph'
import GraphTooltip from 'src/next/components/Graphs/GraphTooltip'
import GraphTooltipContainer from 'src/next/components/Graphs/GraphTooltipContainer'
import { listFormat } from 'src/next/containers/WorkloadRecommendations/utils'
import { unique } from 'src/next/utils/array'
import { createFormatFn } from 'src/next/utils/graph.utils'

export interface StreamChartProps extends Omit<GraphProps, 'data' | 'unit'> {
  data: AreaGraphProps[]
  unit: GraphUnit
  centerY?: boolean
}

export const StreamChart = ({
  data,
  centerY,
  unit,
  xAxis,
  ...rest
}: Omit<StreamChartProps, 'stack'>) => {
  const maxValue = useRef(0)

  const formatUnit = createFormatFn(unit, {
    cpu: { shortDisplayValue: true },
    memory: { maximumFractionDigits: 0 },
  })
  /**
   * Take graph data and add a "ghost" graph. The ghost graph will render
   * transparent and pushes the stacked graphs up, so it will look like
   * a stream chart (shape of top and bottom follow the same shape)
   */
  const streamData = useMemo(() => {
    // Determine interpolation type of all graphs
    const interpolation = unique(
      data.map(graph => graph?.props?.interpolation),
    ) as string[]
    if (interpolation.length > 1) {
      throw new Error(
        `StreamChart needs all graph interpolations to be of the same type (found ${listFormat(
          interpolation,
          { wrapInQuotes: true },
        )})`,
      )
    }

    const sumValuesPerX: GraphDataPoint[] = Object.values(
      data
        .filter(
          // When enabled is not provided, we still want to show the graph.
          graph => !(typeof graph.enabled === 'boolean' && !graph.enabled),
        )
        .flatMap(graph => graph.data)
        .reduce((acc: any, item: any) => {
          acc[item.x] = acc[item.x]
            ? (acc[item.x] = {
                x: item.x,
                y: acc[item.x].y + item.y,
              })
            : item
          return acc
        }, {}),
    )

    maxValue.current = sumValuesPerX.length
      ? maxBy(sumValuesPerX, (item: GraphDataPoint) => item.y)?.y!
      : 1

    const ghostGraph = {
      id: 'ghost',
      type: 'area',
      props: {
        style: { data: { fill: 'transparent' } },
        interpolation: interpolation[0],
      },
      data: sumValuesPerX.map(({ x, y }) => ({
        x,
        y: maxValue.current / 2 - Number(y) / 2,
      })),
    }

    return [ghostGraph, ...data] as GraphConfig[]
  }, [data])

  const xAxisConfig = xAxis || [
    {
      tickFormat: x => x,
    },
  ]

  const yAxisConfig = [
    {
      tickFormat: (tick: number) => {
        return formatUnit(centerY ? tick - maxValue.current / 2 : tick)
      },
      tickValues: centerY
        ? [0, maxValue.current / 2, maxValue.current]
        : undefined,
    },
  ]

  const getTooltipHeading = useCallback(activePoint => {
    const date = dayjs(activePoint?.x)
    return date.isValid() ? date.format('LLL') : ''
  }, [])

  return (
    <Graph
      unit={unit}
      yAxis={yAxisConfig}
      xAxis={xAxisConfig}
      data={streamData}
      stack
      normalizeScale={false}
      containerComponent={GraphTooltipContainer(
        <GraphTooltip
          graphConfig={streamData}
          heading={getTooltipHeading}
          reverseOrder
        />,
      )}
      {...rest}
    />
  )
}
