import { Workload as CNWorkload } from '@cloudnatix-types/dashboard'
import { SliderStateRequest } from 'src/next/containers/WorkloadRecommendations'

export enum ChartLegend {
  'avg_usage' = 'avg_usage',
  'limit' = 'limit',
  'max_usage' = 'max_usage',
  'configured_request' = 'configured_request',
  'recommended_request' = 'recommended_request',
  'recommended_limit' = 'recommended_limit',
  'user_configured_request' = 'user_configured_request',
  'pod_evictions' = 'pod_evictions',
  'recommended_request_range' = 'recommended_request_range',
  'configured_limit' = 'configured_limit',
  'vpa_status' = 'vpa_status',
}

export interface Workload extends Omit<CNWorkload, 'uid' | 'status'> {
  uid: string // Make uid required
  status: Status // Make status more specific
}

export enum WorkloadStatus {
  Pending = 'Pending',
  Running = 'Running',
  Succeed = 'Succeed',
  Failed = 'Failed',
  Unknown = 'Unknown',
}
export type Status = {
  [key in WorkloadStatus]?: number
}

export type WorkloadRecommendationsMetric = 'cpu' | 'memory'

interface SliderProps {
  value: number | ''
  persist?: boolean
}

export type OnChangeRecommendationSlider = ({
  value,
  persist,
}: SliderProps) => void

export type GraphColor =
  | 'var(--carbonPalette1)'
  | 'var(--carbonPalette2)'
  | 'var(--carbonPalette3)'
  | 'var(--carbonPalette4)'
  | 'var(--carbonPalette5)'
  | 'var(--carbonPalette6)'
  | 'var(--carbonPalette7)'
  | 'var(--carbonPalette8)'
  | 'var(--carbonPalette9)'
  | 'var(--carbonPalette10)'
  | 'var(--carbonPalette11)'
  | 'var(--carbonPalette12)'
  | 'var(--carbonPalette13)'
  | 'var(--carbonPalette14)'

export type DisabledGraphColor = 'var(--carbonGrayScale2)'

export interface SliderRecommendation {
  containerName: string | undefined
  cpu: SliderStateRequest
  memory: SliderStateRequest
}

export enum CostSlider {
  min = 0,
  default = 100,
  max = 100,
}
