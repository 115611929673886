import { useQuery } from 'react-query'
import { ListComputeInstanceTagKeysResponse } from '@cloudnatix-types/dashboard'
import { fetchData, useRequestParams } from 'src/api'

const url = 'v1/dashboard/infrastructure/compute/instancetagkeys'

export const useInstanceTagKeysQuery = () => {
  const params = useRequestParams({ addOrgFilter: true })

  return useQuery<ListComputeInstanceTagKeysResponse>(
    [url, params],
    () => fetchData<ListComputeInstanceTagKeysResponse>(url, params),
    {
      cacheTime: 3600000,
    },
  )
}
