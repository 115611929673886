import { ReactNode, useMemo } from 'react'
import { DataTableRow } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { ComputeInstanceGroupingKeyKey } from '@cloudnatix-types/dashboard'
import { Flex } from 'src/next/components'
import DataTable, { useOrderBy } from 'src/next/components/DataTable'
import { InlineNotification } from 'src/next/components/InlineNotification'
import { DataTableHeader } from 'src/next/types/dataTable'

interface TabDataTableProps {
  id: keyof typeof ComputeInstanceGroupingKeyKey | 'namespace'
  headers: DataTableHeader[]
  rows: DataTableRow[]
  overflowMenu: ReactNode
  isLoading?: boolean
}

export const TabDataTable = ({
  id,
  headers,
  rows,
  overflowMenu,
  isLoading = false,
}: TabDataTableProps) => {
  const { t } = useTranslation()

  const testId = `dashboard-data-table-${id.toLowerCase()}`

  const orderBy = useOrderBy()
  const [column, direction] = orderBy.value?.split(' ') || []

  const orderedRows = useMemo(() => {
    const result = [...rows]

    result.sort((a, b) => {
      if (column === '' || column === 'id') {
        return a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      }

      const valueA = a[column as keyof DataTableRow]
      const valueB = b[column as keyof DataTableRow]

      if (valueA === undefined) {
        return 1
      }

      if (valueB === undefined) {
        return -1
      }

      return valueA - valueB
    })

    if (direction === 'desc') {
      result.reverse()
    }

    return result
  }, [rows, column, direction])

  return (
    <div data-testid={testId}>
      <DataTable
        size="md"
        key={`${column}-${direction}`}
        storageKey={testId}
        headers={headers}
        isLoading={isLoading}
        rows={orderedRows}
        orderBy={orderBy}
        toolbar={
          <Flex alignSelf="center" alignItems="stretch">
            {overflowMenu}
          </Flex>
        }
      />
      {!isLoading && !rows?.length ? (
        <InlineNotification
          title={`${t('Dashboard.Common.DataTable.NoData')}`}
        />
      ) : null}
    </div>
  )
}
