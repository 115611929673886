/* eslint-disable react/display-name */
import React, {
  forwardRef,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { DatePicker, DatePickerInput } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box } from '../Box'
import { MeridiemType, timeFormatRegex, TimeInput } from '../TimeInput'

type oneToNine = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
type zeroToNine = 0 | oneToNine

type YYYY = `19${zeroToNine}${zeroToNine}` | `20${zeroToNine}${zeroToNine}`

type MM = `0${oneToNine}` | `1${0 | 1 | 2}`

type DD = `${0}${oneToNine}` | `${1 | 2}${zeroToNine}` | `3${0 | 1}`

export type DateType = `${MM}/${DD}/${YYYY}`

export const maxDate = new Date().toLocaleDateString() as DateType

export interface DateTimePickerSharedInputProps {
  id: string
  datePickerType?: 'simple' | 'single' | 'range'
  maxDate?: DateType
  minDate?: DateType
  size?: 'sm' | 'md' | 'lg' | 'xl'
  withTimeInput?: boolean
}

export interface DateTimePickerComponentProps
  extends DateTimePickerSharedInputProps {
  value: Date[] | undefined
  setValue: Dispatch<SetStateAction<Date[]>>
}

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: var(--cds-spacing-05);
`

const TimeInputContainer = styled.div`
  width: 50%;
  height: 100%;
  gap: var(--cds-spacing-05);
`

const StyledDatePicker = styled(DatePicker)`
  .cds--date-picker {
    &-container {
      width: 100%;
    }

    &--range {
      display: flex;
      flex-direction: column;
      gap: var(--cds-spacing-05);
      width: 100%;
    }

    span {
      width: 100%;
    }

    &__input {
      width: 100%;
    }
  }
`

export const DateTimePicker = forwardRef<
  DatePicker,
  DateTimePickerComponentProps
>(
  (
    {
      id,
      maxDate,
      minDate,
      datePickerType = 'single',
      size = 'md',
      value = [],
      setValue,
      withTimeInput = false,
    },
    ref,
  ) => {
    const { i18n, t } = useTranslation()
    const [localStartTime, setLocalStartTime] = useState('')
    const [localEndTime, setLocalEndTime] = useState('')
    const [localStartMeridiem, setLocalStartMeridiem] =
      useState<MeridiemType>('PM')
    const [localEndMeridiem, setLocalEndMeridiem] = useState<MeridiemType>('PM')

    const updateTimePart = (
      value: Date,
      localTime?: string,
      localMeridiem?: MeridiemType,
    ) => {
      const dateObj = new Date(value)
      if (localTime) {
        const result = timeFormatRegex.exec(localTime)
        if (result !== null) {
          let localHour = parseInt(result[1], 10)
          if (localMeridiem === 'PM') {
            if (localHour != 12) {
              localHour += 12
            }
          } else if (localHour == 12) {
            localHour = 0
          }

          const localMinute = parseInt(result[2], 10)

          const localFullYear = dateObj.getFullYear()
          const localMonth = dateObj.getMonth()
          const localDay = dateObj.getDate()

          return new Date(
            localFullYear,
            localMonth,
            localDay,
            localHour,
            localMinute,
          )
        }
      }
      return dateObj
    }

    // Note that when called by the DatePicker via onChange, date here is set to
    // the beginning of the picked date in the local timezone.
    const updateValue = (date: Date[]) => {
      const startDate = date[0]
      const endDate = date[1]

      const updatedStart = updateTimePart(
        startDate,
        localStartTime,
        localStartMeridiem,
      )
      if (datePickerType === 'range' && date[1]) {
        const updatedEnd = updateTimePart(
          endDate,
          localEndTime || '11:59',
          localEndTime ? localEndMeridiem : 'PM',
        )
        setValue([updatedStart, updatedEnd])
      } else {
        setValue([updatedStart])
      }
    }

    const getTimeAndMeridiem = (time: Date) => {
      return time
        .toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
        .split(' ')
    }

    useEffect(() => {
      if (value[0]) {
        const start = getTimeAndMeridiem(value[0])
        setLocalStartTime(start[0])
        setLocalStartMeridiem(start[1] as MeridiemType)
      }
      if (value[1]) {
        const end = getTimeAndMeridiem(value[1])
        setLocalEndTime(end[0])
        setLocalEndMeridiem(end[1] as MeridiemType)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (value.length !== 0) updateValue(value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStartTime, localEndTime, localStartMeridiem, localEndMeridiem])

    return (
      <Container>
        <StyledDatePicker
          ref={ref}
          className="your-date-picker-container"
          maxDate={maxDate?.toString()}
          minDate={minDate?.toString()}
          datePickerType={datePickerType}
          value={value.map(
            x => new Date(x.getFullYear(), x.getMonth(), x.getDate()),
          )}
          onChange={e => {
            updateValue(e)
          }}
          locale={i18n.language}
        >
          <DatePickerInput
            id={`${id}-date-picker-start`}
            placeholder="MM/DD/YYYY"
            labelText={
              datePickerType === 'range'
                ? t('DateTimeRangePicker.StartDate')
                : t('DateTimeRangePicker.Date')
            }
            size={size}
          />

          {datePickerType === 'range' ? (
            <DatePickerInput
              id={`${id}-date-picker-end`}
              placeholder="MM/DD/YYYY"
              labelText={t('DateTimeRangePicker.EndDate')}
              size={size}
            />
          ) : null}
        </StyledDatePicker>

        {withTimeInput && (
          <TimeInputContainer>
            <TimeInput
              id={`${id}-time-input-start`}
              setMeridiem={setLocalStartMeridiem}
              meridiem={localStartMeridiem}
              setTime={setLocalStartTime}
              value={localStartTime}
              label={t('DateTimeRangePicker.StartTime')}
            />

            {datePickerType === 'range' && (
              <Box marginTop="1rem">
                <TimeInput
                  id={`${id}-time-input-end`}
                  setMeridiem={setLocalEndMeridiem}
                  meridiem={localEndMeridiem}
                  setTime={setLocalEndTime}
                  value={localEndTime}
                  label={t('DateTimeRangePicker.EndTime')}
                />
              </Box>
            )}
          </TimeInputContainer>
        )}
      </Container>
    )
  },
)
