import React, { ReactNode } from 'react'
import { Flex } from 'src/next/components'
import Typography from 'src/next/components/Typography'
import { css } from 'styled-components'

interface TabChartHeadingProps {
  title: string
  overflowMenu: ReactNode
}

export const TabChartHeading = ({
  title,
  overflowMenu,
}: TabChartHeadingProps) => (
  <Flex
    flex="1"
    justifyContent="space-between"
    alignItems="center"
    css={css`
      > .cds--dropdown__wrapper {
        grid-gap: unset;
      }
    `}
  >
    <Typography variant="productive-heading-02">{title}</Typography>
    {overflowMenu}
  </Flex>
)
