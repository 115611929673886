import { QueryObserverOptions, useQuery } from 'react-query'
import {
  ListKubernetesNamespacesRequest,
  ListKubernetesNamespacesResponse,
} from '@cloudnatix-types/dashboard'
import { fetchData, useRequestParams } from 'src/api'

const url = 'v1/dashboard/kubernetes/namespaces'

export const useNamespacesQuery = <T = ListKubernetesNamespacesResponse>(
  request: ListKubernetesNamespacesRequest,
  options?: QueryObserverOptions<ListKubernetesNamespacesResponse, any, T>,
) => {
  const params = useRequestParams({ request, addOrgFilter: true })

  return useQuery<ListKubernetesNamespacesResponse, any, T>(
    [url, params],
    () => fetchData<ListKubernetesNamespacesResponse>(url, params),
    {
      ...options,
    },
  )
}
