import { zip } from 'lodash'
import {
  GetTopComputeInstanceSummariesResponse,
  GetTopSummariesResponse,
  Summary,
} from '@cloudnatix-types/dashboard'
import { BarGraphProps } from 'src/next/components/Graphs'
import { GraphColor } from 'src/next/types/workloads'
import { unique } from 'src/next/utils/array'
import {
  createFormatFn,
  getCarbonPaletteCssVariable,
  getFormatType,
} from 'src/next/utils/graph.utils'
import { middleEllipsis } from 'src/next/utils/middle-ellipsis'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'

export type ColorMap = { id: string; color: GraphColor }[]

export type TransformedData = (Summary & {
  timestamp: number
})[][]

export const getColorMapTopSummaries = (data: TransformedData) => {
  return unique(
    data
      .flat()
      .map(item => {
        return item.groupingName!
      })
      .filter(Boolean)
      .sort(),
  ).map((o, i) => {
    return {
      id: `${o}`,
      color: getCarbonPaletteCssVariable(i),
    }
  })
}

export const createGraphConfig = (
  data: TransformedData,
  yAccessorKey: string,
  colorMap: ColorMap,
  selectedId?: string | null,
): BarGraphProps[] => {
  const formatTooltipValue = createFormatFn(getFormatType(yAccessorKey))

  return (
    data?.map((itemData, index) => ({
      id: `stacked-bar-${index}`,
      tooltipLabel: (pointData: any) =>
        middleEllipsis(pointData.groupingName, 15, 25),
      type: 'bar',
      data: itemData.map((item: any) => {
        const { groupingName } = item
        return {
          ...item,
          style: {
            data: {
              fill: colorMap.find(u => u.id === groupingName)?.color,
              opacity: !selectedId || selectedId === groupingName ? 1 : 0.3,
            },
          },
        }
      }),
      tooltipValueTransformFn: (_value: any, pointData: any) =>
        formatTooltipValue(pointData[yAccessorKey]) || '',
    })) || []
  )
}

type TopSummariesResponse = GetTopSummariesResponse &
  GetTopComputeInstanceSummariesResponse

export function getTransformTopSummariesData(data: TopSummariesResponse) {
  const result = data.topSummaries
    ? Object.values(data.topSummaries)
        .filter(item => !!item.summaries)
        // transform object + timestamp to milliseconds
        .map(({ summaries }) =>
          summaries?.map(summary => ({
            ...summary,
            timestamp: nanoToMilliSeconds(summary.timestampNs || 0),
          })),
        )
    : []

  // The stacked bar chart expects a data structure different from the API
  // returns (namely, different X-values combined, instead of the same).
  // `zip` accounts for this and regroups the passed arrays:
  // https://lodash.com/docs/#zip
  //
  // after doing so, we filter undefined values, which can arise when f.e.
  // organizations were added later.
  return zip(...result).map(item => item.filter(Boolean)) as TransformedData
}
