import round from 'lodash/round'

/**
 * converts millicores into a scaled representation for converting it into a
 * user friendly string.
 *
 * @export
 * @param {number} millicores
 * @param {number} [decimals=2]
 * @return {*}
 */
export function millicoresToUserFriendlySizeBase(
  millicores: number,
  decimals = 2,
): {
  value: number
  isMillicores: boolean
} {
  const coresRounded = round(millicores / 1000, decimals)
  const millicoresRounded = round(millicores, decimals)
  const isMillicores = millicoresRounded < 1000 && millicoresRounded > -1000
  const value = isMillicores ? millicoresRounded : coresRounded

  return {
    value,
    isMillicores,
  }
}

/**
 * converts millicores into a user friendly string. Short version.
 *
 * @export
 * @param {number} millicores
 * @param {number} [decimals=2]
 * @return {string}
 */
export function millicoresToUserFriendlySizeShort(
  millicores: number,
  decimals = 2,
): string {
  const { value, isMillicores } = millicoresToUserFriendlySizeBase(
    millicores,
    decimals,
  )

  const formattedValue = isNaN(value) ? '---' : value

  const unit = isMillicores ? 'm' : ''

  // E.g.
  // - '100m' for 100 millicores
  // - '100' for 100 cores
  return `${formattedValue}${unit}`
}

/**
 * converts millicores into a user friendly string. Long version.
 *
 * @export
 * @param {number} millicores
 * @param {number} [decimals=2]
 * @return {string}
 */
export function millicoresToUserFriendlySizeLong(
  millicores: number,
  decimals = 2,
): string {
  const { value, isMillicores } = millicoresToUserFriendlySizeBase(
    millicores,
    decimals,
  )

  const formattedValue = isNaN(value) ? '---' : value

  const unitSingular = isMillicores ? 'millicore' : 'core'
  // The unit is millicore when the value is 1. Otherwise, it's millicores.
  const unit = `${unitSingular}${
    isNaN(value) || Math.abs(value) !== 1 ? 's' : ''
  }`

  return `${formattedValue} ${unit}`
}
