/* eslint-disable react/display-name */
import React, { memo } from 'react'
import { Content, Header, HeaderMenuButton, SkipToContent } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { SideNav } from 'src/App/SideNav'
import useBreakpoint from 'src/next/hooks/useBreakpoint'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { HeaderRightMenu, HeaderTitle } from './AppHeader'

const StyledHeaderMenuButton = styled(HeaderMenuButton)`
  > svg {
    fill: var(--cds-icon-primary);
  }

  :hover > svg {
    fill: var(--cds-icon-on-color);
  }
`

const StyledHeader = styled(Header)`
  background-color: var(--cds-background);
  border-bottom: 0;

  .cds--header__global {
    .cds--btn--primary {
      color: var(--cds-icon-primary);
    }

    .cds--btn--primary:hover {
      color: var(--cds-text-on-color);
    }
  }
`

const StyledContent = styled(Content)<{
  $sideBarWidth: number
}>`
  position: relative;
  z-index: 0;
  padding-top: 70px;

  ${({ $sideBarWidth }) => `
    margin-left: ${$sideBarWidth}px;
    width: calc(100% - ${$sideBarWidth}px);
  `};
`

const sideBarIconsOnlyWidth = 48
const sideBarExpandedWidth = 256

interface AppShellProps {
  children: React.ReactNode
}

export const AppShell = memo(({ children }: AppShellProps) => {
  const { t } = useTranslation()
  const { isMobile, isDesktop } = useBreakpoint()
  const [isSideNavExpanded, setSidebarExpanded] = useLocalStorage(
    'sidebar-expanded',
    false,
  )

  const getMenuButtonLabel = (isExpanded: boolean) => {
    const openMenu = t('SideBar.AriaLabel.OpenMenu', 'Open menu')
    const minimizeMenu = t('SideBar.AriaLabel.MinimizeMenu', 'Minimize menu')
    const closeMenu = t('SideBar.AriaLabel.CloseMenu', 'Close menu')

    if (isExpanded && isDesktop) {
      return minimizeMenu
    }

    if (isExpanded) {
      return closeMenu
    }

    return openMenu
  }

  const sideBarWidth = () => {
    if (isMobile) {
      return 0
    }
    if (isSideNavExpanded) {
      return sideBarExpandedWidth
    }
    return sideBarIconsOnlyWidth
  }

  const handleSidebarToggle = () => {
    setSidebarExpanded(!isSideNavExpanded)
  }

  return (
    <>
      <StyledHeader aria-label="CloudNatix">
        <SkipToContent />
        <StyledHeaderMenuButton
          aria-label={getMenuButtonLabel(isSideNavExpanded)}
          isCollapsible
          onClick={handleSidebarToggle}
          isActive={isSideNavExpanded}
        />
        <HeaderTitle />
        <HeaderRightMenu />
      </StyledHeader>
      <div
        css={css`
          display: flex;
          width: 100%;
          position: relative;
          z-index: 0;
        `}
      >
        {!(isMobile && !isSideNavExpanded) ? (
          <div
            css={css`
              position: relative;
              z-index: 10;
            `}
          >
            <SideNav
              isSideNavExpanded={isSideNavExpanded}
              onSideNavToggle={handleSidebarToggle}
            />
          </div>
        ) : null}
        <StyledContent
          tagName="div"
          id="main-content"
          $sideBarWidth={sideBarWidth()}
        >
          {children}
        </StyledContent>
      </div>
    </>
  )
})
