import React from 'react'
import {
  Button,
  AccordionItem,
  AccordionProps,
  Accordion as CarbonAccordion,
} from '@carbon/react'
import { Information } from '@carbon/react/icons'
import styled from 'styled-components'
import Tooltip from 'src/next/components/Tooltip'

const StyledCarbonAccordion = styled(CarbonAccordion)`
  padding-left: 0;
`

export const TableFilterAccordion = (props: AccordionProps) => (
  <StyledCarbonAccordion size="sm" {...props} />
)

const ItemContainer = styled.div`
  display: grid;
`

const StyledAccordionItem = styled(AccordionItem)`
  list-style: none;
  border: none;
  border-bottom: none;

  &:last-child {
    border-bottom: none;
  }

  .cds--accordion__heading {
    align-items: center;
    min-height: 0;
    padding: 0;

    &:focus::before {
      margin-left: calc(var(--cds-spacing-03) * -1);
      width: calc(100% + var(--cds-spacing-05));
    }
    &:hover::before {
      background: transparent;
    }
  }

  .cds--accordion__title {
    font-size: 12px;
    margin-left: 0;
    padding: var(--cds-spacing-03) 0;
  }

  .cds--accordion__content {
    padding: var(--cds-spacing-02) 0 1.5rem;
  }

  .cds--accordion__arrow {
    margin-right: 0px;
  }
`

interface TableFilterAccordionItemProps {
  children: React.ReactNode
  title: string
  defaultOpen?: boolean
}

export const TableFilterAccordionItem = ({
  children,
  defaultOpen = true,
  title,
}: TableFilterAccordionItemProps) => {
  return (
    <ItemContainer>
      <StyledAccordionItem open={defaultOpen} title={title}>
        {children}
      </StyledAccordionItem>
    </ItemContainer>
  )
}
