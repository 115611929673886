import { useQuery } from 'react-query'
import { getApi } from 'src/services'

const useCliDistributionVersions = (options = {}) =>
  useQuery<string[]>(
    ['installCli', 'distributionVersions'],
    async () => {
      const { data } = await getApi('/v1/distributionversions', {
        kind: 'cli',
      })
      return data.distributionVersions
    },
    { ...options },
  )

export default useCliDistributionVersions
