import React from 'react'
import { InlineLoading } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useSummariesMetrics } from 'src/api'
import { SimpleStackedBar } from 'src/next/components/SimpleStackedBar'
import { Tooltip } from 'src/next/components/ui/Tooltip'
import Typography from 'src/next/components/Typography'
import {
  bytesToUserFriendlySize,
  millicoresToUserFriendlySizeShort,
  millicoresToUserFriendlySizeLong,
} from 'src/next/utils'
import { MetricContainer } from './MetricContainer'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    flex: 1 0 220px;

    @media (min-width: 1280px) {
      flex: 1 0 100%;
    }
  }
`

const ContentRow = styled.div`
  display: flex;
  align-content: flex-start;
  justify-content: space-around;
  align-items: flex-start;
  padding: var(--cds-spacing-03) 0;
`

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 60px;
`

const Label = styled.div`
  color: var(--cds-text-secondary);
  font-size: var(--cds-body-01-font-size);
  font-weight: 400;
`

const StackedBarWrapper = styled.div`
  padding: var(--cds-spacing-01) 0;
  width: 100%;
`

export const ResourceMetrics = () => {
  const { t } = useTranslation()

  const id = 'dashboard-resource-range'

  const { data, isLoading, isError } = useSummariesMetrics(id)

  const summary = data?.aggregatedSummary?.aggregatedSummary

  const entities = [
    { name: t('Cluster.clusters'), total: summary?.numClusters },
    { name: t('Instance.instances'), total: summary?.numInstances },
  ]

  return (
    <MetricContainer
      id={id}
      title={t('Dashboard.ResourceOverview')}
      primaryAction={{
        label: t('Dashboard.TopMetrics.ResourceAction'),
        href: '/app/clusters',
      }}
    >
      <Wrapper>
        {isLoading && (
          <InlineLoading
            status="active"
            iconDescription="Active loading indicator"
            description="Loading..."
          />
        )}
        {!isError && !isLoading ? (
          <>
            <ContentRow>
              {entities.map(({ name, total }) => (
                <ContentColumn key={name}>
                  <Label>{t('Dashboard.Sparkline.AverageLabel')}</Label>
                  <Typography variant="productive-heading-04">
                    {total}
                  </Typography>
                  <span>{name}</span>
                </ContentColumn>
              ))}
            </ContentRow>

            <ContentRow>
              <Tooltip
                tooltipText={
                  (summary?.cpuEfficiency! * 100).toFixed(2) +
                  '% of ' +
                  millicoresToUserFriendlySizeLong(summary?.avgCpuCapacity || 0)
                }
              >
                <ContentColumn>
                  <Label>{t('Dashboard.Sparkline.AverageLabel')}</Label>
                  <Typography variant="productive-heading-02">
                    {`${millicoresToUserFriendlySizeShort(
                      summary?.avgCpuCapacity || 0,
                    )} ${t('Common.Cores')}
                        `}
                  </Typography>
                  <span>{t('Common.CPU')}</span>
                  <StackedBarWrapper>
                    <SimpleStackedBar
                      data={[
                        {
                          label: 'utilization',
                          value: summary?.cpuEfficiency!,
                        },
                        {
                          label: 'total',
                          value: 1,
                        },
                      ]}
                    />
                  </StackedBarWrapper>
                </ContentColumn>
              </Tooltip>
              <Tooltip
                tooltipText={
                  (summary?.memoryEfficiency! * 100).toFixed(2) +
                  '% of ' +
                  bytesToUserFriendlySize(summary?.avgMemoryCapacity!)
                }
              >
                <ContentColumn>
                  <Label>{t('Dashboard.Sparkline.AverageLabel')}</Label>
                  <Typography variant="productive-heading-02">
                    {bytesToUserFriendlySize(summary?.avgMemoryCapacity!)}
                  </Typography>
                  <span>{t('Common.Memory')}</span>
                  <StackedBarWrapper>
                    <SimpleStackedBar
                      data={[
                        {
                          label: 'utilization',
                          value: summary?.memoryEfficiency!,
                        },
                        {
                          label: 'total',
                          value: 1,
                        },
                      ]}
                    />
                  </StackedBarWrapper>
                </ContentColumn>
              </Tooltip>
            </ContentRow>
          </>
        ) : null}
      </Wrapper>
    </MetricContainer>
  )
}
