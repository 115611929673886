import { ResourceTimeSeries } from '@cloudnatix-types/dashboard'
import { SliderStateRequest } from 'src/next/containers/WorkloadRecommendations'
import { CostSlider } from 'src/next/types/workloads'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'

interface ListFormatOptions {
  wrapInQuotes?: boolean
}

// returns a list of names, as in: ['Foo', 'Bar', 'Baz'] › "'Foo', 'Bar' & 'Baz'"
export function listFormat(
  arr: string[],
  { wrapInQuotes = false }: ListFormatOptions = {},
): string {
  const names = arr.map(name => (wrapInQuotes ? `'${name}'` : name))
  return names.length > 1
    ? `${names.slice(0, -1).join(', ')} & ${names.slice(-1)}`
    : names.join('')
}

type DataPointResource = 'avgMagnitude' | 'maxMagnitude'

export const dataPointToGraphPoint = (
  dataPoint: ResourceTimeSeries,
  resource: DataPointResource,
) => {
  const yValue = Number(dataPoint[resource])

  return {
    x: new Date(nanoToMilliSeconds(dataPoint?.timestampNs ?? 0)),
    y: isNaN(yValue) ? 0 : yValue,
  }
}

export const convertApiToGraph = (
  type: DataPointResource,
  data: ResourceTimeSeries[] | undefined,
) => {
  if (data === undefined) return []

  return data.map((dataPoint: ResourceTimeSeries) =>
    dataPointToGraphPoint(dataPoint, type),
  )
}

/**
 * The resource (CPU or Memory) value is lower than the default value
 * Calculate the current resource slider value
 */
const getLowerResourceValue = (resourceValues: any, fraction: number) => {
  const { min } = resourceValues
  return (resourceValues.default - min) * fraction
}

/**
 * The resource (CPU or Memory) value is higher than the default value
 * Calculate the current resource slider value
 */
const getHigherResourceValue = (resourceValues: any, fraction: number) => {
  const { max } = resourceValues
  const defaultValue = resourceValues.default
  return defaultValue + (max - defaultValue) * fraction
}

/**
 * The currentSliderValue is between min and default
 * Calculate that number as a fraction of the range between min and default
 *
 * Target cost slider:
 * 0% |--------------x-----------------| 100%
 *   min     |     default            max
 *    currentSliderValue
 *
 * Example 1:
 * min = 0, currentSliderValue = 25, default = 50
 * Fraction = 0.5
 *
 * Example 2:
 * min = 0, currentSliderValue = 10, default = 50
 * Fraction = 0.2
 *
 * Example 3:
 * min = 0, currentSliderValue = 100, default = 100, max = 100
 * Fraction = 1
 * */
const getSliderFractionMin = (currentSliderValue: number) => {
  return currentSliderValue / CostSlider.default
}

/**
 * The currentSliderValue is between default and max
 * Calculate that number as a fraction of the range between default and max
 *
 * Target cost slider:
 * 0% |--------------x-----------------| 100%
 *   min          default       |     max
 *                      currentSliderValue
 *
 * Example 1:
 * default = 90, currentSliderValue = 95, max = 100
 * Fraction = 0.5
 *
 * Example 2:
 * default = 50, currentSliderValue = 90, max = 100
 * Fraction = 0.8
 * */
const getSliderFractionMax = (currentSliderValue: number) => {
  return (
    (currentSliderValue - CostSlider.default) /
    (CostSlider.max - CostSlider.default)
  )
}

/**
 * Calculate the value of the resource slider (CPU or Memory slider)
 *   based on the currentSliderValue of the Target Cost Slider
 */
export const calculateResourceValue = (
  requests: SliderStateRequest,
  currentSliderValue: number,
) => {
  const moveUp = currentSliderValue > CostSlider.default
  const costSliderFraction = moveUp
    ? getSliderFractionMax(currentSliderValue)
    : getSliderFractionMin(currentSliderValue)

  return moveUp
    ? getHigherResourceValue(requests, costSliderFraction)
    : getLowerResourceValue(requests, costSliderFraction)
}
