import React, { ReactNode } from 'react'
import { Calendar, Information } from '@carbon/react/icons'
import { Button, Loading, OverflowMenu, Tile } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box, Flex, Link } from 'src/next/components'
import Tooltip from 'src/next/components/Tooltip'
import Typography from 'src/next/components/Typography'
import { useTimeRangeOverflowMenu } from '../../timerange'

const StyledTile = styled(Tile)`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--cds-layer-02);
  padding: var(--cds-spacing-02) var(--cds-spacing-05);
`

const Heading = styled.div`
  display: flex;
  padding-top: var(--cds-spacing-03);
  margin-right: 40px; // Overflow menu button
`

export const TimeRange = styled.div`
  color: var(--cds-text-secondary);
  font-size: 0.8125rem;
  margin-bottom: var(--cds-spacing-01);
`

const Content = styled.div`
  padding-bottom: var(--cds-spacing-03);
  padding-top: var(--cds-spacing-03);
  flex: 1 0 auto;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
`

const Footer = styled.div`
  display: flex;
  padding-bottom: var(--cds-spacing-04);
  padding-top: var(--cds-spacing-03);
`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  place-items: center;
`

const ErrorWrapper = styled.div`
  display: grid;
  place-items: center;
  padding: var(--cds-spacing-03);
  height: 100%;
`

export interface MetricContainerProps {
  id: string
  children: ReactNode
  title: string | React.ReactNode
  enableTimeRange?: boolean
  loading?: boolean
  error?: string
  primaryAction?: {
    label: string
    href: string
  }
  tooltip?: string
}

export const MetricContainer = ({
  id,
  children,
  title,
  enableTimeRange = true,
  loading = false,
  error,
  primaryAction,
  tooltip,
}: MetricContainerProps) => {
  const { t } = useTranslation()

  const { items: overflowMenuItems, selectedItem } = useTimeRangeOverflowMenu({
    id,
  })

  return (
    <StyledTile>
      <Heading>
        <Typography variant="heading-03" as="h2">
          {title}
          {tooltip ? (
            <Tooltip
              label={t('Dashboard.Sparkline.SavingsTooltip')}
              align="bottom-right"
            >
              <Button
                size="md"
                kind="ghost"
                hasIconOnly
                renderIcon={Information}
              />
            </Tooltip>
          ) : null}
          {enableTimeRange ? (
            <Flex alignItems="center">
              <TimeRange>{selectedItem.label}</TimeRange>
            </Flex>
          ) : null}
        </Typography>
        <Box top={0} right={0} position="absolute" data-floating-menu-container>
          {enableTimeRange && (
            <OverflowMenu
              data-testid={`time-range-${id}`}
              flipped
              iconDescription={t('Common.DateFilter.SelectTimeRange')}
              ariaLabel={t('Common.DateFilter.SelectTimeRange')}
              renderIcon={Calendar}
            >
              {overflowMenuItems}
            </OverflowMenu>
          )}
        </Box>
      </Heading>
      {loading ? (
        <LoadingWrapper>
          <Loading small withOverlay={false} />
        </LoadingWrapper>
      ) : error ? (
        <ErrorWrapper>{error}</ErrorWrapper>
      ) : (
        <>
          <Content>{children}</Content>
          <Footer>
            {primaryAction && (
              <Link to={primaryAction.href}>{primaryAction.label}</Link>
            )}
          </Footer>
        </>
      )}
    </StyledTile>
  )
}
