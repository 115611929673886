import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { getCarbonPaletteCssVariable } from 'src/next/utils/graph.utils'

interface TooltipRowProps {
  label: string
  value: ReactNode
  color?: string
}

export interface TooltipContentProps {
  header?: ReactNode
  data?: TooltipRowProps[]
}

export const TooltipContent = ({ header, data }: TooltipContentProps) => {
  if (!header && !data) {
    return null
  }

  return (
    <TooltipWrapper>
      {header ? <TooltipHeader>{header}</TooltipHeader> : null}
      {data?.length
        ? data.map((row, i) => {
            const borderColor = row.color || getCarbonPaletteCssVariable(i)
            return (
              <TooltipRow key={row.label} style={{ borderColor }}>
                <TooltipLabel>{row.label}</TooltipLabel>
                {row.value}
              </TooltipRow>
            )
          })
        : null}
    </TooltipWrapper>
  )
}

const TooltipWrapper = styled.div`
  background: var(--cds-toggle-off);
  border: 0.5px solid var(--cds-border-strong-01);
  color: var(--cds-text-inverse);
  display: inline-flex;
  flex-direction: column;
  font-family: var(--cn-font-family--condensed);
  font-size: var(--cds-helper-text-01-font-size);
  min-width: 180px;
  pointer-events: none;
  gap: 1px;

  // Hide (border) when tooltip is empty
  &:empty {
    display: none;
  }
`

export const TooltipHeader = styled.div`
  background: var(--cds-layer-selected-inverse);
  padding: 2px 4px 2px 12px;
  font-weight: bold;
  white-space: pre-line;
`

export const TooltipRow = styled.div`
  align-content: space-between;
  background: var(--cds-layer-selected-inverse);
  border-left-color: transparent;
  border-left-style: solid;
  border-left-width: 8px;
  display: flex;
  flex-direction: row;
  padding: 2px 4px;
  width: 100%;
`

export const TooltipLabel = styled.div`
  flex: 1;
  margin-right: 1em;
`
