import { AxiosRequestHeaders, Method } from 'axios'
import createAxios from 'src/api/axios'

export const putApi = (
  apiUrl: string,
  data: any,
  headers: AxiosRequestHeaders,
) => apiCall('put', data, apiUrl, headers)

export const postApi = (
  apiUrl: string,
  data: any,
  headers: AxiosRequestHeaders,
) => apiCall('post', data, apiUrl, headers)

export const getApi = (
  apiUrl: string,
  data?: any,
  headers?: AxiosRequestHeaders,
) => apiCall('get', data, apiUrl, headers)

const apiCall = (
  method: Method,
  data: any,
  url: string,
  headers?: AxiosRequestHeaders,
) =>
  createAxios({
    method,
    params: data,
    url,
    headers,
  })
