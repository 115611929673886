/* eslint-disable react/no-unknown-property */
import React from 'react'
import { InlineLoading } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { Textfit } from 'react-textfit'
import styled, { css } from 'styled-components'
import { useOptimizationTotalSaving } from 'src/api/queries'
import Typography from 'src/next/components/Typography'
import { createFormatFn } from 'src/next/utils/graph.utils'
import { MetricContainer } from './MetricContainer'

const SavingsContainer = styled.div`
  height: 100%;
  display: grid;
  place-items: center;
`

const StyledTextFit = styled(Textfit)`
  font-weight: bold;
  margin: 0 10%;
  line-height: 1.2;
`

export const SavingsMetrics = () => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useOptimizationTotalSaving()
  const formatCurrency = createFormatFn('currency')

  const id = 'dashboard-savings-range'

  return (
    <MetricContainer
      id={id}
      title={t('Dashboard.Sparkline.Savings')}
      primaryAction={{
        label: t('Dashboard.TopMetrics.SavingsAction'),
        href: '/app/insights',
      }}
      enableTimeRange={false}
      tooltip={t('Dashboard.Sparkline.SavingsTooltip')}
    >
      <SavingsContainer>
        {isLoading ? (
          <InlineLoading
            css={css`
              width: auto;
            `}
          />
        ) : isError ? (
          <Typography
            variant="helper-text-02"
            color="var(--cds-text-secondary)"
          >
            {t('Common.NoDataAvailable')}
          </Typography>
        ) : (
          <div
            css={css`
              text-align: center;
              width: 100%;
            `}
          >
            <StyledTextFit mode="single" max={36}>
              {formatCurrency(data?.totalSaving || 0)}
            </StyledTextFit>
            {t('Dashboard.Savings.Estimate')}
          </div>
        )}
      </SavingsContainer>
    </MetricContainer>
  )
}
