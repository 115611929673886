import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { useTableFilter } from './TableFilterContext'

const Form = styled.form`
  border-top: 2px solid transparent;
  border-left: none;
  margin-top: var(--cds-spacing-09);
  margin-right: var(--cds-spacing-04);
  padding: 0 var(--cds-spacing-02) 0 0;

  .cds--checkbox-label-text {
    margin-left: var(--cds-spacing-02);
  }
`

const Container = styled.div<{ $isOpen: boolean }>`
  display: grid;
  overflow: auto;

  ${({ $isOpen }) => css`
    grid-template-columns: ${$isOpen ? 'minmax(250px, 300px) 1fr' : '1fr'};
  `}
`

interface TableFilterProps {
  children: ReactNode
  className?: string
}

// actual filter that can be toggled
export const TableFilter = ({ children, className }: TableFilterProps) => {
  const { isOpen } = useTableFilter()

  return isOpen ? <Form className={className}>{children}</Form> : null
}

export interface TableFilterContainerProps {
  children: ReactNode
}

// layout container
export const TableFilterContainer = (props: TableFilterContainerProps) => {
  const { isOpen } = useTableFilter()

  return <Container $isOpen={isOpen} {...props} />
}
