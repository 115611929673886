import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ComputeInstanceSummary, Summary } from '@cloudnatix-types/dashboard'
import { Box, Legend, LegendItem } from 'src/next/components'
import Loading from 'src/next/components/Loading'
import StackedBarChart from 'src/next/components/StackedBarChart'
import { StackedBarChartProps } from 'src/next/components/StackedBarChart/StackedBarChart'
import { kubernetesWorkloadSummaryMetricMap } from 'src/next/constants/summary'
import {
  ColorMap,
  createGraphConfig,
  getColorMapTopSummaries,
  getTransformTopSummariesData,
  TransformedData,
} from 'src/next/containers/Charts/TopSummariesUtils'
import {
  createFormatFn,
  getFormatType,
  getStackedMaxYDomain,
} from 'src/next/utils/graph.utils'
import { middleEllipsis } from 'src/next/utils/middle-ellipsis'
import { getTimePeriod } from 'src/next/utils/timePeriod'
import {
  TimePeriod,
  TopSummariesChartSettingLimit,
  TopSummariesChartSettingMetric,
  TopSummariesChartSettingType,
} from '../WorkloadCharts/types'
import { useSummariesRollupQuery } from '../WorkloadCharts/useSummariesRollup'

const LegendWrapper = styled.div`
  margin-left: var(--cds-spacing-04);
  width: 100%;
`

interface TopSummariesChartProps
  extends Omit<StackedBarChartProps, 'graphConfig'> {
  summaryLimit: TopSummariesChartSettingLimit
  summaryMetric: TopSummariesChartSettingMetric
  summaryType: TopSummariesChartSettingType
  timePeriod: TimePeriod
  multiLegendSelect?: boolean
  selectedId?: string | null
  colorMap?: ColorMap
}

export const TopSummariesChart = ({
  summaryLimit,
  summaryMetric,
  summaryType,
  timePeriod,
  colorMap,
  ...props
}: TopSummariesChartProps) => {
  const yAccessorKey = kubernetesWorkloadSummaryMetricMap[summaryMetric]
  const { startTimeNs, endTimeNs } = getTimePeriod(timePeriod)

  const { data, isLoading } = useSummariesRollupQuery<TransformedData>(
    {
      summaryType: summaryType as any,
      summaryInterval: timePeriod.toUpperCase() as any,
      summaryMetrics: summaryMetric as any,
      limit: Number(summaryLimit),
      filter: {
        startTimeNs,
        endTimeNs,
      },
    },
    { select: getTransformTopSummariesData, useErrorBoundary: true },
  )

  if (isLoading && !data) {
    return (
      <Box height="300px" position="relative">
        <Loading size="small" centered withOverlay={false} />
      </Box>
    )
  }

  if (!data?.length) return null

  return (
    <StackedSummariesChart
      yAccessorKey={yAccessorKey}
      data={data}
      colorMap={colorMap || getColorMapTopSummaries(data)}
      {...props}
    />
  )
}

interface SummariesChartProps
  extends Omit<
    TopSummariesChartProps,
    'summaryLimit' | 'summaryMetric' | 'timePeriod' | 'summaryType'
  > {
  yAccessorKey: keyof Summary | keyof ComputeInstanceSummary | string
  data: TransformedData
  colorMap: ColorMap
  summaryType?: TopSummariesChartSettingType
}

export const StackedSummariesChart = ({
  data,
  selectedId,
  multiLegendSelect = true,
  yAccessorKey,
  colorMap,
  ...props
}: SummariesChartProps) => {
  const yFormatFn = useCallback(
    value =>
      createFormatFn(getFormatType(yAccessorKey), {
        cpu: { shortDisplayValue: true },
        memory: { maximumFractionDigits: 0 },
        disk: { maximumFractionDigits: 0 },
        currency: { minimumFractionDigits: 0 },
      })(value),
    [yAccessorKey],
  )

  const [checkedLegendItems, setCheckedLegendItems] = useState(
    colorMap.map(f => `${f.id}`),
  )

  useEffect(() => {
    setCheckedLegendItems(colorMap.map(f => `${f.id}`))
  }, [colorMap])

  const graphConfig = useMemo(() => {
    const config = data
      ? createGraphConfig(data, yAccessorKey, colorMap, selectedId)
      : []

    if (!config?.length) throw new Error('graphConfig could not be generated')

    return config
  }, [data, yAccessorKey, selectedId, colorMap])

  const handleLegendChange = (label: string) => {
    if (multiLegendSelect) {
      const legendItems = checkedLegendItems?.filter(c => c !== label)
      // make sure at least 1 item is selected
      if (!legendItems?.length) return false
      const newLegendItems = checkedLegendItems?.includes(label)
        ? legendItems
        : [...checkedLegendItems, label]
      setCheckedLegendItems(newLegendItems)
    } else {
      const allLegendItems = colorMap!.map(f => `${f.id}`)
      if (checkedLegendItems.length === 1 && label === checkedLegendItems[0]) {
        setCheckedLegendItems(allLegendItems)
      } else {
        setCheckedLegendItems([label])
      }
    }
  }

  const filteredGraphConfig = useMemo(() => {
    return graphConfig.map(config => {
      const filteredData = config.data.filter(d => {
        return d && checkedLegendItems?.includes(`${d.groupingName}`)
      })

      return {
        ...config,
        data: filteredData,
      }
    })
  }, [graphConfig, checkedLegendItems])

  const maxYDomain = getStackedMaxYDomain(
    filteredGraphConfig || [],
    yAccessorKey,
  )

  return (
    <>
      <StackedBarChart
        selectedId={selectedId}
        yAccessorKey={yAccessorKey}
        yDomain={[0, maxYDomain]}
        yTickFormat={yFormatFn}
        graphConfig={graphConfig}
        graphConfigFiltered={filteredGraphConfig || null}
        height={320 || props.height}
        width={640 || props.width}
        {...props}
      />
      <LegendWrapper>
        <Legend>
          {colorMap.map(({ id, color }) => {
            return (
              <LegendItem
                id={id || `unnamed-${color}`}
                key={id}
                title={id}
                color={color}
                onChange={() => handleLegendChange(id)}
                checked={checkedLegendItems.includes(id)}
              >
                {middleEllipsis(id, 10, 10)}
              </LegendItem>
            )
          })}
        </Legend>
      </LegendWrapper>
    </>
  )
}
