import React, { useMemo } from 'react'
import { Dropdown } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import {
  DropdownHeading,
  DropdownInlineLabel,
  DropdownSubheading,
} from 'src/next/components/Dropdown/DropdownHeading'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { usePersistentDropdown } from 'src/next/hooks/usePersistentDropdown'
import { TopSummariesChart } from '../Charts/TopSummariesChart'
import ChartContainer from './ChartContainer'
import { TimePeriod } from './types'
import {
  getTimeFilters,
  useKubernetesWorkloadTopSummariesDropdownItems,
} from './WorkloadCharts.translations'

export const WorkloadStackedBarChart = () => {
  const { t } = useTranslation()

  const summaryLimit = '5'

  const dropdownItems = useKubernetesWorkloadTopSummariesDropdownItems()

  const timeFilterItems = useMemo(() => getTimeFilters(t), [t])
  const [timeFilterRollup, setTimeFilterRollup] = useLocalStorage<TimePeriod>(
    'workloads-rollup-chart-time-filter',
    'daily',
  )

  const {
    dropdownProps: { selectedItem: metric, ...metricDropdownProps },
  } = usePersistentDropdown(
    'workloads-rollup-chart-metric',
    dropdownItems.metric,
  )

  const {
    dropdownProps: { selectedItem: type, ...typeDropdownProps },
  } = usePersistentDropdown('workloads-rollup-chart-type', dropdownItems.type)

  // Not using string operation intentionally to make it easier to look up
  // translation IDs on the code search.
  const timePeriodTranslationMap = {
    daily: t('Common.TimePeriod.Daily'),
    weekly: t('Common.TimePeriod.Weekly'),
    monthly: t('Common.TimePeriod.Monthly'),
  }

  return (
    <ChartContainer
      selectedItem={timeFilterRollup}
      onChange={setTimeFilterRollup}
      menuItems={timeFilterItems.rollup}
      heading={
        <>
          <DropdownHeading>
            <DropdownInlineLabel>
              {t('Workloads.TopCharts.Rollup.FilterItems.Top')} {summaryLimit}{' '}
            </DropdownInlineLabel>
            <Dropdown
              type="inline"
              label={t('Workloads.TopCharts.Rollup.Heading.Type')}
              hideLabel
              selectedItem={type}
              {...typeDropdownProps}
              itemToString={item => (item ? item.label.toLowerCase() : '')}
              itemToElement={item => (item ? <>{item.label}</> : null)}
            />
            {/* by <metric> */}
            <DropdownInlineLabel>
              {t('Workloads.TopCharts.Rollup.Heading.By')}{' '}
            </DropdownInlineLabel>
            <Dropdown
              type="inline"
              label={t('Workloads.TopCharts.Rollup.Heading.Metric')}
              hideLabel
              selectedItem={metric}
              {...metricDropdownProps}
              itemToString={item => (item ? item.label.toLowerCase() : '')}
              itemToElement={item => (item ? <>{item.label}</> : null)}
            />
          </DropdownHeading>
          {/* ... Daily/Weekly/Monthly rollup */}
          <DropdownSubheading>
            {t('Workloads.TopCharts.Rollup.Heading.Suffix', {
              timePeriod: timePeriodTranslationMap[timeFilterRollup],
            })}
          </DropdownSubheading>
        </>
      }
      data-testid="workloads-top-summaries-chart"
    >
      <TopSummariesChart
        timePeriod={timeFilterRollup}
        summaryLimit={summaryLimit}
        summaryType={type.id}
        summaryMetric={metric.id}
      />
    </ChartContainer>
  )
}
