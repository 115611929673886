import styled from 'styled-components'
import { DataTablePagination } from './DataTablePagination'

export const DataTableStyles = styled.div`
  > .cds--data-table-container {
    // Toolbar
    // - prevent disappearing overflow menu in deeply nested tables
    > .cds--table-toolbar {
      background: transparent;

      > .cds--toolbar-content > .cds--overflow-menu {
        width: auto;
      }

      .cds--overflow-menu:hover {
        background-color: var(--cds-field-hover);
      }
    }

    // Table
    > .cds--skeleton > .cds--data-table,
    > .cds--data-table-content > .cds--data-table {
      // reset some styles on both the table headers and rows
      > thead,
      > tbody {
        > tr,
        > tr:hover {
          background-color: var(--data-table-row-background, transparent);

          > th,
          > td {
            box-shadow: none;
            background-color: var(--data-table-row-background, transparent);
            border-bottom-width: 1px;
            border-top-width: 1px;
            color: var(--data-table-cell-text-color, var(--cds-text-secondary));
            padding-left: var(--cds-spacing-05, 1rem);
            padding-right: var(--cds-spacing-05, 1rem);

            &.cds--table-column-checkbox {
              width: 2rem;
              padding-left: 0.375rem;
              padding-right: 0.375rem;

              > .cds--checkbox--inline {
                transform: translateY(-1px);
              }
            }

            // Fix position of action buttons in tall data table
            > .cds--btn--icon-only {
              margin-top: -1rem;
            }
            // Fix position of small action buttons in data table
            > .cds--btn--icon-only.cds--btn--sm {
              margin-top: -0.5rem;
            }
          }
        }

        // rows
        // - assign vars
        > tr:not([data-child-row]) {
          min-height: unset;
          height: auto;

          > th,
          > td {
            height: var(--data-table-row-height, 3rem);
            vertical-align: var(--data-table-row-vertical-align, middle);
            padding-bottom: var(--data-table-cell-padding-bottom, 0.4375rem);
            padding-top: var(--data-table-cell-padding-top, 0.375rem);
          }
        }

        // expanded panel
        > tr.cds--expandable-row[data-child-row='true'] > td {
          padding: 0;
        }

        > tr.cds--parent-row.cds--expandable-row + tr[data-child-row] > td {
          border-bottom: 1px solid var(--cds-border-subtle);
        }
      }

      // Heading
      // - give heading rows a background color
      > thead {
        > tr,
        > tr > th {
          --data-table-cell-text-color: var(--cds-text-primary);
          background-color: var(--cds-layer-accent-01, #e0e0e0);
          color: var(--cds-text-primary, #161616);
          border: 0;

          > .cds--table-header-label {
            padding: 0;
          }
        }

        // cell with sorting
        tr > th[aria-sort] {
          padding: 0;

          > .cds--table-sort {
            line-height: 1.125rem;
          }
        }
      }

      /* overwrite for sm table with mixed sort headers */
      .cds--data-table--sm.cds--data-table--sort > thead {
        tr > th[aria-sort] {
          > .cds--table-sort {
            height: var(--data-table-row-height);

            .cds--table-sort__flex {
              min-height: unset;
              height: var(--data-table-row-height);

              .cds--table-header-label {
                padding-top: var(--data-table-cell-padding-top);
                padding-bottom: var(--data-table-cell-padding-bottom);
              }

              .cds--table-sort__icon-unsorted,
              .cds--table-sort__icon {
                margin-top: var(--data-table-cell-padding-top);
                margin-bottom: var(--data-table-cell-padding-bottom);
              }
            }
          }
        }
      }

      // Body
      // - give row a background color on hover unless it is expanded
      > tbody {
        > tr,
        > tr > th {
          background: var(--data-table-row-background, none);
          border-color: transparent;
        }

        > tr {
          // background color on hover, except when it is expanded
          &:not(.cds--expandable-row):not(.cds--data-table--selected):hover {
            --data-table-cell-text-color: var(--cds-text-primary);
            --data-table-row-background: var(--cds-layer-hover);
          }
        }

        // row with expand button
        > tr.cds--parent-row {
          // expand button
          > td.cds--table-expand {
            max-width: 2.5rem;
            padding-left: 0.5rem;
            padding-right: 0;

            // align expand button to the baseline
            > .cds--table-expand__button {
              display: inline;
              height: auto;

              &:focus {
                box-shadow: none;
              }

              &:focus-visible {
                box-shadow: inset 0 0 0 2px var(--cds-focus, #0f62fe);
              }

              .cds--table-expand__svg {
                transform: rotate(90deg) translateX(2px);
              }
            }

            &[data-previous-value='collapsed']
              > .cds--table-expand__button
              .cds--table-expand__svg {
              transform: rotate(270deg) translateX(-2px);
            }
          }
        }

        // state: row is selected
        > tr.cds--data-table--selected {
          --data-table-row-background: var(--cds-layer-selected);
          --data-table-cell-text-color: var(--cds-text-primary);
          box-shadow: 0 1px 0 var(--cds-layer-selected-hover);

          &:hover {
            --data-table-row-background: var(--cds-layer-selected-hover);
          }

          // reset background color on expanded row when selected
          + tr[data-child-row] > td {
            background-color: transparent;
          }
        }

        // state: row is expanded
        > tr.cds--expandable-row > td {
          --data-table-cell-text-color: var(--cds-text-primary);
          border-top-color: transparent;
          border-bottom-color: transparent;
        }
      }
    }

    > .cds--data-table-content .cds--data-table,
    > .cds--skeleton .cds--data-table {
      // reset vars to initial so that they don't leak into nested tables
      --data-table-row-background: initial;
      --data-table-cell-text-color: initial;
      --data-table-row-height: initial;
      --data-table-cell-padding-bottom: initial;
      --data-table-cell-padding-top: initial;

      &--xs {
        --data-table-row-height: 1.5rem;
        --data-table-cell-padding-bottom: 0.125rem;
        --data-table-cell-padding-top: 0.125rem;
      }

      &--sm {
        --data-table-row-height: 2rem;
        --data-table-cell-padding-bottom: 0.4375rem;
        --data-table-cell-padding-top: 0.375rem;
      }

      &--md {
        --data-table-row-height: 2.5rem;
        --data-table-cell-padding-bottom: 0.5625rem;
        --data-table-cell-padding-top: 0.5rem;
      }

      &--xl {
        --data-table-row-height: 4rem;
        --data-table-cell-padding-bottom: 0.25rem;
        --data-table-cell-padding-top: 1rem;
        --data-table-row-vertical-align: top;

        /* prevent scrollbars on empty table */
        min-height: 75px;
      }
    }

    // Nested skeletons
    > .cds--skeleton thead,
    > .cds--skeleton tbody {
      > tr {
        &,
        & > td,
        & > th {
          height: var(--data-table-row-height, 3rem);
          vertical-align: var(--data-table-row-vertical-align, middle);
          overflow: hidden;
          padding-bottom: var(--data-table-cell-padding-bottom, 0.4375rem);
          padding-top: var(--data-table-cell-padding-top, 0.375rem);
          padding-left: 1rem;
        }
      }
    }
  }
`

export const DataTableNestedPagination = styled(DataTablePagination)`
  align-items: flex-end;
  background-color: var(--cds-background-active);
  border-top: 1px solid var(--cds-text-placeholder) !important;
  overflow: hidden;
  min-height: 2rem;

  .cds--pagination__button,
  .cds--btn--ghost.cds--pagination__button {
    border-left: 1px solid var(--cds-text-placeholder);
  }

  .cds--select--inline .cds--select-input {
    background: transparent;
  }
  .cds--select--inline .cds--select-input:hover {
    background: transparent;
  }
  .cds--select--inline .cds--select-input:active {
    background: transparent;
  }
  .cds--select--inline .cds--select-input:focus {
    background: transparent;
  }

  // overwrite on _pagination.scss from carbon
  .cds--pagination__button,
  .cds--btn--ghost.cds--pagination__button {
    width: 2rem;
    height: 2rem;
    margin: 0;
  }

  .cds--select-input {
    height: 2rem !important;
    line-height: 2rem !important;
  }
`
