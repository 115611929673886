/* eslint-disable react/display-name */
import React from 'react'
import {
  OverflowMenu as CarbonOverflowMenu,
  OverflowMenuProps as CarbonOverflowMenuProps,
} from '@carbon/react'
import * as Styled from './OverflowMenu.styled'

export interface OverflowMenuProps extends CarbonOverflowMenuProps {
  minWidth?: string
}

export const OverflowMenu = React.forwardRef(
  (
    { minWidth, ...rest }: OverflowMenuProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    return typeof minWidth !== 'undefined' ? (
      <Styled.OverflowMenuWrapper
        $minWidth={minWidth}
        data-floating-menu-container
      >
        <CarbonOverflowMenu {...rest} ref={ref} />
      </Styled.OverflowMenuWrapper>
    ) : (
      <CarbonOverflowMenu {...rest} ref={ref} />
    )
  },
)
