import { useQuery, UseQueryOptions } from 'react-query'
import {
  ListComputeInstanceGroupsRequest,
  ListComputeInstanceGroupsResponse,
} from '@cloudnatix-types/dashboard'
import { fetchData } from 'src/api/axios'
import { useRequestParams } from 'src/api/useRequestParams'

const url = '/v1/dashboard/infrastructure/compute/instancegroups'

export function useDashboardInstanceGroups<
  T = ListComputeInstanceGroupsResponse,
>(
  request: ListComputeInstanceGroupsRequest,
  options?: UseQueryOptions<ListComputeInstanceGroupsResponse, any, T>,
) {
  const params = useRequestParams({ request, addOrgFilter: true })

  return useQuery<ListComputeInstanceGroupsResponse, Error, T>(
    [url, params],
    () => fetchData<ListComputeInstanceGroupsResponse>(url, params),
    {
      staleTime: 60000,
      ...options,
    },
  )
}
