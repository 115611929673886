/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
import React, { Suspense } from 'react'
import { TabStandaloneProps } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Box } from 'src/next/components'
import Loading from 'src/next/components/Loading'
import {
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from 'src/next/components/Tabs'
import { useTabSelection } from 'src/next/hooks'
import * as TabTypes from './tabs'

const StyledTabList = styled(TabList)`
  /* tabs = ul */
  .cds--tabs--scrollable__nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  /* tab = li */
  .cds--tabs--scrollable__nav-item {
    flex: 1;
  }

  /* button */
  .cds--tab--list {
    width: 100%;
  }
`

const SuspenseFallback = () => <Loading size="small" withOverlay={false} />

const tabs = [
  ['Region', <TabTypes.RegionTab />],
  ['CSP', <TabTypes.GenericTab group="CSP" />],
  ['Organization', <TabTypes.GenericTab group="ORG" noLimit />],
  ['AccountID', <TabTypes.GenericTab group="ACCOUNT" />],
  ['Cluster', <TabTypes.GenericTab group="CLUSTER" />],
  ['Namespace', <TabTypes.NamespaceTab />],
  ['Tag', <TabTypes.TagTab />],
  ['Instances', <TabTypes.InstancesTab />],
] as const

export const DashboardTabs = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const tabIds = tabs.map(([tabId]) => tabId)
  const { selectedTab, setSelectedTab } = useTabSelection(tabIds)

  const handleTabClick = ({ selectedIndex }) => {
    setSelectedTab(selectedIndex)
    const params = new URLSearchParams({ tabs: tabs[selectedIndex][0] })
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    })
  }

  return (
    <Box position="relative" mb="100px" minHeight="800px">
      <Tabs
        type="container"
        selectedIndex={selectedTab}
        onChange={handleTabClick}
      >
        <StyledTabList data-testid="dashboard-tabs" aria-label="dashboard tabs">
          {tabs.map(([tabKey, tabContent], index) => (
            <DashboardTab index={index} key={tabKey} selected>
              {t(`Dashboard.Tabs.${tabKey}`)}
            </DashboardTab>
          ))}
        </StyledTabList>
        <TabPanels>
          {tabs.map(([tabKey, tabContent], index) => (
            <TabPanel key={tabKey}>
              <Suspense fallback={<SuspenseFallback />}>
                {selectedTab === index ? tabContent : null}
              </Suspense>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  )
}

const DashboardTab = React.forwardRef<any, TabStandaloneProps>((props, ref) => {
  const { index, children } = props

  return (
    <Tab ref={ref} id={`dashboard-tabs-${index! + 1}`} {...props}>
      {children}
    </Tab>
  )
})
