import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from 'src/next/components/Tabs'
import { PageTitle } from 'src/next/components/ui'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { AuditLogPage } from './AuditLogPage'
import { OrganizationsPage } from './OrganizationsPage'
import { ResourcesPage } from './ResourcesPage'
import { RulesPage } from './RulesPage'
import { UsersPage } from './UsersPage'

export const AdministrationPage = () => {
  const { t } = useTranslation()

  const [selectedTab, setSelectedTab] = useLocalStorage('admin-page', 0)

  return (
    <>
      <PageTitle title={t('NavigationPanel.Administration')} />
      <Tabs
        type="container"
        selectedIndex={selectedTab}
        onChange={({ selectedIndex }) => setSelectedTab(selectedIndex)}
      >
        <TabList aria-label="select admin type">
          <Tab id="organizations">{t('Organizations.PageTitle')}</Tab>
          <Tab id="resources">{t('Resources.PageTitle', 'Resources')}</Tab>
          <Tab id="rules">{t('Rules.PageTitle')}</Tab>
          <Tab id="users">{t('Users.PageTitle')}</Tab>
          <Tab id="auditlogs">{t('AuditLogs.PageTitle')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel key="organizations">
            <OrganizationsPage />
          </TabPanel>
          <TabPanel key="resources">
            <ResourcesPage />
          </TabPanel>
          <TabPanel key="rules">
            <RulesPage />
          </TabPanel>
          <TabPanel key="users">
            <UsersPage />
          </TabPanel>
          <TabPanel key="auditlogs">
            <AuditLogPage />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}
