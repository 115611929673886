import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'
import { ListOptimizationInsightsResponse as ResponseType } from '@cloudnatix-types/dashboard'
import { fetchData, useRequestParams } from 'src/api'
import { InsightTypes } from 'src/next/components/insights'

export const useOptimizations = <T = ResponseType>(
  type: InsightTypes,
  request?: any,
  options?: UseInfiniteQueryOptions<ResponseType, Error, T>,
) => {
  const params = useRequestParams({ request, addOrgFilter: true })
  const url = `v1/insights/optimizations/${type}`

  return useInfiniteQuery<ResponseType, Error, T>(
    [url, params],
    ({ pageParam }) => {
      return fetchData<ResponseType>(url, {
        ...params,
        pageToken: pageParam,
      })
    },
    {
      getNextPageParam: lastPage => lastPage.nextPageToken,
      ...options,
    },
  )
}
