import styled from 'styled-components'
import { TrendItem } from './TrendItem'

const TrendWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  grid-gap: var(--cds-spacing-03);
`

interface Trend {
  label: string
  value: string
  showArrow?: boolean
}

export interface TrendsProps {
  isLoading: boolean
  trends: Trend[]
}

export const Trends = ({ isLoading, trends }: TrendsProps) => {
  return (
    <TrendWrapper>
      {trends.map((trend: Trend) => (
        <TrendItem key={trend.label} isLoading={isLoading} {...trend} />
      ))}
    </TrendWrapper>
  )
}
