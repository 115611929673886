import React, { ReactNode, useRef, useState } from 'react'
import { Tooltip } from '@carbon/react'
import { TooltipProps } from '@carbon/react/lib/components/Tooltip/Tooltip'
import { compose } from 'lodash/fp'
import styled from 'styled-components'
// import { useSafeState } from 'use-safe-state';

const StyledTooltip = styled(Tooltip)`
  z-index: 9000;
`

interface FeedbackProps extends TooltipProps<any> {
  tooltipContent: ReactNode
  feedbackTimeout?: number
}

const Feedback: React.FC<FeedbackProps> = ({
  children,
  feedbackTimeout = 2000,
  // prevent onChange is not defined issue
  onChange = f => f,
  onClick = f => f,
  tooltipContent,
  ...props
}) => {
  // use useSafeState to check if the component wasn't unmounted before updating
  // the state (which is needed since a timeout is used)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const timeoutRef = useRef<number>()

  const handleClick = () => {
    clearInterval(timeoutRef.current)

    setTooltipOpen(true)
    timeoutRef.current = window.setTimeout(() => {
      setTooltipOpen(false)
    }, feedbackTimeout)
  }

  const childrenWithClickProp = React.Children.map(children, child => {
    const wrappedChild = React.isValidElement(child) ? (
      child
    ) : (
      <span>{child}</span>
    )

    return React.cloneElement(wrappedChild, {
      onClick: compose(
        ...[handleClick, wrappedChild.props?.onClick].filter(Boolean),
      ),
    })
  })

  return (
    <StyledTooltip
      onClick={onClick}
      open={tooltipOpen}
      onChange={onChange}
      label={tooltipContent}
      {...props}
    >
      <span>{childrenWithClickProp}</span>
    </StyledTooltip>
  )
}

export default Feedback
