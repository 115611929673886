import React, { useState } from 'react'
import {
  Api_1,
  ChartCombo,
  CloudServiceManagement,
  Dashboard,
  DataVis_1,
  Help,
  LogoSlack,
  Network_1,
  Report,
  Rocket,
  Terminal,
  WatsonHealthAiResults,
} from '@carbon/react/icons'
import { SideNavItems, SideNavLink } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useUserSelf } from 'src/api'
import { useCLUIRegisterInstallCLIModal } from 'src/next/components/CLUI/actions/useCLUIRegisterInstallCLIModal'
import { ConnectClusterModal } from 'src/next/components/ConnectClusterModal'
// https://github.com/apache/superset/blob/0e17e4b06f4b3c0a7931b61d1f2bbe89eb8e14ce/superset-frontend/src/assets/images/favicon.png
import { ReactComponent as SupersetIcon } from 'src/images/CN-SuperSet.svg'
import { ReactComponent as KubernetesIcon } from 'src/images/logo--kubernetes.svg'
import InstallCLIModalContainer from 'src/next/containers/InstallCLIModalContainer'
import useBreakpoint from 'src/next/hooks/useBreakpoint'
import * as Styled from './SideNav.styled'
import {
  hasAPIDocAccess,
  hasDeploymentDashboardAccess,
  getGrafanaUrl,
  getSupersetUrl,
} from './util'
import styled from 'styled-components'

interface SideNavProps {
  isSideNavExpanded: boolean
  onSideNavToggle: any
}

const StyledSideNavItems = styled(SideNavItems)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
`

export const SideNav = ({
  isSideNavExpanded,
  onSideNavToggle,
}: SideNavProps) => {
  const [openCliModal, setOpenCliModal] = useState(false)
  useCLUIRegisterInstallCLIModal(setOpenCliModal)

  const handleOpenModal = () => setOpenCliModal(true)
  const handleCloseModal = () => setOpenCliModal(false)

  const { t } = useTranslation()
  const { isMobile } = useBreakpoint()
  const { data: user } = useUserSelf()

  const isFreemium =
    user?.tenant?.subscriptionProperties?.offeringType === 'FREEMIUM'

  const handleNavItemClick = () => {
    if (isMobile) {
      onSideNavToggle()
    }
  }

  const handleMouseDown = (e: any) => {
    // Prevent the SideBar from expanding when you click an icon.
    e.preventDefault()
  }

  const grafanaUrl = getGrafanaUrl(user?.user)
  const supersetUrl = getSupersetUrl(user?.user)

  return (
    <>
      <Styled.StyledSideNavContainer>
        <Styled.StyledSideNav
          aria-label={t('SideBar.AriaLabel.SideNavigation', 'Side navigation')}
          expanded={isSideNavExpanded}
          onOverlayClick={onSideNavToggle}
        >
          <StyledSideNavItems>
            <div>
              <SideNavLink
                renderIcon={Dashboard}
                // @ts-ignore
                as={NavLink}
                onClick={handleNavItemClick}
                to="/app/dashboard"
                id="navigationpanel-dashboard"
                onMouseDown={handleMouseDown}
              >
                {/* @ts-ignore */}
                <span className="nav-tooltip">
                  {t('NavigationPanel.dashboard')}
                </span>
              </SideNavLink>
              <SideNavLink
                renderIcon={WatsonHealthAiResults}
                // @ts-ignore
                as={NavLink}
                onClick={handleNavItemClick}
                to="/app/insights"
                id="navigationpanel-insights"
                onMouseDown={handleMouseDown}
              >
                {/* @ts-ignore */}
                <span className="nav-tooltip">
                  {t('NavigationPanel.insights')}
                </span>
              </SideNavLink>
              <SideNavLink
                renderIcon={Network_1}
                // @ts-ignore
                as={NavLink}
                onClick={handleNavItemClick}
                to="/app/workloads"
                id="navigationpanel-workloads"
                onMouseDown={handleMouseDown}
              >
                {/* @ts-ignore */}
                <span className="nav-tooltip">
                  {t('NavigationPanel.workloads')}
                </span>
              </SideNavLink>
              <SideNavLink
                renderIcon={DataVis_1}
                // @ts-ignore
                as={NavLink}
                onClick={handleNavItemClick}
                to="/app/clusters"
                id="navigationpanel-clusters"
                onMouseDown={handleMouseDown}
              >
                {/* @ts-ignore */}
                <span className="nav-tooltip">
                  {t('NavigationPanel.clusters')}
                </span>
              </SideNavLink>
              <SideNavLink
                renderIcon={KubernetesIcon}
                as={NavLink}
                onClick={handleNavItemClick}
                to="/app/kubernetes-resources"
                id="kubernetes-resources"
                onMouseDown={handleMouseDown}
              >
                {/* @ts-ignore */}
                <span className="nav-tooltip">
                  {t('NavigationPanel.kubernetes-resources')}
                </span>
              </SideNavLink>
              <SideNavLink
                renderIcon={CloudServiceManagement}
                // @ts-ignore
                as={NavLink}
                onClick={handleNavItemClick}
                to="/app/administration"
                id="navigationpanel-administration"
                onMouseDown={handleMouseDown}
              >
                {/* @ts-ignore */}
                <span className="nav-tooltip">
                  {t('NavigationPanel.Administration')}
                </span>
              </SideNavLink>
              {hasDeploymentDashboardAccess(user?.user) && (
                <SideNavLink
                  renderIcon={Rocket}
                  // @ts-ignore
                  as={NavLink}
                  onClick={handleNavItemClick}
                  to="/app/deployment-dashboard"
                  id="navigationpanel-deployment-dashboard"
                  onMouseDown={handleMouseDown}
                >
                  {/* @ts-ignore */}
                  <span className="nav-tooltip">
                    {t('NavigationPanel.deployment-dashboard')}
                  </span>
                </SideNavLink>
              )}
            </div>

            {/* FOOTER */}
            <div className="menu-container-footer">
              {grafanaUrl && (
                <SideNavLink
                  renderIcon={ChartCombo}
                  id="navigationpanel-grafana"
                  href={grafanaUrl}
                  target="_blank"
                  onMouseDown={handleMouseDown}
                >
                  {/* @ts-ignore */}
                  <span className="nav-tooltip">
                    {t('NavigationPanel.grafana')}
                  </span>
                </SideNavLink>
              )}
              {supersetUrl && (
                <SideNavLink
                  renderIcon={SupersetIcon}
                  id="navigationpanel-superset"
                  href={supersetUrl}
                  target="_blank"
                  onMouseDown={handleMouseDown}
                >
                  {/* @ts-ignore */}
                  <span className="nav-tooltip">
                    {t('NavigationPanel.superset')}
                  </span>
                </SideNavLink>
              )}
              <SideNavLink
                renderIcon={Terminal}
                as="button"
                onClick={handleOpenModal}
                id="navigationpanel-install-cli"
                onMouseDown={handleMouseDown}
              >
                {/* @ts-ignore */}
                <span className="nav-tooltip">
                  {t('NavigationPanel.install_cli')}
                </span>
              </SideNavLink>
              {hasAPIDocAccess(user?.user) && (
                <SideNavLink
                  renderIcon={Api_1}
                  // @ts-ignore
                  as={NavLink}
                  onClick={handleNavItemClick}
                  to="/app/apidoc"
                  id="navigationpanel-apidocument"
                  onMouseDown={handleMouseDown}
                >
                  {/* @ts-ignore */}
                  <span className="nav-tooltip">
                    {t('NavigationPanel.apidoc')}
                  </span>
                </SideNavLink>
              )}
              <SideNavLink
                renderIcon={Report}
                id="navigationpanel-documentation"
                href={import.meta.env.VITE_DOC_URL}
                target="_blank"
                onMouseDown={handleMouseDown}
              >
                {/* @ts-ignore */}
                <span className="nav-tooltip">
                  {t('NavigationPanel.documentation')}
                </span>
              </SideNavLink>
              {isFreemium && (
                <SideNavLink
                  renderIcon={LogoSlack}
                  id="navigationpanel-slack"
                  href={import.meta.env.VITE_SLACK_URL}
                  target="_blank"
                  onMouseDown={handleMouseDown}
                >
                  {/* @ts-ignore */}
                  <span className="nav-tooltip">
                    {t('NavigationPanel.slack')}
                  </span>
                </SideNavLink>
              )}
              <SideNavLink
                renderIcon={Help}
                id="navigationpanel-help"
                href="https://cloudnatix.atlassian.net/servicedesk/customer/portals"
                target="_blank"
                onMouseDown={handleMouseDown}
              >
                {/* @ts-ignore */}
                <span className="nav-tooltip">{t('NavigationPanel.help')}</span>
              </SideNavLink>
            </div>
          </StyledSideNavItems>
        </Styled.StyledSideNav>
      </Styled.StyledSideNavContainer>
      {openCliModal && (
        <InstallCLIModalContainer
          open={openCliModal}
          onClose={handleCloseModal}
        />
      )}
    </>
  )
}
