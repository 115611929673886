import { ReactNode } from 'react'
import { ArrowDown, ArrowUp } from '@carbon/react/icons'
import { SkeletonText } from '@carbon/react'
import styled from 'styled-components'
import { Flex } from 'src/next/components'
import { parseToNumber } from 'src/next/utils'

const Label = styled.div`
  color: var(--cds-text-secondary);
  font-size: var(--cds-body-01-font-size);
  font-weight: 400;
`

const Arrow = styled.div`
  display: flex;
  margin-right: var(--cds-spacing-01);
`

const Value = styled.div`
  font-size: var(--cds-body-02-font-size);
  font-weight: 600;
  white-space: nowrap;
`

interface TrendItemProps {
  label: ReactNode
  value: string
  showArrow?: boolean
  isLoading?: boolean
}

export const TrendItem = ({
  label,
  value,
  showArrow = false,
  isLoading,
}: TrendItemProps) => {
  const valueNr = parseToNumber(value)
  const isPositive = Math.sign(valueNr) === 1
  const showArrowIcon = showArrow && valueNr !== 0

  return (
    <Flex flexDirection="column">
      <Label>{label}</Label>

      <Flex>
        {isLoading ? (
          <SkeletonText width="50%" />
        ) : (
          <Flex alignItems="center">
            {showArrowIcon && (
              <Arrow>{isPositive ? <ArrowUp /> : <ArrowDown />}</Arrow>
            )}
            <Value>{value}</Value>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
