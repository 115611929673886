import { useQuery, UseQueryOptions } from 'react-query'
import {
  GetTopComputeInstanceSummariesRequest,
  GetTopComputeInstanceSummariesResponse,
} from '@cloudnatix-types/dashboard'
import { fetchData } from 'src/api/axios'
import { useRequestParams } from 'src/api/useRequestParams'

const url = '/v1/dashboard/infrastructure/compute/instancesummaries:rollup'

export function useInstanceSummariesRollupQuery<
  T = Required<GetTopComputeInstanceSummariesResponse>,
>(
  request: GetTopComputeInstanceSummariesRequest,
  options?: UseQueryOptions<GetTopComputeInstanceSummariesResponse, Error, T>,
) {
  const params = useRequestParams({ request, addOrgFilter: true })

  return useQuery<GetTopComputeInstanceSummariesResponse, Error, T>(
    ['instance-summaries-rollup', params],
    () => fetchData<GetTopComputeInstanceSummariesResponse>(url, params),
    {
      cacheTime: 3600000, // 60 * 60 * 1000 = 1 hour
      staleTime: 3600000, // 60 * 60 * 1000 = 1 hour
      retry: false,
      ...options,
    },
  )
}
