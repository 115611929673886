import { useQuery } from 'react-query'
import { getApi } from 'src/services'
import { InstallCLIModalState } from './InstallCLIModalContainer'

const useCliDownloadUrl = (
  OS: InstallCLIModalState['OS'],
  version: InstallCLIModalState['version'],
  channel: InstallCLIModalState['channel'],
  options = {},
) =>
  useQuery<string>(
    ['installCli', 'distributionVersions', OS, version, channel],
    async () => {
      const { data } = await getApi('/v1/artifact-download-url', {
        name: 'cli',
        os_arch: OS,
        distribution_version: version,
        channel,
      })
      return data.downloadUrl
    },
    options,
  )

export default useCliDownloadUrl
