import { useTranslation } from 'react-i18next'
import { useSummariesMetrics } from 'src/api'
import { createFormatFn } from 'src/next/utils/graph.utils'
import { MetricContainer } from './MetricContainer'
import { SparklineContainer } from './SparklineContainer'
import { Trends } from './Trends'

export const MemoryMetrics = () => {
  const { t } = useTranslation()

  const id = 'dashboard-memory-range'
  const metric = 'avgMemoryUsage'

  const { data, isLoading } = useSummariesMetrics(id)

  const trends = [
    {
      label: t('Dashboard.Sparkline.AverageLabel'),
      value: createFormatFn('memory')(
        data?.aggregatedSummary?.aggregatedSummary?.[metric] || 0,
      ),
    },
    {
      label: t('Dashboard.Sparkline.ChangeLabel'),
      value: createFormatFn('memory')(
        data?.aggregatedSummary?.deltaSummary?.[metric] || 0,
      ),
      showArrow: true,
    },
  ]

  return (
    <MetricContainer
      id={id}
      title={t('Dashboard.Sparkline.Memory')}
      primaryAction={{
        label: t('Dashboard.TopMetrics.MemoryAction'),
        href: '/app/workloads',
      }}
    >
      <SparklineContainer
        id={id}
        title={t('Dashboard.Sparkline.Memory')}
        y={metric}
        data={data?.summaries}
        isLoading={isLoading}
        graphColor="var(--carbonPalette3)"
        formatValue={createFormatFn('memory')}
        timeRange={data?.selectedTimeRange}
      />
      <Trends isLoading={isLoading} trends={trends} />
    </MetricContainer>
  )
}
