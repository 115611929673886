import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ComputeInstanceSummaryMetricMap,
  KubernetesWorkloadSummaryMetricMap,
} from 'src/next/constants/summary'

export const useGroupingHeaders = (
  metrics: ComputeInstanceSummaryMetricMap | KubernetesWorkloadSummaryMetricMap,
) => {
  const { t } = useTranslation()

  return useMemo(() => {
    return [
      {
        key: 'legendSelection',
        header: '',
        persistent: true,
      },
      {
        key: 'name',
        header: t('Dashboard.Common.DataTable.Name'),
        style: { minWidth: '200px' },
        sort: 'id',
      },
    ].concat(
      Object.values(metrics).map(metric => ({
        key: `${metric}Formatted`,
        header: t(
          `Resources.${metric.charAt(0).toUpperCase()}${metric.slice(1)}`,
        ),
        style: { minWidth: '14ch' },
        sort: metric,
      })),
    )
  }, [t, metrics])
}
