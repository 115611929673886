/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ComboBox, ComboBoxProps } from '@carbon/react'
import styled from 'styled-components'

const StyledComboBox = styled(ComboBox)`
  // hide clear button
  .cds--list-box__selection {
    display: none;
  }

  .cds--list-box__menu-icon {
    pointer-events: none;
  }
`

export interface FilterableSelectProps extends Omit<ComboBoxProps, 'onChange'> {
  onChange?: OnChangeHandler
}
type SelectedItem = ComboBoxProps<string>['selectedItem']
type ShouldFilterItem = ComboBoxProps<string>['shouldFilterItem']
type OnChangeHandler = (value: SelectedItem) => void

const shouldFilterItem: ShouldFilterItem = ({ item, inputValue }) =>
  item?.toLowerCase().includes(inputValue || '')

/**
 * @component FilterableSelect
 * Clears input field on focus so that the user can start searching
 * immediately. Restores value to the last selected value on blur.
 * For now only supports a list with strings.
 */

export const FilterableSelect = ({
  selectedItem: selectedItemProp = '',
  onChange: onChangeProp = () => {},
  ...props
}: FilterableSelectProps) => {
  const lastSelectedItemRef = useRef<SelectedItem>(selectedItemProp as string)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [selectedItem, setSelectedItem] =
    useState<SelectedItem>(selectedItemProp)
  const onChangeMemo = useCallback(onChangeProp, [onChangeProp])

  // update internal state when selectedItemProp is updated
  useEffect(() => {
    setSelectedItem(selectedItemProp)
    onChangeMemo(selectedItemProp)
  }, [onChangeMemo, selectedItemProp])

  // remove the selected value on focus so that the user can directly search in the inputfield
  const onFocusHandler = () => {
    lastSelectedItemRef.current = selectedItem
    setSelectedItem('')
  }

  const onBlurHandler = () => {
    setSelectedItem(lastSelectedItemRef.current || '')
    onChangeMemo(lastSelectedItemRef.current || '')
  }

  // blur input field on change
  const onChangeHandler: OnChangeHandler = newlySelectedItem => {
    if (!newlySelectedItem) return
    lastSelectedItemRef.current = newlySelectedItem
    setSelectedItem(newlySelectedItem)
    inputRef.current?.blur()
  }

  return (
    <StyledComboBox
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      shouldFilterItem={shouldFilterItem}
      selectedItem={selectedItem}
      initialSelectedItem={selectedItem}
      onChange={({ selectedItem }: { selectedItem: string }) =>
        onChangeHandler(selectedItem)
      }
      ref={inputRef}
      {...props}
    />
  )
}

export default FilterableSelect
