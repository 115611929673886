/* eslint-disable react/display-name */
import React, { ReactNode } from 'react'
import {
  Slider as CarbonSlider,
  SliderProps as CarbonSliderProps,
  SliderSkeleton as CarbonSliderSkeleton,
  NumberInputSkeleton,
  Toggletip,
  ToggletipButton,
  ToggletipContent,
} from '@carbon/react'
import { Information } from '@carbon/react/icons'
import styled from 'styled-components'
import './Slider.scss'

export interface SliderProps extends CarbonSliderProps {
  tooltipText?: ReactNode
  skeleton?: boolean
  // documented, but undefined property on CarbonSliderProps
  disabled?: boolean
  // show tooltip even if is disabled is true
  forceShowTooltip?: boolean
}

const Wrapper = styled.div`
  input {
    width: 7rem;
    padding: 0;
  }
`

export const Slider = React.forwardRef(
  (
    {
      tooltipText,
      skeleton,
      className = '',
      style,
      forceShowTooltip = false,
      ...rest
    }: SliderProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const labelText =
      (tooltipText && rest.disabled === false) || forceShowTooltip ? (
        <div>
          {rest.labelText}
          <span className="cnx-slider-tooltip-icon">
            <Toggletip>
              <ToggletipButton label="information">
                <Information />
              </ToggletipButton>
              <ToggletipContent>
                <div>{tooltipText}</div>
              </ToggletipContent>
            </Toggletip>
          </span>
        </div>
      ) : (
        rest.labelText
      )

    // Currently, Carbon will show an error when you enter a value that does not
    // match the step value. We don't want to show an error for now. If requirements
    // change we can add the those custom validations in this variable.
    //
    // TODO(takeshi): Even with this parameter, when mouse over the input box
    // with an invalid value in it in terms of the step value, it shows a
    // warning tooltip. Remove it.
    const invalid = false

    return skeleton ? (
      <Wrapper
        className={`${className} cnx-slider cnx-slider--skeleton`}
        style={{ ...style, display: 'flex' }}
        ref={ref}
      >
        <CarbonSliderSkeleton />
        <NumberInputSkeleton hideLabel style={{ minWidth: '60px' }} />
      </Wrapper>
    ) : (
      <Wrapper className={`${className} cnx-slider`} style={style}>
        <CarbonSlider {...rest} labelText={labelText} invalid={invalid} />
      </Wrapper>
    )
  },
)

export default Slider
