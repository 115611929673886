import { useQuery, UseQueryOptions } from 'react-query'
import {
  GetAggregatedComputeInstanceSummaryRequest as RequestType,
  GetAggregatedComputeInstanceSummaryResponse as ResponseType,
} from '@cloudnatix-types/dashboard'
import { fetchData } from 'src/api/axios'
import { useRequestParams } from 'src/api/useRequestParams'

const url =
  '/v1/dashboard/infrastructure/compute/instancesummaries:aggregateGet'

export function useInstanceSummariesAggregate<T = ResponseType>(
  request: RequestType,
  options?: UseQueryOptions<ResponseType, any, T>,
) {
  const params = useRequestParams({ request, addOrgFilter: true })

  return useQuery<ResponseType, any, T>(
    [url, params],
    () => fetchData<ResponseType>(url, params),
    {
      cacheTime: 3600000, // 60 * 60 * 1000 = 1 hour
      retry: false,
      ...options,
    },
  )
}
