import { ReactComponent as AmazonIcon } from '../../../../../images/AWS.svg'
import { ReactComponent as DockerIcon } from '../../../../../images/Docker.svg'
import { ReactComponent as GoogleCloudIcon } from '../../../../../images/GCP.svg'
import { ReactComponent as MicrosoftIcon } from '../../../../../images/Microsoft.svg'
import { ReactComponent as OracleCloudIcon } from 'src/images/OCI.svg'
import Text from '../../../Text'

interface ClusterIconProps {
  type?: string
}

export const ClusterIcon = ({ type, ...props }: ClusterIconProps) => {
  switch (type) {
    case 'aws':
      return <AmazonIcon {...props} />
    case 'gcp':
      return <GoogleCloudIcon {...props} />
    case 'azure':
      return <MicrosoftIcon {...props} />
    case 'docker':
      return <DockerIcon {...props} />
    case 'oci':
      return <OracleCloudIcon {...props} />
    default:
      return null
  }
}

interface WorkloadClusterProps {
  cluster: string
  csp?: string // Cluster Service Provider, e.g. aws, gcp
}

export const WorkloadCluster = ({ cluster, csp }: WorkloadClusterProps) => {
  if (!cluster && !csp) {
    return null
  }

  const icon = ClusterIcon({ type: csp }) && <ClusterIcon type={csp} />

  return <Text icon={icon}>{cluster}</Text>
}
