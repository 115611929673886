import React, { useEffect, useState } from 'react'
import { SkeletonPlaceholder } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useOptimizations } from 'src/api/queries'
import { Box, Flex, Grid } from 'src/next/components'
import {
  InsightsCard,
  InsightsCardHPA,
} from 'src/next/components/insights/InsightsCard'
import {
  TableFilter,
  TableFilterAccordionItem,
  TableFilterActiveItems,
  TableFilterSearchSelection,
  TableFilterSlider,
  useTableFilter,
} from 'src/next/components/TableFilter'
import { InlineNotification } from '../InlineNotification'
import { Sidebar, SidebarToggle } from '../Sidebar'
import { InsightsSavingsText } from './InsightsSavingsText'
import { InsightsToolbar } from './InsightsToolbar'
import { DataTablePagination, usePagination } from '../DataTable'
import { K8sTableNsFilter } from 'src/next/components/Workloads/WorkloadsTable/filters/KubernetesTableNsFilter'
import { TableClusterFilter } from 'src/next/components/Filters/TableClusterFilter'
import { isProduction } from 'src/next/utils'

export type InsightTypes =
  | 'kubernetesworkloads'
  | 'hpas'
  | 'clusters'
  | 'computeinstances'

const StyledSkeletonPlaceholder = styled(SkeletonPlaceholder)`
  width: 100%;
  height: 130px;
`

export interface InsightsProps {
  type: InsightTypes
}

export type OrderByItem = {
  id: string
  label: string
}

export const Insights = ({ type }: InsightsProps) => {
  const { t } = useTranslation()

  const { isOpen, toggleFilters, activeFilters } = useTableFilter()

  const orderByItems = useOrderByItems(type)
  const [orderBy, setOrderBy] = useState(orderByItems[0])
  const [isSortDesc, setIsSortDesc] = useState(type !== 'hpas')

  const pagination = usePagination('insights')

  useEffect(() => {
    if (pagination.page > 1) pagination.resetPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, activeFilters])

  const optimizationFilters = { ...activeFilters }

  const getQueryData = queryData => {
    return queryData?.pages?.[pagination.page - 1] || {}
  }
  const query = useOptimizations(type, {
    filter: optimizationFilters,
    orderBy: `${orderBy.id} ${isSortDesc ? 'desc' : 'asc'}`,
    pageSize: pagination.pageSize,
  })

  const { data: queryData, isLoading, isError } = query

  const data = getQueryData(queryData)

  const nameFilterPlaceIDs = {
    kubernetesworkloads: 'name',
    hpas: 'workloadNames',
    clusters: 'name',
    computeinstances: 'name',
  }

  const nameFilterPlaceholders = {
    kubernetesworkloads: t('Insights.Filters.WorkloadNamePlaceHolder'),
    hpas: t('Insights.Filters.WorkloadNamePlaceHolder'),
    clusters: t('Insights.Filters.ClusterNamePlaceHolder'),
    computeinstances: t('Insights.Filters.ComputeInstanceNamePlaceHolder'),
  }

  return (
    <Box mt={4}>
      {type === 'hpas' ? null : (
        <InsightsSavingsText
          isLoading={isLoading}
          savings={data?.totalSavings || 0}
        />
      )}
      <Sidebar>
        <Sidebar.Panel $open={isOpen} $width={300}>
          <TableFilter>
            <TableFilterActiveItems />

            <TableFilterAccordionItem title={t('Insights.Filters.Name')}>
              <TableFilterSearchSelection
                id={nameFilterPlaceIDs[type]}
                placeholder={nameFilterPlaceholders[type]}
              />
            </TableFilterAccordionItem>

            {type === 'kubernetesworkloads' || type === 'hpas' ? (
              <K8sTableNsFilter />
            ) : null}

            {type === 'hpas' ? null : (
              <TableFilterAccordionItem
                title={t('Insights.Filters.MinimumCostSaving')}
              >
                <TableFilterSlider
                  id="minCostSaving"
                  min={0}
                  max={
                    // @ts-ignore Latest types are missing TODO remove this ignore
                    Math.floor(data?.maxCostSaving || 1000)
                  }
                />
              </TableFilterAccordionItem>
            )}

            {type === 'computeinstances' ? (
              <TableClusterFilter includeVMs={true} />
            ) : (
              <TableClusterFilter />
            )}
          </TableFilter>
        </Sidebar.Panel>
        <Sidebar.MainContent>
          <Flex justifyContent="flex-end">
            <InsightsToolbar
              setOrderBy={setOrderBy}
              setIsSortDesc={setIsSortDesc}
              orderByItems={orderByItems}
              isSortDesc={isSortDesc}
              orderBy={orderBy}
            />
            <SidebarToggle open={isOpen} onToggle={toggleFilters} />
          </Flex>
          {!isLoading && (isError || data?.optimizationPlans?.length === 0) ? (
            <InlineNotification title={t('Insights.NoData')} />
          ) : null}
          <Grid
            gap={5}
            gridTemplateColumns={{ _: '1fr', lg: '1fr 1fr' }}
            marginTop={6}
          >
            {isLoading ? (
              [...Array(4)].map((_, i) => <StyledSkeletonPlaceholder key={i} />)
            ) : type === 'hpas' ? (
              <>
                {data?.insights?.map((insight, index) => (
                  <Box key={index}>
                    <InsightsCardHPA insight={insight} />
                  </Box>
                ))}
              </>
            ) : (
              <>
                {data?.optimizationPlans?.map((plan, index) => (
                  <Box key={index}>
                    <InsightsCard plan={plan} />
                  </Box>
                ))}
              </>
            )}
          </Grid>
          {data.optimizationPlans ? (
            <DataTablePagination
              pageSize={pagination.pageSize}
              page={pagination.page}
              onChange={pagination.onChange}
              query={query}
            />
          ) : null}
        </Sidebar.MainContent>
      </Sidebar>
    </Box>
  )
}

const useOrderByItems = (type: InsightTypes): OrderByItem[] => {
  const { t } = useTranslation()

  const orders: OrderByItem[] = []

  if (type !== 'hpas') {
    orders.push({
      id: 'cost_saving',
      label: t('Insights.OrderBy.CostSavings'),
    })
  }

  orders.push({
    id: 'workload_name',
    label: t('Insights.OrderBy.WorkloadName'),
  })

  if (type === 'clusters') {
    orders.push({
      id: 'cluster_name',
      label: t('Insights.OrderBy.ClusterName'),
    })
  }

  if (type !== 'hpas') {
    orders.push({
      id: 'spend',
      label: t('Insights.OrderBy.Spend'),
    })
  }

  return orders
}
