import { QueryObserverOptions, UseQueryResult, useQuery } from 'react-query'
import {
  CLUIKubernetesResource,
  ListCLUIKubernetesResourcesRequest,
  ListCLUIKubernetesResourcesResponse,
  WorkloadFilters,
} from '@cloudnatix-types/dashboard'
import { fetchData, fetchAllPages, useRequestParams } from 'src/api'

export const useCLUIKubernetesResources = (
  params?: ListCLUIKubernetesResourcesRequest,
  options?: QueryObserverOptions<CLUIKubernetesResource[]>,
): UseQueryResult<CLUIKubernetesResource[]> => {
  const url = '/v1/dashboard/cluik8sresources'
  const requestParams = useRequestParams({ request: params })
  return useQuery<CLUIKubernetesResource[]>(
    [url, requestParams],
    () =>
      fetchAllPages<
        CLUIKubernetesResource,
        ListCLUIKubernetesResourcesResponse
      >(url, 'resources', requestParams),
    {
      ...options,
    },
  )
}

export const useCLUIKubernetesResource = (
  uid,
  options?: QueryObserverOptions<CLUIKubernetesResource>,
): UseQueryResult<CLUIKubernetesResource> => {
  const url = `/v1/dashboard/cluik8sresources/${uid}`
  return useQuery<CLUIKubernetesResource>(
    [url],
    () => fetchData<CLUIKubernetesResource>(url),
    {
      ...options,
    },
  )
}
