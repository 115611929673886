import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { InterpolationPropType } from 'victory-core'
import { Summary, ListSummariesResponse } from '@cloudnatix-types/dashboard'
import {
  AreaGraphProps,
  GraphDataPoint,
  StreamChart,
  useLegend,
} from 'src/next/components'
import Loading from 'src/next/components/Loading'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { usePersistentMetricDropdown } from 'src/next/hooks/usePersistentMetricDropdown'
import {
  bytesToUserFriendlySize,
  millicoresToUserFriendlySizeLong,
} from 'src/next/utils'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'
import ChartContainer from './ChartContainer'
import { ResourceType, TimePeriod } from './types'
import { useSummariesQuery } from './useSummariesQuery'
import { getTimeFilters, getTranslations } from './WorkloadCharts.translations'
import { WorkloadResourceTypeHeading } from './WorkloadResourceTypeHeading'

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;
  max-height: 375px;
`

const formatTooltipValue = (value: unknown, type: ResourceType) =>
  type === 'cpu'
    ? millicoresToUserFriendlySizeLong(Number(value), 2)
    : bytesToUserFriendlySize(Number(value))

const getGraphConfig = (resourceType: ResourceType, t: TFunction) => {
  const cpuUsed = {
    id: 'usedCpu',
    label: t('Workloads.TopCharts.UsedCpu'),
    tooltipLabel: t('Workloads.TopCharts.UsedCpu'),
    data: [],
  }
  const cpuUnused = {
    id: 'unusedCpu',
    label: t('Workloads.TopCharts.UnusedCpu'),
    tooltipLabel: t('Workloads.TopCharts.UnusedCpu'),
    data: [],
  }
  const memUsed = {
    id: 'usedMemory',
    label: t('Workloads.TopCharts.UsedMemory'),
    tooltipLabel: t('Workloads.TopCharts.UsedMemory'),
    data: [],
  }
  const memUnused = {
    id: 'unusedMemory',
    label: t('Workloads.TopCharts.UnusedMemory'),
    tooltipLabel: t('Workloads.TopCharts.UnusedMemory'),
    data: [],
  }

  return [
    {
      ...(resourceType === 'cpu' ? cpuUsed : memUsed),
      type: 'area' as const,
      tooltipValueTransformFn: (value: unknown) =>
        formatTooltipValue(value, resourceType),
      props: {
        style: { data: { fill: 'var(--carbonPalette4)' } },
        interpolation: 'basis' as InterpolationPropType,
      },
      enabled: true,
    },
    {
      ...(resourceType === 'cpu' ? cpuUnused : memUnused),
      type: 'area' as const,
      tooltipValueTransformFn: (value: unknown) =>
        formatTooltipValue(value, resourceType),
      props: {
        style: { data: { fill: 'var(--carbonPalette3)' } },
        interpolation: 'basis' as InterpolationPropType,
      },
      enabled: true,
    },
  ]
}

const tickFormatMap: Record<TimePeriod, string> = {
  daily: 'MM/DD',
  weekly: 'MMM D',
  monthly: 'MMM D',
}

const WorkloadStreamChart = () => {
  const { t } = useTranslation()
  const translations = getTranslations(t)

  const { selectedItem: resourceType, dropdownProps } =
    usePersistentMetricDropdown('workloads-stream-resource-type')

  const graphConfigBase = useMemo(
    () => getGraphConfig(resourceType, t),
    [resourceType, t],
  )

  const { Legend, graphConfig, legendProps } =
    useLegend<AreaGraphProps>(graphConfigBase)

  const timeFilterItems = useMemo(() => getTimeFilters(t), [t])
  const [timeFilter, setTimeFilter] = useLocalStorage<TimePeriod>(
    'workloads-stream-chart-time-filter',
    'daily',
  )

  type Key = keyof Summary
  type Result = {
    [key in keyof Summary]: GraphDataPoint[]
  }

  const transformToGraphPoints = (data: ListSummariesResponse) =>
    data.orgSummaries?.reduce(
      (acc: Result, item: Summary) => {
        Object.keys(acc).forEach(key => {
          acc[key as Key]?.push({
            x: new Date(nanoToMilliSeconds(item.timestampNs ?? 0)),
            y: item[key as Key] as number, // Why number casing needed here?
          })
        })
        return acc
      },
      {
        usedMemory: [],
        unusedMemory: [],
        usedCpu: [],
        unusedCpu: [],
      },
    )

  const { data: chartSummariesData, isLoading } = useSummariesQuery(
    timeFilter,
    {
      select: transformToGraphPoints,
    },
  )

  // when switching between resourceType, we need to wait for useLegend to update
  const isLegendEnabled = graphConfig.find(c => c.enabled)

  if (!isLegendEnabled) return null

  // Filter graphs based on the legend
  const filteredGraphConfig = graphConfig.map(config => {
    return {
      ...config,
      data: chartSummariesData?.[config.id as Key]!,
    }
  })

  const noData = Object.values(chartSummariesData || {}).flat().length === 0

  if (!isLoading && noData) throw new Error('No data available')

  return (
    <ChartContainer
      selectedItem={timeFilter}
      onChange={setTimeFilter}
      menuItems={timeFilterItems.range}
      heading={
        <WorkloadResourceTypeHeading
          id="stream-chart-resource-type"
          label={`${translations[timeFilter]} ${t(
            'Workloads.TopCharts.Labels.Range',
          )}`}
          dropdownProps={dropdownProps}
        />
      }
    >
      <ChartWrapper>
        {isLoading ? (
          <Loading centered size="small" withOverlay={false} />
        ) : (
          <>
            <StreamChart
              centerY
              data={filteredGraphConfig}
              width={640}
              height={340}
              unit={resourceType === 'memory' ? 'bytes' : 'cores'}
              xAxis={[
                {
                  tickFormat: (x: number) =>
                    dayjs(x).format(tickFormatMap[timeFilter]),
                },
              ]}
            />
            <Legend {...legendProps} />
          </>
        )}
      </ChartWrapper>
    </ChartContainer>
  )
}

export default WorkloadStreamChart
