import React, { memo, useEffect, useReducer } from 'react'
import InstallCLIModal from '../../components/InstallCLIModal'
import { useCliChannels } from './useCliChannels'
import useCliDistributionVersions from './useCliDistributionVersions'
import useCliDownloadUrl from './useCliDownloadURL'

export interface InstallCLIModalState {
  OS: null | 'darwin-amd64' | 'linux-amd64' | 'windows'
  version: null | string
  channel: null | string
}

export type InstallCLIModalAction =
  | { type: 'selectOS'; value: InstallCLIModalState['OS'] }
  | { type: 'selectVersion'; value: InstallCLIModalState['version'] }
  | { type: 'selectChannel'; value: InstallCLIModalState['channel'] }

const initialState: InstallCLIModalState = {
  OS: null,
  version: null,
  channel: null,
}

const formReducer = (
  state: InstallCLIModalState,
  action: InstallCLIModalAction,
) => {
  switch (action.type) {
    case 'selectOS':
      return {
        ...state,
        OS: action.value,
        channel: null,
      }
    case 'selectVersion':
      return {
        ...state,
        version: action.value,
        channel: null,
      }
    case 'selectChannel':
      return {
        ...state,
        channel: action.value,
      }
    default:
      throw new Error(`Unsupported action: ${action}`)
  }
}

const getCommand = (formState: InstallCLIModalState, downloadUrl?: string) => {
  const { OS } = formState
  if (!downloadUrl) return null

  if (OS === 'linux-amd64' || OS === 'darwin-amd64') {
    return `curl "${downloadUrl}" --output cnatix &&
sudo mv cnatix /usr/local/bin &&
sudo chmod +x /usr/local/bin/cnatix &&
cnatix`
  }
  return `# Command line TBA
      \n
      # Please download
      ${downloadUrl}
      manually`
}

interface Props {
  open: boolean
  onClose(): void
}

const InstallCLIModalContainer: React.FC<Props> = props => {
  const [formState, dispatch] = useReducer(formReducer, initialState)

  // fetch distribution versions
  const {
    data: distributionVersionsList,
    status: distributionVersionsListStatus,
  } = useCliDistributionVersions({
    enabled: !!formState.OS,
    staleTime: 60000,
  })

  const { data: channelsList, status: channelsListStatus } = useCliChannels(
    {
      distributionVersion: formState.version || '',
      kind: 'cli',
    },
    formState.OS || '',
    {
      enabled: !!(formState.OS && formState.version),
      staleTime: 60000,
      select: data => data.channelNames,
    },
  )

  // fetch download URL
  const { data: downloadUrl, status: downloadUrlStatus } = useCliDownloadUrl(
    formState.OS,
    formState.version,
    formState.channel,
    {
      enabled: !!(formState.OS && formState.version && formState.channel),
      staleTime: 60000,
      retry: false,
    },
  )

  // set default OS based on user agent
  useEffect(() => {
    let OS: InstallCLIModalState['OS'] = null

    if (navigator.userAgent.indexOf('Mac') >= 0) OS = 'darwin-amd64'
    else if (
      navigator.userAgent.indexOf('X11') >= 0 ||
      navigator.userAgent.indexOf('Linux') >= 0
    )
      OS = 'linux-amd64'
    dispatch({ type: 'selectOS', value: OS })
  }, [])

  // set to latest version
  useEffect(() => {
    if (distributionVersionsList?.length)
      dispatch({ type: 'selectVersion', value: distributionVersionsList[0] })
  }, [distributionVersionsList])

  // set to first channel in list
  useEffect(() => {
    if (channelsList?.length)
      dispatch({ type: 'selectChannel', value: channelsList[0] })
  }, [channelsList])

  // generate/copy the command
  const command = getCommand(formState, downloadUrl)

  return (
    <InstallCLIModal
      selectedOS={formState.OS}
      selectedVersion={formState.version}
      selectedChannel={formState.channel}
      onChangeOS={(value: InstallCLIModalState['OS']) =>
        dispatch({
          type: 'selectOS',
          value,
        })
      }
      onChangeVersion={(value: InstallCLIModalState['version']) =>
        dispatch({
          type: 'selectVersion',
          value,
        })
      }
      onChangeChannel={(value: InstallCLIModalState['channel']) =>
        dispatch({
          type: 'selectChannel',
          value,
        })
      }
      distributionVersionsList={distributionVersionsList}
      distributionVersionsListStatus={distributionVersionsListStatus}
      channelsList={channelsList}
      channelsListStatus={channelsListStatus}
      downloadUrl={downloadUrl}
      downloadUrlStatus={downloadUrlStatus}
      command={command}
      defaultCommand="sudo chmod +x /usr/local/bin/cnatix && cnatix"
      {...props}
    />
  )
}

export default memo(InstallCLIModalContainer)
