import React from 'react'
import styled, { css } from 'styled-components'
import Loading from '../../Loading'

const Wrapper = styled.div`
  position: relative;
`

const LoadingWrapper = styled.div<{
  $loading?: boolean
  $keepOpacity?: boolean
}>`
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  ${({ $loading, $keepOpacity }) =>
    $loading &&
    css`
      opacity: ${$keepOpacity ? 'inherit' : '0.2 !important'};
      filter: saturate(0%);
      pointer-events: none;

      svg {
        pointer-events: none !important;
      }
    `}
`

const MessageWrapper = styled.div`
  position: absolute;
  top: 60%;
  left: 25%;
  right: 25%;
  text-align: center;
`

export interface LoadingGraphProps {
  children: React.ReactNode
  isLoading: boolean
  keepOpacity?: boolean
  hideSpinner?: boolean
  message?: string
}

export const LoadingGraph = ({
  isLoading,
  hideSpinner,
  keepOpacity,
  message,
  children,
  ...props
}: LoadingGraphProps) => {
  return (
    <Wrapper {...props}>
      <LoadingWrapper $loading={isLoading} $keepOpacity={keepOpacity}>
        {children}
      </LoadingWrapper>
      {isLoading && !hideSpinner && <Loading centered withOverlay={false} />}
      {isLoading && message && <MessageWrapper>{message}</MessageWrapper>}
    </Wrapper>
  )
}
