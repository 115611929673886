import { useMemo } from 'react'
import { flatten, groupBy, sumBy } from 'lodash'
import max from 'lodash/max'
import maxBy from 'lodash/maxBy'
import min from 'lodash/min'
import minBy from 'lodash/minBy'
import { GraphDataPoint } from 'src/next/components/Graphs'

export const useGraphBoundaries = (data: GraphDataPoint[][], stack = false) => {
  const boundariesPerGraph = useMemo(() => {
    if (stack) {
      // For stacked graphs, we need to calculate the boundaries by adding all
      // values per timestamp together.
      //
      // 1. Flatten all graphs into single array;
      // 2. Group by timestamp;
      // 3. Sum all values per timestamp
      // 4. Create new array with the summed value per timestamp.
      const summedPerTimestamp: GraphDataPoint[] = Object.values(
        groupBy(flatten(data), 'x'),
      ).map(values => ({
        x: values[0].x,
        y: sumBy(values, 'y'),
      }))

      return [
        {
          minX: min(summedPerTimestamp.map(d => d.x)),
          maxX: max(summedPerTimestamp.map(d => d.x)),
          minY: minBy(summedPerTimestamp, d => d.y)?.y,
          maxY: maxBy(summedPerTimestamp, d => d.y)?.y,
        },
      ]
    }

    // For normal graphs just find the highest and lowest values.
    return data.map(graphData => ({
      minX: min(graphData.map(d => d.x)),
      maxX: max(graphData.map(d => d.x)),
      minY: minBy(graphData, d => d.y)?.y,
      maxY: maxBy(graphData, d => d.y)?.y,
    }))
  }, [data, stack])

  return useMemo(
    () => ({
      minX: min(boundariesPerGraph.map(b => b.minX)),
      maxX: max(boundariesPerGraph.map(b => b.maxX)),
      minY: min(boundariesPerGraph.map(b => b.minY)),
      maxY: max(boundariesPerGraph.map(b => b.maxY)),
    }),
    [boundariesPerGraph],
  )
}
