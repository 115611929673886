import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from 'src/next/components'
import { ClustersTable } from 'src/next/components/Clusters'
import { ClustersOverviewChart } from 'src/next/components/Clusters/ClustersOverviewChart'
import { CollapseComponent, PageTitle } from 'src/next/components/ui'
import {
  NoClustersBanner,
  useHasClusters,
} from '../../components/NoClustersBanner'
import {
  TableFilterContainer,
  TableFilterContextProvider,
} from 'src/next/components/TableFilter'
import { ClustersTableFilters } from 'src/next/components/Clusters/TableFilters'

export const ClustersPage = () => {
  const { t } = useTranslation()
  const { isLoading, hasClusters } = useHasClusters()

  return (
    <>
      <PageTitle title={t('PageTitle.Clusters')} />
      {!isLoading && !hasClusters ? (
        <Flex mt={-6} mb={6} justifyContent="center">
          <NoClustersBanner />
        </Flex>
      ) : (
        <>
          <CollapseComponent>
            <ClustersOverviewChart />
          </CollapseComponent>

          <TableFilterContextProvider localStorageId="clusters-page-filters">
            <TableFilterContainer data-testid="clusters-table-filters">
              <ClustersTableFilters />
              <ClustersTable />
            </TableFilterContainer>
          </TableFilterContextProvider>
        </>
      )}
    </>
  )
}
