/* eslint-disable @typescript-eslint/no-empty-function */
import { CaretDown, TreeViewAlt } from '@carbon/react/icons'
import * as Popover from '@radix-ui/react-popover'
import * as Carbon from '@carbon/react'
import styled from 'styled-components'
import { Org } from '@cloudnatix-types/orgcontroller'
import { useOrgs } from 'src/api'
import { Box, Flex } from 'src/next/components'
import { useOrgSelection } from 'src/next/hooks'
import { useUserSelf } from 'src/api'
import { enableComboBoxOrgSelector } from 'src/App/util'
import { useTranslation } from 'react-i18next'

interface ComboBoxItem {
  id: string
  text: string
}

const StyledPopoverTrigger = styled(Popover.Trigger)`
  color: var(--cds-icon-primary);
  width: fit-content;
  padding: var(--cds-spacing-05);
  margin-left: var(--cds-spacing-01);

  &[data-state='open'] {
    background: var(--cds-layer-01);
  }

  &&:hover {
    color: var(--cds-text-primary);
  }
`

type OrgWithChildren = Org & { subOrgs: OrgWithChildren[] }

export const OrgSelector = () => {
  const { t } = useTranslation()

  const { data: orgsData } = useOrgs()

  const { data: user } = useUserSelf()

  const [selectedOrgUuid, setSelectedOrgUuid] = useOrgSelection()

  if (!selectedOrgUuid || !orgsData?.orgs) return null

  const selectedOrg = orgsData.orgs.find(org => org.uuid === selectedOrgUuid)

  const orgTree = createOrgTree(orgsData.orgs)

  const isComboBoxOrgSelectorEnabled = enableComboBoxOrgSelector(user?.user)

  const buildComboBoxItems = (
    orgTreeNode: OrgWithChildren[],
    prefix: string,
  ): ComboBoxItem[] => {
    const items: ComboBoxItem[] = []
    for (const org of orgTreeNode) {
      if (org.uuid === undefined) {
        continue
      }

      items.push({
        id: org.uuid,
        text: `${prefix}${org.name || t('OrgSelector.UnnamedOrg')}`,
      })

      if (org.subOrgs.length > 0) {
        const subItems = buildComboBoxItems(
          org.subOrgs,
          `${prefix}${org.name} > `,
        )
        for (const item of subItems) {
          items.push(item)
        }
      }
    }
    return items
  }

  const isSingleOrganization =
    orgTree?.length === 1 && orgTree[0].subOrgs?.length === 0

  if (isSingleOrganization) {
    return (
      <Box ml="1" pl="5">
        {selectedOrg?.name}
      </Box>
    )
  }

  return (
    <Popover.Root>
      <StyledPopoverTrigger
        className="cds--header__action cds--btn cds--btn--primary"
        data-testid="org-selection-trigger"
      >
        <Flex alignItems="center">
          <TreeViewAlt size={20} />
          <Box pl="3" pr="2">
            {selectedOrg?.name}
          </Box>
          <CaretDown />
        </Flex>
      </StyledPopoverTrigger>
      <Popover.Content data-testid="org-selection-content" align="start">
        {isComboBoxOrgSelectorEnabled ? (
          <Box
            bg="layer-01"
            style={{ boxShadow: '0 2px 12px var(--cds-shadow)' }}
            minWidth="60em"
          >
            <Carbon.ComboBox<ComboBoxItem>
              id="org-selector"
              placeholder={t('OrgSelector.PlaceHolder')}
              onFocus={e => {
                // Invoke a click event to open the list of orgs automatically
                // right after the ComboBox is shown.
                e.target.click()
              }}
              onChange={e => {
                const item = e.selectedItem
                if (item === undefined || item === null) {
                  return
                }
                setSelectedOrgUuid(item.id)
              }}
              items={buildComboBoxItems(orgTree, '')}
              shouldFilterItem={({ item, itemToString, inputValue }) => {
                if (inputValue === null || itemToString === undefined)
                  return true
                return itemToString(item)
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }}
              itemToString={item => {
                if (item === undefined || item === null) {
                  return ''
                }

                return item.text
              }}
            />
          </Box>
        ) : (
          <Box
            bg="layer-01"
            style={{ boxShadow: '0 2px 12px var(--cds-shadow)' }}
            minWidth="250px"
            maxWidth="400px"
          >
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Carbon.TreeView
              label="Organizations"
              hideLabel
              selected={[`${selectedOrgUuid}-org-select`]}
              active={`${selectedOrgUuid}-org-select`}
              onSelect={(_, node) => setSelectedOrgUuid(node.value!)}
            >
              {orgTree && renderTree(orgTree)}
            </Carbon.TreeView>
          </Box>
        )}
      </Popover.Content>
    </Popover.Root>
  )
}

const createOrgTree = (orgs: Org[]) => {
  const store = Object.create(null)
  orgs.forEach(org => (store[org.uuid!] = { ...org, subOrgs: [] }))
  const dataTree: OrgWithChildren[] = []
  orgs.forEach(org => {
    if (org.parentOrgUuid)
      store[org.parentOrgUuid].subOrgs.push(store[org.uuid!])
    else dataTree.push(store[org.uuid!])
  })
  return dataTree
}

const renderTree = (orgs: OrgWithChildren[]) => {
  return orgs.map(org => {
    return (
      // eslint-disable-next-line react/jsx-pascal-case
      <Carbon.TreeNode
        id={`${org.uuid}-org-select`}
        key={org.uuid}
        value={org.uuid}
        label={org.name}
        // needed for keyboard expand
        onToggle={() => {}}
        isExpanded
      >
        {org.subOrgs.length > 0 && renderTree(org.subOrgs)}
      </Carbon.TreeNode>
    )
  })
}
