import { useMemo } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { GetTimeFilters, TopSummariesDropdownItems } from './types'

export const getTimeFilters = (t: TFunction): GetTimeFilters => ({
  average: [
    {
      id: 'daily',
      label: t('Workloads.TopCharts.Filters.DailyAverage'),
    },
    {
      id: 'weekly',
      label: t('Workloads.TopCharts.Filters.WeeklyAverage'),
    },
    {
      id: 'monthly',
      label: t('Workloads.TopCharts.Filters.MonthlyAverage'),
    },
  ],
  range: [
    {
      id: 'daily',
      label: t('Workloads.TopCharts.Filters.DailyRange'),
    },
    {
      id: 'weekly',
      label: t('Workloads.TopCharts.Filters.WeeklyRange'),
    },
    {
      id: 'monthly',
      label: t('Workloads.TopCharts.Filters.MonthlyRange'),
    },
  ],
  rollup: [
    {
      id: 'daily',
      label: t('Workloads.TopCharts.Filters.DailyRollup'),
    },
    {
      id: 'weekly',
      label: t('Workloads.TopCharts.Filters.WeeklyRollup'),
    },
    {
      id: 'monthly',
      label: t('Workloads.TopCharts.Filters.MonthlyRollup'),
    },
  ],
})

export const getTranslations = (t: TFunction) => ({
  daily: t('Common.TimePeriod.Daily'),
  weekly: t('Common.TimePeriod.Weekly'),
  monthly: t('Common.TimePeriod.Monthly'),
})

export const getLimits = (
  t: TFunction,
  noLimit?: boolean,
): TopSummariesDropdownItems['limit'] => [
  {
    id: '5',
    label: `${t('Workloads.TopCharts.Rollup.FilterItems.Top')} 5`,
  },
  {
    id: '10',
    label: `${t('Workloads.TopCharts.Rollup.FilterItems.Top')} 10`,
  },
  ...(noLimit === true
    ? [
        {
          id: '0',
          label: `${t('Workloads.TopCharts.Rollup.FilterItems.All')}`,
        },
      ]
    : []),
]

export const useKubernetesWorkloadTopSummariesDropdownItems = (): Omit<
  TopSummariesDropdownItems,
  'limit'
> => {
  const { t } = useTranslation()
  return useMemo(
    () => ({
      type: [
        {
          id: 'WORKLOAD',
          label: t('Workloads.TopCharts.Rollup.FilterItems.Workloads'),
        },
        {
          id: 'NAMESPACE',
          label: t('Workloads.TopCharts.Rollup.FilterItems.Namespaces'),
        },
        {
          id: 'ORG',
          label: t('Workloads.TopCharts.Rollup.FilterItems.Organizations'),
        },
      ],
      metric: [
        {
          id: 'CPU_AVG',
          label: t('Resources.AvgCpuUsage'),
        },
        {
          id: 'CPU_MAX',
          label: t('Resources.MaxCpuUsage'),
        },
        {
          id: 'MEMORY_AVG',
          label: t('Resources.AvgMemoryUsage'),
        },
        {
          id: 'MEMORY_MAX',
          label: t('Resources.MaxMemoryUsage'),
        },
        {
          id: 'TOTAL_COST',
          label: t('Resources.TotalCost'),
        },
        {
          id: 'AVG_CPU_REQUESTED',
          label: t('Resources.AvgCpuRequested'),
        },
        {
          id: 'MAX_CPU_REQUESTED',
          label: t('Resources.MaxCpuRequested'),
        },
        {
          id: 'AVG_MEMORY_REQUESTED',
          label: t('Resources.AvgMemoryRequested'),
        },
        {
          id: 'MAX_MEMORY_REQUESTED',
          label: t('Resources.MaxMemoryRequested'),
        },
        {
          id: 'AVG_CPU_LIMIT',
          label: t('Resources.AvgCpuLimit'),
        },
        {
          id: 'MAX_CPU_LIMIT',
          label: t('Resources.MaxCpuLimit'),
        },
        {
          id: 'AVG_MEMORY_LIMIT',
          label: t('Resources.AvgMemoryLimit'),
        },
        {
          id: 'MAX_MEMORY_LIMIT',
          label: t('Resources.MaxMemoryLimit'),
        },
        {
          id: 'AVG_CPU_CAPACITY',
          label: t('Resources.AvgCpuCapacity'),
        },
        {
          id: 'MAX_CPU_CAPACITY',
          label: t('Resources.MaxCpuCapacity'),
        },
        {
          id: 'AVG_MEMORY_CAPACITY',
          label: t('Resources.AvgMemoryCapacity'),
        },
        {
          id: 'MAX_MEMORY_CAPACITY',
          label: t('Resources.MaxMemoryCapacity'),
        },
      ],
    }),
    [t],
  )
}
