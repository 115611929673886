import React from 'react'
import { SkeletonText } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { Typography } from 'src/next/components'
import { currencyFormat } from 'src/next/utils'
import { Box } from '../Box'

interface Props {
  savings: number
  isLoading: boolean
}

export const InsightsSavingsText = ({ savings, isLoading }: Props) => {
  const { t } = useTranslation()

  return (
    <Box minHeight={30}>
      {isLoading ? (
        <SkeletonText style={{ width: 200, height: 24 }} />
      ) : (
        <Typography variant="heading-03" as="h2">
          {savings > 0
            ? t('Insights.SavingsTitleWithAmount', {
                savings: currencyFormat(savings),
              })
            : t('Insights.SavingsTitle')}
        </Typography>
      )}
    </Box>
  )
}
