/* eslint-disable react/no-unknown-property */
import React from 'react'
import { PiggyBank } from '@carbon/react/icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { HpaInsight } from '@cloudnatix-types/dashboard'
import { Link, Typography } from 'src/next/components'
import Text from 'src/next/components/Text'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  background: var(--cds-layer-02);
  padding: var(--cds-spacing-05);
  gap: var(--cds-spacing-05);
`

export interface InsightsCardHPAProps {
  insight: HpaInsight
}

export const InsightsCardHPA = ({ insight }: InsightsCardHPAProps) => {
  const { t } = useTranslation()

  const kindDescriptionMap = {
    SCALE_DOWN: t('Insights.HPAs.Kind.ScaleDown'),
    SCALE_UP: t('Insights.HPAs.Kind.ScaleUp'),
    SCALE_OUT: t('Insights.HPAs.Kind.ScaleOut'),
    SCALE_IN: t('Insights.HPAs.Kind.ScaleIn'),
    LINT: t('Insights.HPAs.Kind.Lint'),
    TARGET_METRIC_UP: t('Insights.HPAs.Kind.TargetMetricUp'),
    TARGET_METRIC_DOWN: t('Insights.HPAs.Kind.TargetMetricDown'),
    REPLICA_LIMITS_MALFORMED: t('Insights.HPAs.Kind.ReplicaLimitsMalformed'),
    INEFFECTIVE_TARGET: t('Insights.HPAs.Kind.IneffectiveTarget'),
  }
  const kind = insight.kind
  const kindDescription =
    kind === undefined
      ? ''
      : kindDescriptionMap[kind] === undefined
      ? kind
      : kindDescriptionMap[kind]

  return (
    <Container>
      <Text icon={<PiggyBank />}>
        <Typography
          variant="productive-heading-03"
          style={{ lineHeight: 1.1, marginTop: '-2px' }}
        >
          {kindDescription}
        </Typography>
      </Text>
      <Text>
        <Typography variant="productive-heading-02" style={{ lineHeight: 1.1 }}>
          {`${insight.workloadName}.${insight.namespace} (${insight.clusterName})`}
        </Typography>
      </Text>

      <Link
        to={`/app/workload/${encodeURIComponent(
          insight.workloadUid!,
        )}?tabs=hpas`}
        state={{ from: `${location.pathname}${location.search}` }}
      >
        {t('Common.Recommendation')}: {insight.description}
      </Link>
    </Container>
  )
}
