import React, { lazy, Suspense } from 'react'
import { ContentSwitcherOnChangeData } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { Box } from 'src/next/components'
import Loading from 'src/next/components/Loading'
import {
  TableFilterContainer,
  TableFilterContextProvider,
} from 'src/next/components/TableFilter'
import Typography from 'src/next/components/Typography'
import {
  K8sTableFilters,
  KubernetesTable,
} from 'src/next/components/Workloads/WorkloadsTable'
import { WorkloadCategory } from 'src/next/components/Workloads/WorkloadsTable/types'
import { WorkloadCharts } from 'src/next/containers/WorkloadCharts'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import WorkloadCategorySwitcher from './components/WorkloadCategorySwitcher'

const ComputeInstancesTableFilters = lazy(
  () =>
    import(
      'src/next/components/Workloads/WorkloadsTable/filters/ComputeInstancesTableFilters'
    ),
)
const ComputeInstancesTable = lazy(
  () =>
    import(
      'src/next/components/Workloads/WorkloadsTable/ComputeInstancesTable'
    ),
)

const EcsTable = lazy(
  () => import('src/next/components/Workloads/WorkloadsTable/EcsTable'),
)

const Workloads = () => {
  const { t } = useTranslation()

  const [category, setCategory] = useLocalStorage<WorkloadCategory>(
    'workloads-page-category',
    'kubernetes',
  )

  return (
    <>
      <Box mb={6}>
        <Typography variant="heading-03" as="h4">
          {t('Workload.workloads', 'Workloads')}
        </Typography>
      </Box>

      <WorkloadCategorySwitcher
        category={category}
        onChange={({ name }: ContentSwitcherOnChangeData) =>
          setCategory(name as WorkloadCategory)
        }
      />

      {category === 'kubernetes' ? (
        <>
          <WorkloadCharts />
          <TableFilterContextProvider
            localStorageId="kubernetes-page-filters"
            defaultValues={{
              hideSystemWorkload: true,
              hideCnatixManagedWorkload: true,
              hideDeletedWorkload: false,
            }}
          >
            <TableFilterContainer data-testid="kubernetes-table-filters">
              <K8sTableFilters />
              <KubernetesTable />
            </TableFilterContainer>
          </TableFilterContextProvider>
        </>
      ) : category === 'vm' ? (
        <Suspense fallback={<Loading withOverlay={false} centered />}>
          <TableFilterContextProvider
            localStorageId="vm-page-filters"
            defaultValues={{}}
          >
            <TableFilterContainer data-testid="vm-table-filters">
              <ComputeInstancesTableFilters />
              <ComputeInstancesTable />
            </TableFilterContainer>
          </TableFilterContextProvider>
        </Suspense>
      ) : (
        <Suspense fallback={<Loading withOverlay={false} centered />}>
          <EcsTable />
        </Suspense>
      )}
    </>
  )
}

export default Workloads
