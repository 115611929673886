import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, FeatureFlag } from 'src/next/components'
import { Insights, InsightTypes } from 'src/next/components/insights'
import { TableFilterContextProvider } from 'src/next/components/TableFilter'
import {
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from 'src/next/components/Tabs'
import { PageTitle } from 'src/next/components/ui'
import { useUrlSearchParams } from 'src/next/hooks'
import { isProduction } from 'src/next/utils'

interface TabType {
  id: InsightTypes
  label: string
}

export const InsightsPage = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const tabs = [
    {
      id: 'kubernetesworkloads',
      label: t('Insights.Tabs.KubernetesWorkloads'),
    },
    ...(isProduction() === false
      ? [
          {
            id: 'hpas',
            label: t('Insights.Tabs.HPAs'),
          },
        ]
      : []),
    {
      id: 'clusters',
      label: t('Insights.Tabs.Clusters'),
    },
    {
      id: 'computeinstances',
      label: t('Insights.Tabs.ComputeInstances'),
    },
  ] as TabType[]

  const { tab: tabIdFromUrl } = useUrlSearchParams()

  const initiallySelectedTab = tabIdFromUrl
    ? Math.max(
        0,
        tabs.findIndex(tab => tab.id === tabIdFromUrl),
      )
    : 0

  const [selectedTab, setSelectedTab] = useState(initiallySelectedTab)

  const handleTabClick = ({ selectedIndex }) => {
    setSelectedTab(selectedIndex)

    const params = new URLSearchParams({ tab: tabs[selectedIndex].id })
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    })
  }

  return (
    <>
      <PageTitle title={t('Insights.PageTitle')} />

      <Box mb={5}>
        <Tabs
          type="container"
          selectedIndex={selectedTab}
          onChange={handleTabClick}
        >
          <TabList>
            {tabs.map(tab => (
              <Tab key={tab.id}>{tab.label}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabs.map(tab => (
              <TabPanel key={tab.id}>
                <TableFilterContextProvider
                  defaultValues={{ clusterUuids: [] }}
                  defaultOpen
                >
                  {tab.id === 'hpas' ? (
                    <FeatureFlag>
                      <Insights type={tab.id} />
                    </FeatureFlag>
                  ) : (
                    <Insights type={tab.id} />
                  )}
                </TableFilterContextProvider>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </>
  )
}
