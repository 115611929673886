import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ComputeInstanceGroup } from '@cloudnatix-types/dashboard'
import { EfficiencyCell } from 'src/next/components/dashboard/tabs/tables/EfficiencyCell'
import MiddleTruncate from 'src/next/components/MiddleTruncate'
import { currencyFormat } from 'src/next/utils'

export const useFormatRows = (data?: ComputeInstanceGroup[]) => {
  const { t } = useTranslation()

  return useMemo(() => {
    if (!data) {
      return []
    }

    return (
      data.map(row => ({
        id: row.uid || `${row.efficiency}-${row.waste}-${row.spend}`,
        uid: row.uid,
        name: (
          <MiddleTruncate
            charsAtEnd={30}
            charsAtStart={30}
            text={row.name || ''}
          />
        ),
        spend: row.spend!,
        spendFormatted: currencyFormat(row.spend!),
        waste: row.waste!,
        wasteFormatted: currencyFormat(row.waste!),
        efficiency: row.efficiency ? 1 - row.efficiency : undefined,
        efficiencyFormatted: row.efficiency
          ? `${((1 - row.efficiency) * 100).toFixed(0)}% ${t(
              'Resources.AvgWaste',
            )}`
          : '---',
        cpu: row.utilizations?.[0].utilization,
        memory: row.utilizations?.[1].utilization,
        cpuFormatted: <EfficiencyCell efficiency={row.utilizations?.[0]!} />,
        memoryFormatted: <EfficiencyCell efficiency={row.utilizations?.[1]!} />,
      })) || []
    )
  }, [data])
}

export const useHeaders = () => {
  const { t } = useTranslation()

  return useMemo(() => {
    return [
      {
        key: 'name',
        sort: 'id',
        header: t('Dashboard.Common.DataTable.Name'),
      },
      {
        key: 'spendFormatted',
        sort: 'spend',
        header: t('Common.Spend'),
      },
      {
        key: 'wasteFormatted',
        sort: 'waste',
        header: t('Common.Waste'),
      },
      {
        key: 'efficiencyFormatted',
        sort: 'efficiency',
        header: t('Dashboard.Common.DataTable.CostEfficiency'),
      },
      {
        key: 'cpuFormatted',
        sort: 'cpu',
        header: t('Dashboard.Common.CPUEfficiency'),
      },
      {
        key: 'memoryFormatted',
        sort: 'memory',
        header: t('Dashboard.Common.MemoryEfficiency'),
      },
    ]
  }, [t])
}
