import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  TableFilter,
  TableFilterAccordion,
  TableFilterAccordionItem,
  TableFilterActiveItems,
  TableFilterCheckbox,
  TableFilterCheckboxGroup,
  TableFilterSearchSelection,
} from 'src/next/components/TableFilter'
import ShowMore from 'src/next/components/wrappers/ShowMore'
import { K8sTableNsFilter } from './KubernetesTableNsFilter'
import { TableClusterFilter } from 'src/next/components/Filters/TableClusterFilter'

export const K8sTableFilters = () => {
  const { t } = useTranslation()

  // Note: labels currently do not work. Awaiting BE issue CNATIX-2217
  // const { data: labelKeys, isLoading: isLoadingLabelKeys } = useLabelKeysQuery(
  //   {},
  //   {
  //     enabled: isOpen,
  //     staleTime: 900000,
  //     select: (data) => data.keys,
  //   }
  // );

  return (
    <TableFilter>
      <TableFilterActiveItems />
      <div data-testid="k8s-filter-items">
        <TableFilterAccordion>
          <TableFilterAccordionItem
            title={t('Workloads.K8sTable.Filters.Status')}
          >
            <TableFilterCheckbox
              id="hideSystemWorkload"
              labelText={t('Workloads.K8sTable.Filters.HideSystem')}
            />
            <TableFilterCheckbox
              id="hideCnatixManagedWorkload"
              labelText={t('Workloads.K8sTable.Filters.HideCnManaged')}
            />
            <TableFilterCheckbox
              id="hideDeletedWorkload"
              labelText={t('Workloads.K8sTable.Filters.HideDeleted')}
            />
          </TableFilterAccordionItem>

          {/* Names */}
          <TableFilterAccordionItem
            title={t('Workloads.K8sTable.Filters.Names')}
          >
            <TableFilterSearchSelection
              id="names"
              placeholder={t('Workloads.K8sTable.Filters.NamesPlaceHolder')}
            />
          </TableFilterAccordionItem>

          {/* Namespaces */}
          <K8sTableNsFilter />

          {/* Type */}
          <TableFilterAccordionItem
            title={t('Workloads.K8sTable.Filters.Kinds')}
          >
            <TableFilterCheckboxGroup group="kinds">
              <ShowMore
                maxItems={4}
                maxItemsTolerance={1}
                collapseLabel={t('Common.ShowLessEllipsis')}
                expandLabel={t('Common.ShowMoreEllipsis')}
              >
                <TableFilterCheckbox id="Pod" labelText="Pod" />
                <TableFilterCheckbox id="Deployment" labelText="Deployment" />
                <TableFilterCheckbox id="DaemonSet" labelText="DaemonSet" />
                <TableFilterCheckbox id="ReplicaSet" labelText="ReplicaSet" />
                <TableFilterCheckbox id="StatefulSet" labelText="StatefulSet" />
                <TableFilterCheckbox id="CronJob" labelText="CronJob" />
                <TableFilterCheckbox id="Job" labelText="Job" />
                <TableFilterCheckbox id="Rollout" labelText="Rollout" />
              </ShowMore>
            </TableFilterCheckboxGroup>
          </TableFilterAccordionItem>

          <TableClusterFilter />

          {/* Labels 
                    Note: currently does not work. Awaiting BE issue CNATIX-2217
                */}
          {/* <FeatureFlag>
                <TableFilterAccordionItem title={t('Common.Labels')}>
                  <TableFilterFilt erableMultiSelect
                    // TODO: prevent react-hook-form from being smart by turning
                    // labels[key] into an object in `filters`
                    // id="labels[key]"
                    id="labels"
                    placeholder={t('Workloads.K8sTable.Filters.Labels')}
                    isLoading={isLoadingLabelKeys}
                    items={labelKeys || []}
                  />
                </TableFilterAccordionItem>
              </FeatureFlag> */}
        </TableFilterAccordion>
      </div>
    </TableFilter>
  )
}
