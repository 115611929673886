import React from 'react'
import { useTranslation } from 'react-i18next'
import { ManagementOrg } from '@cloudnatix-types/dashboard'
import { TableExpandedPanel } from 'src/next/components/TableExpanded'
import {
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from 'src/next/components/Tabs'
import { OrgNamespacesTable } from './Namespaces/OrgNamespacesTable'
import { OrgOverviewTab } from './Overview/OrgOverviewTab'
import { OrgResourcesTable } from './Resources/OrgResourcesTable'

interface Props {
  org: ManagementOrg
}

export const OrgExpandedPanel = ({ org }: Props) => {
  const { t } = useTranslation()

  const id = org.uuid!

  return (
    <TableExpandedPanel>
      <Tabs type="container">
        <TabList>
          <Tab id={`organization-${id}-panel-overview`}>
            {t('Common.Overview')}
          </Tab>
          <Tab id={`organization-${id}-panel-namespaces`}>
            {t('Organizations.Namespaces.Title')}
          </Tab>
          <Tab id={`organization-${id}-panel-resources`}>
            {t('Organizations.Resources', 'Resources')}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <OrgOverviewTab org={org} />
          </TabPanel>
          <TabPanel>
            <OrgNamespacesTable id={id} />
          </TabPanel>
          <TabPanel>
            <OrgResourcesTable id={id} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </TableExpandedPanel>
  )
}
