/* eslint-disable react/display-name */
import React from 'react'
import { Close } from '@carbon/react/icons'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import clsx from 'clsx'
import partition from 'lodash/partition'
import { Box } from 'src/next/components/Box'
import * as Styled from 'src/next/components/Modal/Modal.styled'
import Typography from 'src/next/components/Typography'

export interface ModalContentProps extends DialogPrimitive.DialogContentProps {
  children: React.ReactNode | React.ReactNode[]
  title?: string
  label?: string
  open?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg'
}

interface ModalFooterProps {
  children: React.ReactChild | React.ReactChild[]
  isButtonSet?: boolean
}

export const ModalFooter = ({ children, isButtonSet }: ModalFooterProps) => {
  return (
    <div className={`cds--modal-footer ${isButtonSet ? 'cds--btn-set' : ''}`}>
      {children}
    </div>
  )
}
ModalFooter.displayName = 'ModalFooter'

export const ModalContent = React.forwardRef<HTMLDivElement, ModalContentProps>(
  (
    {
      children: childrenProp,
      title,
      label,
      size = 'sm',
      className,
    }: ModalContentProps,
    forwardedRef,
  ) => {
    // loop over children and filter out <ModalFooter-component
    const [modalFooterChildren, children] = partition(
      React.Children.toArray(childrenProp),
      child => {
        if (!React.isValidElement(child) || typeof child?.type === 'string')
          return false

        return (child.type as any)?.displayName === ModalFooter.displayName
      },
    )

    return (
      <DialogPrimitive.Portal>
        <Styled.AnimatedOverlay className="cds--modal is-visible">
          <Styled.AnimatedContent
            ref={forwardedRef}
            $size={size}
            className={clsx(
              'cds--modal-container',
              size && `cds--modal-container--${size}`,
              className,
            )}
          >
            <Box px="5" pr="9" pt="5" mb="3">
              {label && (
                <Typography variant="label" as="div">
                  <Box id="radix-2" color="text-secondary" mb="2">
                    {label}
                  </Box>
                </Typography>
              )}
              {title && (
                <DialogPrimitive.Title>
                  <Typography variant="productive-heading-03">
                    {title}
                  </Typography>
                </DialogPrimitive.Title>
              )}
            </Box>
            {children.length ? (
              <Box overflowY="auto" mb="9">
                <Box px="5" pt="3">
                  {children}
                </Box>
              </Box>
            ) : null}

            {modalFooterChildren.length ? (
              <div>{modalFooterChildren}</div>
            ) : null}
            <DialogPrimitive.Close asChild>
              <button className="cds--modal-close">
                <Close className="cds--modal-close__icon" size={24} />
              </button>
            </DialogPrimitive.Close>
          </Styled.AnimatedContent>
        </Styled.AnimatedOverlay>
      </DialogPrimitive.Portal>
    )
  },
)

export const Modal = DialogPrimitive.Root
export const ModalTrigger = DialogPrimitive.Trigger
