import { lazy, Suspense, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDownloadWorkloads } from 'src/api'
import DataTable, {
  DataTablePagination,
  useTableControls,
} from 'src/next/components/DataTable'
import { DownloadButton } from 'src/next/components/DownloadButton'
import { ResetFiltersLink } from 'src/next/components/InlineNotification'
import Loading from 'src/next/components/Loading'
import {
  TableFilterToolbarActions,
  useTableFilter,
} from 'src/next/components/TableFilter'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { useWorkloads } from 'src/next/pages/workloads/useWorkloads'
import { Workload } from 'src/next/types/workloads'
import {
  WorkloadAge,
  WorkloadCluster,
  WorkloadCost,
  WorkloadMode,
  WorkloadName,
  WorkloadNotifications,
  WorkloadResources,
  WorkloadStatus,
  WorkloadType,
} from './components'
import { useBatchActions } from './useBatchActions'

const KubernetesTableExpandedPanel = lazy(
  () =>
    import(
      'src/next/containers/KubernetesTableExpandedPanel/KubernetesTableExpandedPanel'
    ),
)
const BatchActions = lazy(() => import('./BatchActions'))

function formatWorkload(workload: Workload, hideTooltips: boolean) {
  return {
    id: workload.uid,
    name: <WorkloadName name={workload.name!} id={workload.uid} />,
    type: <WorkloadType type={workload.type} />,
    mode: (
      <WorkloadMode
        status={workload?.recommendationStatus}
        recommendationConfig={workload?.recommendationConfig}
      />
    ),
    cluster: workload?.cluster?.name ? (
      <WorkloadCluster
        cluster={workload.cluster.name}
        csp={workload.cluster.csp}
      />
    ) : null,
    status: (
      <WorkloadStatus
        status={workload.status}
        isDeleted={workload.deletedAt !== '0'}
        hideTooltips={hideTooltips}
      />
    ),
    age: <WorkloadAge workload={workload} hideTooltips={hideTooltips} />,
    resources: workload.resources ? (
      <WorkloadResources
        resources={workload.resources}
        hideTooltips={hideTooltips}
      />
    ) : null,
    cost: <WorkloadCost costPerHour={Number(workload?.costPerHour)} />,
    namespace: workload.namespace,
  }
}

export const KubernetesTable = (): JSX.Element => {
  const { t } = useTranslation()

  const headers = useMemo(
    () => [
      {
        key: 'name',
        header: t('WorkloadTable.Header.name', 'Name'),
        sort: 'name',
      },
      {
        key: 'type',
        header: t('WorkloadTable.Header.type', 'Type'),
        sort: 'kind',
      },
      {
        key: 'cluster',
        header: t('WorkloadTable.Header.cluster', 'Cluster'),
      },
      {
        key: 'namespace',
        header: t('WorkloadTable.Header.namespace', 'Namespace'),
      },
      {
        key: 'status',
        header: t('WorkloadTable.Header.status', 'Status'),
      },
      {
        key: 'age',
        header: t('WorkloadTable.Header.age', 'Age'),
        sort: 'age',
      },
      {
        key: 'resources',
        header: t('WorkloadTable.Header.resources', 'Resources'),
      },
      {
        key: 'cost',
        header: t('WorkloadTable.Header.cost', 'Cost'),
        sort: 'cost',
      },
      {
        key: 'mode',
        header: t('WorkloadTable.Header.mode', 'Mode'),
      },
    ],
    [t],
  )

  const { pagination, orderBy } = useTableControls('kubernetes-table-page-size')

  const { setPage, resetPage, ...dataTablePaginationProps } = pagination

  const [hideTooltips, setHideTooltips] = useLocalStorage(
    'kubernetes-table-hide-tooltips',
    false,
  )

  const { filters, methods, activeFiltersCount, reset } = useTableFilter()

  // reset pagination when filters change
  methods.watch(() => resetPage())

  const queryParams = {
    filter: filters,
    pageSize: pagination.pageSize,
    orderBy: orderBy.value,
  }
  const query = useWorkloads(queryParams)
  const { mutate: downloadWorkloads, isLoading: downloadIsLoading } =
    useDownloadWorkloads(queryParams)

  const currentPageData = query.data?.pages?.[pagination.page - 1] || {}

  const workloads = useMemo(
    () => currentPageData.workloads || [],
    [currentPageData.workloads],
  )

  const { batchActions, markedWorkloads, showModal, setShowModal } =
    useBatchActions(workloads)

  const rows = useMemo(
    () => workloads.map(workload => formatWorkload(workload, hideTooltips)),
    [hideTooltips, workloads],
  )

  const getRowData = useCallback(
    (id: string) => {
      const rowData = workloads?.find(workload => workload.uid === id)
      const { deletedAt, type, recommendationStatus } = rowData || {}
      return { deletedAt, type, recommendationStatus }
    },
    [workloads],
  )

  return (
    <div>
      <DataTable
        storageKey="kubernetes-table"
        headers={headers}
        rows={rows}
        // Pass `isFetching` which becomes `false` while data is refetched after
        // invalidation, not `isLoading` which doesn't change. See the comment
        // on the logic in `WorkloadRecommendations` where the query is
        // invalidated for the details why invalidation is used.
        isLoading={query.isFetching}
        pageSize={pagination.pageSize}
        size="xl"
        settings={[
          {
            key: 'toggle-tooltips',
            label: t('Workloads.hide_tooltips', 'Hide tooltips'),
            checked: hideTooltips,
            onChange: () => setHideTooltips(!hideTooltips),
          },
        ]}
        onExpand={id => {
          const rowData = getRowData(id)
          return (
            <Suspense fallback={<Loading withOverlay={false} size="small" />}>
              <KubernetesTableExpandedPanel
                workloadId={id}
                type={rowData.type}
                deletedAt={rowData.deletedAt}
                recommendationStatus={rowData.recommendationStatus}
              />
            </Suspense>
          )
        }}
        orderBy={orderBy}
        setPage={setPage}
        toolbar={
          <>
            <DownloadButton
              fileTypes={['CSV', { name: 'Excel', extension: 'xlsx' }]}
              download={downloadWorkloads}
              isLoading={downloadIsLoading}
              fileBase="workloads"
            />
            <TableFilterToolbarActions />
          </>
        }
        batchActions={batchActions}
      />
      <Suspense fallback="Loading...">
        <BatchActions
          markedWorkloads={markedWorkloads}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </Suspense>
      <WorkloadNotifications
        hasItems={Boolean(workloads.length)}
        isError={query.isError}
        loading={query.isFetching}
      >
        <ResetFiltersLink
          activeFiltersCount={activeFiltersCount}
          reset={reset}
        />
      </WorkloadNotifications>
      <DataTablePagination {...dataTablePaginationProps} query={query} />
    </div>
  )
}
