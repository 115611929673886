import React, { useState } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { VictoryVoronoiContainer } from 'victory'
import { Box, GraphDataPoint } from 'src/next/components'
import { TooltipContent } from 'src/next/components/Graphs/GraphTooltip/TooltipContent'
import { Sparkline } from 'src/next/components/Graphs/Sparkline'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'
import { TimeRangeOption } from '../../timerange/TimerangeTypes'

const SparklineWrapper = styled.div`
  height: 50px;
  margin-bottom: var(--cds-spacing-05);
`

const TooltipContainer = styled.div<{ top: number }>`
  position: absolute;
  left: 0;
  right: 0;
  top: ${props => props.top}px;
  display: flex;
  justify-content: center;
`

interface SparklineContainerProps {
  id: string
  title: string
  isLoading?: boolean
  isError?: boolean
  graphColor: string
  data?: GraphDataPoint[]
  y?: string | number
  formatValue: (value: number) => string
  tooltipPosition?: 'top' | 'bottom'
  tooltipUnit?: string
  timeRange?: TimeRangeOption
}

const getTooltipHeader = (
  startTimeNs?: number,
  timeRange?: TimeRangeOption,
) => {
  if (!startTimeNs || !timeRange) {
    return null
  }

  const startDate = dayjs(nanoToMilliSeconds(startTimeNs))

  // For the 6 months time range, we show start and end date in the tooltip header
  if (timeRange.id === '6months') {
    // Add 6 days but not dates in the future
    const endDate = dayjs.min(startDate.add(6, 'days'), dayjs())
    return `${startDate.format('LL')} - ${endDate.format('LL')}`
  }

  // Just show the date for week and month time ranges
  return startDate.format('LL')
}

export const SparklineContainer = ({
  graphColor,
  title,
  data,
  isLoading,
  y,
  formatValue,
  tooltipPosition = 'bottom',
  tooltipUnit,
  timeRange,
}: SparklineContainerProps) => {
  const [activePoint, setActivePoint] = useState<any>(null)

  const tooltipHeader = getTooltipHeader(activePoint?.timestampNs, timeRange)

  const handleTooltip = (point: any) => {
    setActivePoint(point[0])
  }

  return (
    <Box position="relative">
      <SparklineWrapper>
        <Sparkline
          isLoading={isLoading}
          y={y}
          color={graphColor}
          data={data}
          containerComponent={
            <VictoryVoronoiContainer
              voronoiDimension="x"
              onActivated={handleTooltip}
            />
          }
          events={[
            {
              target: 'parent',
              eventHandlers: {
                onMouseLeave: () => {
                  setActivePoint(false)
                },
              },
            },
          ]}
        />
      </SparklineWrapper>
      <TooltipContainer top={tooltipPosition === 'top' ? -70 : 70}>
        {activePoint ? (
          <TooltipContent
            header={tooltipHeader}
            data={[
              {
                label: title,
                value: `${formatValue(y ? activePoint[y] : activePoint.y)}${
                  tooltipUnit ?? ''
                }`,
                color: graphColor || undefined,
              },
            ]}
          />
        ) : null}
      </TooltipContainer>
    </Box>
  )
}
