import React, { useEffect, useMemo, useState } from 'react'
import { Dropdown } from '@carbon/react'
import { orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import FilterableSelect from 'src/next/components/FilterableSelect/FilterableSelect'
import { useInstanceTagKeysQuery } from './useInstanceTagKeysQuery'

export const useTagKey = () => {
  const { data: instanceTagKeys } = useInstanceTagKeysQuery()

  const [tagKey, setTagKey] = useState<string | undefined>(undefined)
  // sort the items, since the order of items being returned is not stable
  const sortedItems = useMemo(
    () => orderBy(instanceTagKeys?.keys, item => item.toLowerCase()),
    [instanceTagKeys],
  )

  useEffect(() => {
    if (sortedItems.length) setTagKey(sortedItems[0])
  }, [sortedItems])

  return {
    tagKeyProps: {
      id: 'instance-tag-keys',
      selectedItem: tagKey,
      handleChange: setTagKey,
      items: sortedItems || [],
    },
    TabDropdown,
  }
}

interface TabDropdownProps {
  id: string
  items: {
    name: string
    uid: string
  }[]
  selectedItem: any
  handleChange: (data: { name: string; uid: string }) => void
  disabled?: boolean
}
interface TabFilterableSelectProps {
  id: string
  items: string[]
  handleChange: (value?: string | null) => void
  selectedItem: string
}

// only works with strings
export const TabFilterableSelect = ({
  id,
  items,
  selectedItem,
  handleChange,
}: TabFilterableSelectProps) => {
  const { t } = useTranslation()
  return (
    <FilterableSelect
      id={id}
      items={items}
      placeholder={t('Dashboard.Tabs.SelectTagKey')}
      selectedItem={selectedItem}
      onChange={handleChange}
    />
  )
}

export const TabDropdown = ({
  id,
  items,
  selectedItem,
  disabled,
  handleChange,
}: TabDropdownProps) => (
  <Dropdown
    id={id}
    data-testid={id}
    disabled={disabled}
    label=""
    hideLabel
    items={items}
    itemToString={item => (typeof item === 'string' ? item : item.name)}
    selectedItem={selectedItem}
    onChange={({ selectedItem }) => handleChange(selectedItem)}
  />
)
