import dayjs from 'dayjs'

export const getTimePeriod = (timePeriod: string) => {
  const lastHour = dayjs().startOf('hour')

  const startTimeNs =
    timePeriod === 'daily'
      ? (lastHour.subtract(7, 'days').valueOf() * 1_000_000).toString()
      : timePeriod === 'weekly'
      ? (lastHour.subtract(7, 'weeks').valueOf() * 1_000_000).toString()
      : timePeriod === 'monthly'
      ? (lastHour.subtract(7, 'months').valueOf() * 1_000_000).toString()
      : (lastHour.subtract(7, 'days').valueOf() * 1_000_000).toString()

  const endTimeNs = (lastHour.valueOf() * 1_000_000).toString()

  return { startTimeNs, endTimeNs }
}
