import { useEffect, useMemo, useState } from 'react'
import { Dropdown } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { ComputeInstanceGroupingKeyKey } from '@cloudnatix-types/dashboard'
import { useDashboardInstanceGroups } from 'src/api'
import { Box, Flex } from 'src/next/components'
import { LoadingGraph } from 'src/next/components/Graphs/LoadingGraph'
import { InlineNotification } from 'src/next/components/InlineNotification'
import { CollapseComponent } from 'src/next/components/ui'
import { useTimeRangeOverflowMenu } from '../timerange'
import { TabChart, TabGrouping } from './charts'
import { TabDropdown, TabFilterableSelect } from './hooks/useTagKey'
import { TabDataTable } from './tables'
import { useFormatRows, useHeaders } from './tables/useSummaryTable'

interface GenericTabProps {
  group: `${ComputeInstanceGroupingKeyKey}`
  tagKeyProps?: any
  noLimit?: boolean
}

export const GenericTab = ({
  group,
  tagKeyProps,
  noLimit,
}: GenericTabProps) => {
  const { t } = useTranslation()

  const { TimeRangeMenu, items, selectedItem, startTimeNs, endTimeNs } =
    useTimeRangeOverflowMenu({
      id: `dashboard-tab-${group}`,
      show24HoursOption: true,
    })

  const [chartType, setChartType] = useState<'detailed' | 'summary'>('detailed')

  const chartTypes = [
    {
      label: t('Dashboard.Tabs.SelectDetailed'),
      id: 'detailed',
    },
    {
      label: t('Dashboard.Tabs.SelectSummary'),
      id: 'summary',
    },
  ] as const

  const { isLoading, data } = useDashboardInstanceGroups({
    groupingKey: {
      key: group as any,
      tagKey: tagKeyProps?.selectedItem,
    },
    filter: {
      startTimeNs,
      endTimeNs,
    },
  })

  const groupingValues = useMemo(() => {
    return data?.instanceGroups?.map(({ name, uid }) => ({
      name: name!,
      uid: uid!,
    }))
  }, [data])

  const [groupingValue, setGroupingValue] = useState(groupingValues?.[0])

  useEffect(() => {
    setGroupingValue(groupingValues?.[0])
  }, [groupingValues])

  const baseHeaders = useHeaders()

  const headers = tagKeyProps?.selectedItem
    ? baseHeaders.map(header => {
        return header.key === 'name'
          ? { key: 'name', header: tagKeyProps.selectedItem }
          : header
      })
    : baseHeaders

  const rows = useFormatRows(data?.instanceGroups)

  const numInstanceGroups = data?.instanceGroups?.length
  const hasData = numInstanceGroups !== undefined && numInstanceGroups > 0

  return (
    <div>
      {hasData && (
        <Flex justifyContent="space-between" flexWrap="wrap">
          <Flex flexWrap="wrap">
            {tagKeyProps && (
              <Box mr="5" mt={5} mb={3} minWidth="350px" maxWidth="400px">
                <TabFilterableSelect {...tagKeyProps} />
              </Box>
            )}
            {chartType === 'summary' && (
              <Box mr="auto" mt={5} mb={3} minWidth="250px" maxWidth="350px">
                <TabDropdown
                  id={`grouping-value-${group}`}
                  disabled={!hasData}
                  items={groupingValues || []}
                  selectedItem={groupingValue}
                  handleChange={(item: typeof groupingValue) => {
                    setGroupingValue(item)
                  }}
                />
              </Box>
            )}
          </Flex>
          <Box mt={5} mb={3} position="absolute" right="0" zIndex={1}>
            <Dropdown
              id="chart-type-selector"
              data-testid="chart-type-selector"
              label="Chart type selector"
              hideLabel
              items={chartTypes}
              selectedItem={chartTypes.find(({ id }) => id === chartType)}
              onChange={({ selectedItem }) => setChartType(selectedItem?.id!)}
            />
          </Box>
        </Flex>
      )}
      {!isLoading && !hasData ? (
        <InlineNotification title={t('Common.no_data_found')} />
      ) : null}

      {groupingValue && groupingValues?.length ? (
        <LoadingGraph isLoading={isLoading} hideSpinner>
          {hasData &&
            (chartType === 'summary' ? (
              <>
                <CollapseComponent>
                  <TabChart
                    groupingKey={group}
                    tagKey={tagKeyProps?.selectedItem}
                    groupingValue={groupingValue?.uid}
                  />
                </CollapseComponent>
                <CollapseComponent>
                  <TabDataTable
                    id={group}
                    headers={headers}
                    rows={rows}
                    isLoading={isLoading}
                    overflowMenu={
                      <TimeRangeMenu label={selectedItem.label} items={items} />
                    }
                  />
                </CollapseComponent>
              </>
            ) : (
              <TabGrouping
                groupingKey={group}
                tagKey={tagKeyProps?.selectedItem}
                noLimit={noLimit}
              />
            ))}
        </LoadingGraph>
      ) : null}
    </div>
  )
}
