import { QueryObserverOptions, useQuery } from 'react-query'
import {
  ListClusterMetricsRequest,
  ListClusterMetricsResponse,
} from '@cloudnatix-types/dashboard'
import { fetchData, useRequestParams } from 'src/api'

const url = 'v1/dashboard/clustermetrics'

export const useClusterMetricsQuery = <T = ListClusterMetricsResponse>(
  request: ListClusterMetricsRequest,
  options?: QueryObserverOptions<ListClusterMetricsResponse, any, T>,
) => {
  const params = useRequestParams({ request, addOrgFilter: true })

  return useQuery<ListClusterMetricsResponse, any, T>(
    [url, params],
    () => fetchData<ListClusterMetricsResponse>(url, params),
    {
      staleTime: 60 * 60 * 1000,
      ...options,
    },
  )
}
