import { useTranslation } from 'react-i18next'
import {
  TableFilter,
  TableFilterAccordionItem,
  TableFilterActiveItems,
} from 'src/next/components/TableFilter'
import { TableRegionFilter } from 'src/next/components/Filters/TableRegionFilter'
import { TableCSPFilter } from '../../Filters/TableCSPFilter'
import { TableClusterNameDropdownFilter } from './TableClusterNameDropdownFilter'

export const ClustersTableFilters = () => {
  const { t } = useTranslation()

  return (
    <TableFilter>
      <TableFilterActiveItems />
      <TableCSPFilter />
      <TableRegionFilter />

      {/* Names */}
      <TableFilterAccordionItem title={t('Clusters.Filters.Name')}>
        <TableClusterNameDropdownFilter />
      </TableFilterAccordionItem>
    </TableFilter>
  )
}
