import { QueryObserverOptions, UseQueryResult, useQuery } from 'react-query'
import {
  CLUIWorkload,
  ListCLUIWorkloadsResponse,
} from '@cloudnatix-types/dashboard'
import { fetchAllPages } from 'src/api'

const url = '/v1/dashboard/cluiworkloads'

export const useCLUIWorkloads = (
  options?: QueryObserverOptions<CLUIWorkload[]>,
): UseQueryResult<CLUIWorkload[]> => {
  return useQuery<CLUIWorkload[]>(
    [url],
    () =>
      fetchAllPages<CLUIWorkload, ListCLUIWorkloadsResponse>(url, 'workloads'),
    {
      ...options,
    },
  )
}
