import React from 'react'
import {
  Loading as CarbonLoading,
  LoadingProps as CarbonLoadingProps,
} from '@carbon/react'
import styled from 'styled-components'

type SizeKey = 'small' | 'medium' | 'large'
export interface LoadingProps extends CarbonLoadingProps {
  size?: SizeKey
  centered?: boolean
}

// Carbon's default size is 5.5rem
const defaultSize = 5.5

const sizeMap: Record<SizeKey, string> = {
  small: `${defaultSize * 0.25}rem`,
  medium: `${defaultSize * 0.5}rem`,
  large: `${defaultSize}rem`,
}

const Wrapper = styled.div<{ $centered?: boolean }>`
  ${({ $centered }) =>
    $centered
      ? `
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      `
      : ''};
`

const StyledLoading = styled(CarbonLoading)<{ $size: SizeKey }>`
  ${({ $size }) => `
    height: ${sizeMap[$size]};
    width: ${sizeMap[$size]};
  `};
`

const Loading = ({
  size = 'medium',
  centered = false,
  children,
  ...props
}: LoadingProps) => {
  return (
    <Wrapper $centered={centered}>
      <StyledLoading $size={size} {...props} />
    </Wrapper>
  )
}

export default Loading
