/* eslint-disable react/no-unknown-property */
import React, { useMemo } from 'react'
import { Button } from '@carbon/react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useConversionRules, useDeleteRule } from 'src/api'
import DataTable, {
  DataTableActionButton,
  useTableControls,
  DataTablePagination,
} from 'src/next/components/DataTable'
import { FeatureInfo } from 'src/next/components/FeatureInfo'
import { InlineNotification } from 'src/next/components/InlineNotification'
import {
  ResourceAllocateModal,
  useResourceAllocateModal,
} from 'src/next/components/management/Resources/ResourcesAllocateModal'
import { DeleteModal, useDeleteModal } from 'src/next/components/Modal'
import { WorkloadLabels } from 'src/next/components/Workloads/WorkloadsTable/components'
import { Link } from 'src/next/components'
import { Flex } from '../../Flex'
import { Tooltip } from 'src/next/components/ui'
import {
  TableFilterToolbarActions,
  useTableFilter,
} from 'src/next/components/TableFilter'

export const RulesTable = () => {
  const { t } = useTranslation()

  const allocateModalProps = useResourceAllocateModal()
  const deleteModalProps = useDeleteModal()

  const headers = useHeaders()

  const { pagination, orderBy } = useTableControls('mgmt-rules-page-size')

  const { resetPage, setPage, ...dataTablePaginationProps } = pagination

  const { filters, methods } = useTableFilter()

  methods.watch(() => resetPage())

  const query = useConversionRules({
    orderBy: orderBy.value || '',
    pageSize: pagination.pageSize,
    filter: filters,
  })

  const currentPageData = query.data?.pages?.[pagination.page - 1] || {}

  const rules = useMemo(
    () => currentPageData.rules || [],
    [currentPageData.rules],
  )

  const formattedRows = useMemo(
    () =>
      rules?.map(rule => {
        return {
          ...rule,
          id: rule.uuid!,
          name: (
            <Link to={`/app/administration/${rule.action!.orgUuid}`}>
              {rule.name}
            </Link>
          ),
          org: (
            <Tooltip
              direction="top"
              tooltipText={`${rule.action!.orgUuid}`}
              onClick={() => {
                navigator.clipboard.writeText(`${rule.action!.orgUuid}`)
              }}
              afterClickText={t('CopyToClipboard.Copied')}
            >
              {rule.targetOrgName!}
            </Tooltip>
          ),
          createdAt: dayjs.unix(+rule.createdAt!).format('ll'),
          labels: rule.labelFilters?.length ? (
            <WorkloadLabels labels={rule.labelFilters} />
          ) : (
            ''
          ),
          actions: (
            <>
              <DataTableActionButton
                iconDescription={t('Rules.Edit')}
                onClick={() => {
                  allocateModalProps.setRule(rule)
                  allocateModalProps.setOpen(true)
                }}
              />
              <DataTableActionButton
                actionType="delete"
                iconDescription={t('Rules.Remove')}
                onClick={() => deleteModalProps.setId(rule.uuid!)}
              />
            </>
          ),
        }
      }) || [],
    [rules, deleteModalProps, allocateModalProps, t],
  )

  return (
    <div>
      <DataTable
        storageKey="admin-rules-table"
        headers={headers}
        rows={formattedRows}
        isLoading={query.isFetching}
        size="xl"
        pageSize={pagination.pageSize}
        orderBy={orderBy}
        {...{
          primaryAction: (
            <Button
              onClick={() => {
                allocateModalProps.setRule(null)
                allocateModalProps.setOpen(true)
              }}
            >
              {t('Resources.Allocation.CreateRule')}
            </Button>
          ),
        }}
        toolbar={
          <>
            <Flex width={1}>
              <FeatureInfo
                definition={<div>{t('Rules.FeatureInfo')}</div>}
                align="bottom-left"
              />
            </Flex>
            <TableFilterToolbarActions />
          </>
        }
      />
      {(!query.isFetching && !formattedRows?.length) || query.isError ? (
        <InlineNotification title={t('Rules.NoData')} />
      ) : (
        <DataTablePagination {...dataTablePaginationProps} query={query} />
      )}
      <DeleteModal
        title={t('Rules.ConfirmDelete')}
        messages={{
          success: t('Rules.Delete.Success'),
          error: t('Rules.Delete.Error'),
        }}
        query={useDeleteRule}
        {...deleteModalProps}
      />
      {allocateModalProps.open ? (
        <ResourceAllocateModal {...allocateModalProps} />
      ) : null}
    </div>
  )
}

const useHeaders = () => {
  const { t } = useTranslation()

  return useMemo(
    () => [
      { key: 'name', sort: 'name', header: t('Organizations.DataTable.Name') },
      { key: 'org', header: t('Dashboard.Tabs.Organization') },
      { key: 'createdAt', sort: 'created_at', header: t('Workload.CreatedAt') },
      // { key: 'deletedAt', header: t('Workload.DeletedAt') },
      { key: 'labels', header: t('Organizations.Labels') },
      { key: 'actions', header: t('Organizations.DataTable.Actions') },
    ],
    [t],
  )
}
