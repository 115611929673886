import { useQuery, UseQueryOptions } from 'react-query'
import { GetOptimizationPlansTotalSavingResponse as ResponseType } from '@cloudnatix-types/dashboard'
import { fetchData, useRequestParams } from 'src/api'

export const useOptimizationTotalSaving = (
  options?: UseQueryOptions<ResponseType>,
) => {
  const params = useRequestParams({
    request: { filter: { kind: 'auto' } },
    addOrgFilter: true,
  })
  const url = 'v1/insights/optimizations/totalsaving'

  return useQuery<ResponseType>(
    [url, params],
    () => fetchData<ResponseType>(url, params),
    {
      cacheTime: 3600000,
      staleTime: 3600000,
      ...options,
    },
  )
}
