import React from 'react'
import dayjs from 'dayjs'
import { round } from 'lodash'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { VictoryLabel } from 'victory'
import { ComputeInstanceGroupingKeyKey } from '@cloudnatix-types/dashboard'
import {
  getFilterGroupingDuration,
  useInstanceSummariesAggregate,
} from 'src/api'
import i18n from 'src/App/i18n'
import { GraphColor } from 'src/next/types/workloads'
import { Legend, LegendItem } from '../../../Graphs'
import { DonutChart } from '../../../Graphs/DonutChart'
import { useTimeRangeOverflowMenu } from '../../timerange'
import { TabChartHeading } from './TabChartHeading'

const DonutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--cds-spacing-04);
  height: 500px;
`

export interface TabDonutChartProps {
  groupingKey: `${ComputeInstanceGroupingKeyKey}`
  groupingValue?: string | null
  tagKey?: string | null
}

export const TabDonutChart = ({
  groupingKey,
  groupingValue,
  tagKey,
}: TabDonutChartProps) => {
  const { t } = useTranslation()

  const {
    TimeRangeMenu,
    items,
    selectedItem,
    startTime,
    endTime,
    startTimeNs,
    endTimeNs,
  } = useTimeRangeOverflowMenu({
    id: `dashboard-${groupingKey}-tab-donut-chart-timerange`,
    show24HoursOption: false,
  })

  const { data, isLoading } = useInstanceSummariesAggregate(
    {
      filter: {
        startTimeNs,
        endTimeNs,
        grouping: {
          groupingKey: {
            key: groupingKey as any,
            tagKey: tagKey as any,
          },
          // todo: fix type
          groupingValue: groupingValue as any,
          // todo: hardcoded for now duration needs to be selected
          duration: getFilterGroupingDuration(selectedItem.id) as any,
        },
      },
    },
    {
      useErrorBoundary: true,
      select: ({ aggregatedSummary }) => aggregatedSummary,
    },
  )

  const periodStart = dayjs(startTime)
    .locale(i18n.resolvedLanguage)
    .format('LLL')
  const periodEnd = dayjs(endTime).locale(i18n.resolvedLanguage).format('LLL')

  const efficiency = round(data?.efficiency! * 100, 2)
  const waste = round(data?.waste! * 100, 2)

  const donutChartData = [
    {
      x: 0,
      y: efficiency,
      value: `${efficiency}%`,
      label: t('Dashboard.Tabs.Charts.Efficiency'),
      color: 'var(--carbonPalette1)' as GraphColor,
    },
    {
      x: 1,
      y: waste,
      value: `${waste}%`,
      label: t('Common.Waste'),
      color: 'var(--carbonPalette2)' as GraphColor,
    },
  ]

  return (
    <>
      <TabChartHeading
        title={t('Dashboard.Common.ResourceEfficiency')}
        overflowMenu={
          <TimeRangeMenu label={selectedItem.label} items={items} />
        }
      />

      <DonutWrapper>
        <DonutChart
          isLoading={isLoading}
          data={donutChartData}
          tooltipHeading={
            <>
              {periodStart} –<br />
              {periodEnd}
            </>
          }
          labelComponent={<VictoryLabel text={({ datum }) => datum.value} />}
          centerHeading={donutChartData[0].label}
          centerValue={donutChartData[0].value}
        />
        <Legend>
          {donutChartData?.map(({ label, color }) => (
            <LegendItem id={label} key={label} color={color}>
              {label}
            </LegendItem>
          ))}
        </Legend>
      </DonutWrapper>
    </>
  )
}
