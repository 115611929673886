import { Column, Grid } from '@carbon/react'
import { ComputeInstanceGroupingKeyKey } from '@cloudnatix-types/dashboard'
import { Box, ErrorBoundary } from 'src/next/components'
import { TabDonutChart } from './TabDonutChart'
import { TabLineChart } from './TabLineChart'

export interface TabChartProps {
  groupingKey: `${ComputeInstanceGroupingKeyKey}`
  groupingValue?: string | null
  tagKey?: string | null
}

export const TabChart = ({
  groupingKey,
  groupingValue,
  tagKey = undefined,
}: TabChartProps) => {
  return (
    <Box
      mt="var(--cds-spacing-05)"
      mb="var(--cds-spacing-05)"
      minHeight="580px"
    >
      <Grid fullWidth narrow>
        <Column sm={4} md={8}>
          <ErrorBoundary resetKeys={[groupingKey, groupingValue, tagKey]}>
            <TabLineChart
              groupingKey={groupingKey}
              groupingValue={groupingValue}
              tagKey={tagKey}
            />
          </ErrorBoundary>
        </Column>
        <Column sm={4} md={8}>
          <ErrorBoundary resetKeys={[groupingKey, groupingValue, tagKey]}>
            <TabDonutChart
              groupingKey={groupingKey}
              groupingValue={groupingValue}
              tagKey={tagKey}
            />
          </ErrorBoundary>
        </Column>
      </Grid>
    </Box>
  )
}
