import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TabSummariesChartSettingMetric,
  TimePeriod,
} from 'src/next/containers/WorkloadCharts/types'

export const useLimitOptions = (): (Record<'id', string> & {
  label: string
})[] => {
  const { t } = useTranslation()
  return useMemo(() => {
    return [
      ...[5, 10, 25].map(value => ({
        id: `${value}`,
        label: t('Common.TopX', {
          value,
        }),
      })),
      {
        // 500 as a sane 'all' default? to prevent rendering / ux problems
        id: '500',
        label: t('Common.ShowAll'),
      },
    ]
  }, [t])
}

export const useComputeInstanceSummariesMetricsDropdownItems = (): (Record<
  'id',
  TabSummariesChartSettingMetric
> & {
  label: string
})[] => {
  const { t } = useTranslation()

  return [
    { id: 'TOTAL_SPEND', label: t('Resources.TotalSpend') },
    { id: 'WASTED_SPEND', label: t('Resources.WastedSpend') },
    { id: 'AVG_CPU_USAGE', label: t('Resources.AvgCpuUsage') },
    { id: 'MAX_CPU_USAGE', label: t('Resources.MaxCpuUsage') },
    { id: 'CI_AVG_CPU_CAPACITY', label: t('Resources.AvgCpuCapacity') },
    { id: 'CI_MAX_CPU_CAPACITY', label: t('Resources.MaxCpuCapacity') },
    { id: 'AVG_MEMORY_USAGE', label: t('Resources.AvgMemoryUsage') },
    { id: 'MAX_MEMORY_USAGE', label: t('Resources.MaxMemoryUsage') },
    { id: 'CI_AVG_MEMORY_CAPACITY', label: t('Resources.AvgMemoryCapacity') },
    { id: 'CI_MAX_MEMORY_CAPACITY', label: t('Resources.MaxMemoryCapacity') },
    { id: 'CI_AVG_DISK_CAPACITY', label: t('Resources.CiAvgDiskCapacity') },
    { id: 'CI_MAX_DISK_CAPACITY', label: t('Resources.CiMaxDiskCapacity') },
  ]
}

export const useTimeRangeOptions = (): (Record<'id', TimePeriod> & {
  label: string
})[] => {
  const { t } = useTranslation()
  return useMemo(
    () => [
      {
        id: 'daily',
        label: t('Workloads.TopCharts.Filters.DailyRollup'),
      },
      {
        id: 'weekly',
        label: t('Workloads.TopCharts.Filters.WeeklyRollup'),
      },
      {
        id: 'monthly',
        label: t('Workloads.TopCharts.Filters.MonthlyRollup'),
      },
    ],
    [t],
  )
}
