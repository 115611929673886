import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ResourceEfficiency } from '@cloudnatix-types/dashboard'
import { SimpleStackedBar } from 'src/next/components/SimpleStackedBar'

const Waste = styled.div`
  margin-bottom: var(--cds-spacing-02);
`

const Efficiency = styled.div`
  max-width: 120px;
`

function getSimpleBarData(efficiency: ResourceEfficiency) {
  return [
    {
      label: `${efficiency?.name}`,
      value: Number(efficiency?.utilization),
    },
    {
      label: '', // Waste
      value: 1 - Number(efficiency?.utilization),
    },
  ]
}

interface EfficiencyCellProps {
  efficiency: ResourceEfficiency
}

export const EfficiencyCell = ({ efficiency }: EfficiencyCellProps) => {
  const { t } = useTranslation()
  const title = t('Resources.AvgWaste')

  if (!efficiency) return <div>---</div>

  const utilization = Number(efficiency?.utilization)
  const waste = `${((1 - utilization) * 100).toFixed(0)}% ${title}`

  return (
    <Efficiency>
      <Waste>{waste}</Waste>
      <SimpleStackedBar data={getSimpleBarData(efficiency)} />
    </Efficiency>
  )
}
