/* eslint-disable react/jsx-key */
/* eslint-disable react/display-name */
import React, { memo } from 'react'
import merge from 'lodash/merge'
import styled from 'styled-components'
import {
  VictoryArea,
  VictoryAxis,
  VictoryAxisProps,
  VictoryBar,
  VictoryChart,
  VictoryChartProps,
  VictoryContainer,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryStack,
} from 'victory'
import PodRestartIcon from 'src/images/auto-pilot.svg'
import BoundingSize from 'src/next/components/BoundingSize'
import { useGraphBoundaries } from 'src/next/components/Graphs/Graph/useGraphBoundaries'
import { createFormatFn, MetricFormatType } from 'src/next/utils/graph.utils'
import theme from '../../../themes/victory-carbon-theme'
import { GraphConfig } from '../index'

// To make the graph responsive we need this "parent container" that has a fixed
// height. Otherwise, the height of the graph will continue to grow
const FlexibleContainer = styled.div<{ height: number }>`
  position: relative;
  width: 100%;
  height: ${({ height }) => height}px;
`

export const defaultPadding = { top: 10, left: 70, right: 10, bottom: 50 }
const domainPadding = { x: 0, y: 10 }
const colorScale: [] = []

const iconWidth = 35
const iconHeight = 16

const GraphIcon = ({ x, y }: any) => {
  // TODO: icon and offset now hardcoded for the 'event' type. This should be
  //  more more generic when lines without icons are needed or with a different
  //  icon.
  return (
    <image
      x={x - iconWidth / 2 - 1} // -1 to let icon overlap the red line completely
      y={y - iconHeight - 4}
      href={PodRestartIcon}
    />
  )
}

export interface GraphProps extends VictoryChartProps {
  data: GraphConfig[]
  unit: MetricFormatType
  width?: number
  height?: number
  xAxis?: VictoryAxisProps[]
  xAxisLabelSpace?: number
  yAxis?: VictoryAxisProps[]
  containerComponent?: React.ReactElement
  stack?: boolean
  scatter?: boolean
  normalizeScale?: boolean
}

export const Graph = memo(
  ({
    data: graphs,
    unit,
    width,
    height,
    xAxis,
    xAxisLabelSpace = 70, // 70px for e.g. 18:00
    yAxis,
    containerComponent,
    stack,
    scatter,
    normalizeScale = true,
    ...rest
  }: GraphProps) => {
    const { scale, ...restProps } = { ...rest }
    const graphBoundaries = useGraphBoundaries(
      graphs
        .filter(graph => graph.enabled ?? true)
        .map(graph => {
          switch (graph.type) {
            case 'area':
            case 'bar':
            case 'line':
              return graph.data
            case 'setting':
              // For now, y of setting graphs are not taken into account.
              return []
            case 'event':
            case 'state':
            default:
              return []
          }
        }),
      stack,
    )
    const maxY = graphBoundaries.maxY!

    const dataLength = graphs.map(graph => {
      const t = graph.type
      switch (t) {
        case 'area':
        case 'bar':
        case 'line':
          return graph.data?.length || 0
        case 'setting':
          return 0
        case 'event':
        case 'state':
          return graph.data?.length || 0
        default:
          return 0
      }
    })
    // Ideally, this value should be calculated based on the number of data
    // points in the zoomed range, not the whole range.
    const totalPointsX = Math.max(0, ...dataLength)

    if (!totalPointsX) {
      return null
    }

    const renderGraph = (graphData: GraphConfig) => {
      if (typeof graphData.enabled === 'boolean' && !graphData.enabled) {
        return null
      }

      /**
       * We transform the Y axis values and axis to a normalized scale between
       * 0 and 1. This makes it easier to draw graphs that have a different
       * scale then the main metric (cores or bytes).
       *
       * E.g. events are a bar chart drawn from top to bottom. By using the
       * normalized scale we can simply provide 1 as Y value.
       */

      const { id } = graphData

      switch (graphData.type) {
        case 'area': {
          const { data, props } = graphData
          return data.length ? (
            <VictoryArea
              name={id}
              key={id}
              data={data}
              y0={normalizeScale ? datum => (datum.y0 || 0) / maxY : undefined}
              y={normalizeScale ? datum => datum.y / maxY : undefined}
              {...props}
            />
          ) : null
        }

        case 'line': {
          const { data, props } = graphData
          return data.length ? (
            <VictoryLine
              name={id}
              key={id}
              data={data}
              y={normalizeScale ? datum => datum.y / maxY : undefined}
              {...props}
            />
          ) : null
        }

        case 'setting': {
          const { value, props } = graphData
          return (
            <VictoryLine
              name={id}
              key={id}
              data={[
                // Not adjustied to the zoomed X-axis range as the rendered
                // result has no problem (gets clopped for the overflowed
                // range).
                { x: graphBoundaries.minX, y: value },
                { x: graphBoundaries.maxX, y: value },
              ]}
              y={normalizeScale ? datum => datum.y / maxY : undefined}
              {...props}
            />
          )
        }

        case 'event': {
          if (!graphBoundaries.maxY) {
            return null
          }

          const { data, props } = graphData
          return data.map(event => (
            <VictoryGroup>
              <VictoryBar
                name={id}
                key={id}
                data={[
                  {
                    x: event.x,
                    y: 1,
                  },
                ]}
                {...merge(
                  {
                    style: { data: { width: 3 } },
                  },
                  props,
                )}
              />

              <VictoryScatter
                data={[{ x: event.x, y: 0 }]}
                size={7}
                dataComponent={<GraphIcon />}
              />
            </VictoryGroup>
          ))
        }

        case 'state': {
          const { data, props } = graphData
          return (
            <VictoryArea
              name={id}
              key={id}
              data={data.map(p => ({
                x: p.x,
                y: p.on ? 1 : 0,
              }))}
              {...props}
            />
          )
        }

        default:
          return null
      }
    }

    const formatFn = createFormatFn(unit, {
      cpu: { shortDisplayValue: true },
      currency: { minimumFractionDigits: 0 },
    })

    return (
      <BoundingSize
        defaultHeight={height}
        defaultWidth={width}
        render={({ width, height }) => {
          // Limit number of labels on the axis. Still needs to be tweaked based on the length of the max label (x chars)
          const tickCountX = Math.max(
            1,
            Math.min(
              totalPointsX,
              Math.ceil(
                (width - defaultPadding.left - defaultPadding.right) /
                  xAxisLabelSpace,
              ),
            ),
          )
          const tickCountY = Math.max(
            1,
            Math.ceil(
              (height - defaultPadding.top - defaultPadding.bottom) / 50,
            ),
          )

          return (
            <FlexibleContainer height={height}>
              <VictoryChart
                theme={theme}
                width={width}
                height={height}
                padding={defaultPadding}
                domain={normalizeScale ? { y: [0, 1] } : undefined}
                domainPadding={domainPadding}
                containerComponent={containerComponent}
                colorScale={colorScale}
                {...restProps}
              >
                {xAxis?.length ? (
                  xAxis.map((axis, index) => (
                    <VictoryAxis
                      key={`xAxis-${index}`}
                      tickCount={tickCountX}
                      {...axis}
                    />
                  ))
                ) : (
                  <VictoryAxis tickCount={tickCountX} />
                )}

                {yAxis?.length ? (
                  yAxis.map((axis, index) => (
                    <VictoryAxis
                      key={`yAxis-${index}`}
                      dependentAxis
                      tickCount={tickCountY}
                      tickFormat={t =>
                        formatFn(t * (graphBoundaries?.maxY || 0))
                      }
                      {...axis}
                      style={{
                        ...axis.style,
                        axisLabel: {
                          padding: 40,
                          fontSize: 18,
                        },
                      }}
                    />
                  ))
                ) : (
                  <VictoryAxis dependentAxis tickCount={tickCountY} />
                )}

                {/* todo: support other graph types when needed */}
                {scatter ? (
                  graphs?.map(({ id, data, props, enabled }) => {
                    return enabled ? (
                      <VictoryGroup data={data} style={props?.style} key={id}>
                        <VictoryLine name={id} style={props?.style} />
                        <VictoryScatter
                          name={`${id}-scatter`}
                          style={{ data: { fill: props?.style?.data?.stroke } }}
                          size={props => (props.active ? 6 : 3)}
                        />
                      </VictoryGroup>
                    ) : null
                  })
                ) : stack ? (
                  <VictoryStack
                    containerComponent={
                      <VictoryContainer preserveAspectRatio="none" />
                    }
                  >
                    {graphs.map(renderGraph)}
                  </VictoryStack>
                ) : (
                  graphs.map(renderGraph)
                )}
              </VictoryChart>
            </FlexibleContainer>
          )
        }}
      />
    )
  },
)
