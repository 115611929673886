import React from 'react'
import { useTranslation } from 'react-i18next'
import { WorkloadCategory } from 'src/next/components/Workloads/WorkloadsTable/types'
import {
  ContentSwitcher,
  ContentSwitcherProps,
  Switch,
} from '../../../components/ContentSwitcher'
import { isProduction } from 'src/next/utils'
import { FeatureFlag } from 'src/next/components'
import { useUserSelf } from 'src/api'

interface WorkloadCategoryProps extends ContentSwitcherProps {
  category: WorkloadCategory
}

const WorkloadCategorySwitcher = ({
  category,
  onChange,
}: WorkloadCategoryProps): JSX.Element => {
  const { t } = useTranslation()

  const { data: user } = useUserSelf({ enabled: isProduction() })

  if (isProduction()) {
    return (
      <ContentSwitcher
        size="sm"
        selectedName={category}
        onChange={onChange}
        minSwitchWidth={180}
      >
        <Switch name="kubernetes" text={t('Common.Kubernetes', 'Kubernetes')} />
        <Switch
          name="vm"
          text={t('Common.compute_instances', 'Compute instances')}
        />
      </ContentSwitcher>
    )
  } else {
    return (
      <FeatureFlag>
        <ContentSwitcher
          size="sm"
          selectedName={category}
          onChange={onChange}
          minSwitchWidth={180}
        >
          <Switch
            name="kubernetes"
            text={t('Common.Kubernetes', 'Kubernetes')}
          />
          <Switch
            name="vm"
            text={t('Common.compute_instances', 'Compute instances')}
          />
          <Switch name="ecs" text={t('Common.ecs', 'AWS ECS')} />
        </ContentSwitcher>
      </FeatureFlag>
    )
  }
}

export default WorkloadCategorySwitcher
