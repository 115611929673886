import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/next/components'
import {
  TableFilter,
  TableFilterAccordion,
  TableFilterAccordionItem,
  TableFilterActiveItems,
  TableFilterCheckbox,
  TableFilterCheckboxGroup,
  TableFilterSearchSelection,
} from 'src/next/components/TableFilter'

export const AuditLogFilters = () => {
  const { t } = useTranslation()

  return (
    <TableFilter>
      <TableFilterActiveItems />
      <TableFilterAccordion>
        <TableFilterAccordionItem title={t('AuditLogs.DataTable.Subject')}>
          <TableFilterSearchSelection
            id="subject"
            placeholder={t('AuditLogs.DataTable.Subject')}
          />
        </TableFilterAccordionItem>
        <TableFilterAccordionItem title={t('AuditLogs.DataTable.DataSource')}>
          <TableFilterSearchSelection
            id="dataSource"
            placeholder={t('AuditLogs.DataTable.DataSource')}
          />
        </TableFilterAccordionItem>
        <TableFilterAccordionItem title={t('AuditLogs.DataTable.TargetType')}>
          <TableFilterSearchSelection
            id="targetType"
            placeholder={t('AuditLogs.DataTable.TargetType')}
          />
        </TableFilterAccordionItem>
        <TableFilterAccordionItem title={t('AuditLogs.DataTable.Target')}>
          <TableFilterSearchSelection
            id="target"
            placeholder={t('AuditLogs.DataTable.Target')}
          />
        </TableFilterAccordionItem>
        <TableFilterAccordionItem title={t('AuditLogs.DataTable.Action')}>
          <TableFilterCheckboxGroup group="action">
            <Grid gridTemplateColumns="1fr 1fr">
              {['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].map(method => (
                <TableFilterCheckbox
                  key={method}
                  labelText={method}
                  value={method}
                  id={method}
                />
              ))}
            </Grid>
          </TableFilterCheckboxGroup>
        </TableFilterAccordionItem>
        <TableFilterAccordionItem title={t('AuditLogs.DataTable.ResponseCode')}>
          <TableFilterSearchSelection
            id="responseCode"
            placeholder={t('AuditLogs.DataTable.ResponseCode')}
          />
        </TableFilterAccordionItem>
        <TableFilterAccordionItem title={t('AuditLogs.DataTable.EventType')}>
          <TableFilterSearchSelection
            id="eventType_verb"
            placeholder={t('AuditLogs.DataTable.EventType')}
          />
        </TableFilterAccordionItem>
      </TableFilterAccordion>
    </TableFilter>
  )
}
