import dayjs from 'dayjs'
import { useQuery } from 'react-query'
import { GetAggregatedSummaryResponse } from '@cloudnatix-types/dashboard'
import { useRequestParams, fetchData } from 'src/api'
import { TimePeriod } from './types'

const url = 'v1/dashboard/kubernetes/summaries:aggregateGet'

export const useSummariesAggregate = (timeFilter: TimePeriod) => {
  if (!['daily', 'weekly', 'monthly'].includes(timeFilter))
    throw new Error(
      `Invalid filter '${timeFilter}' passed to useSummariesAggregateGetQuery.`,
    )

  const summaryInterval = timeFilter.toUpperCase()

  // first round to a whole hour (if now is 07:15 › 07:00) before subtracting
  // the time. This so that React Query can at least store the data for an hour
  const lastHour = dayjs().startOf('hour')
  const endTimeNs = lastHour.valueOf() * 1000000

  const startTimeNs =
    timeFilter === 'daily'
      ? lastHour.subtract(24, 'hours').valueOf() * 1000000
      : timeFilter === 'weekly'
      ? lastHour.subtract(7, 'days').valueOf() * 1000000
      : timeFilter === 'monthly'
      ? lastHour.subtract(1, 'month').valueOf() * 1000000
      : undefined

  if (!startTimeNs) {
    throw new Error(`Not able to get the time for filter ${timeFilter}`)
  }

  const request = {
    filter: {
      startTimeNs,
      endTimeNs,
    },
    summaryType: '3', // org
    summaryInterval,
  }

  const params = useRequestParams({ request, addOrgFilter: true })

  // work around for be missing org UUID error
  // @ts-ignore
  params.orgUuid = params['filter.orgUuid']

  return useQuery<GetAggregatedSummaryResponse>(
    [url, params],
    () => fetchData<GetAggregatedSummaryResponse>(url, params),
    {
      retry: false,
    },
  )
}
