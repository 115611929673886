import React, { useState } from 'react'
import { Pagination, PaginationProps } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { UseInfiniteQueryResult } from 'react-query'
import styled from 'styled-components'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { removeFocusFromButton } from 'src/next/utils'

const StyledPagination = styled(Pagination)`
  .cds--select--inline .cds--select-input {
    background-color: var(--cds-layer-01);
    width: inherit;
  }
`

interface PaginationChange {
  page: number
  pageSize: number
}

export const usePagination = (key: string, initialPageSize = 10) => {
  const [pageSize, setPageSize] = useLocalStorage(key, initialPageSize)
  const [page, setPage] = useState(1)

  const resetPage = () => setPage(1)

  const onChange = ({ page, pageSize }: PaginationChange) => {
    setPage(page)
    setPageSize(pageSize)
  }

  return {
    onChange,
    pageSize,
    page,
    setPage,
    resetPage,
  }
}

interface DataTablePaginationProps
  extends Omit<PaginationProps, 'pageSizes' | 'onChange'> {
  page?: number
  pageSizes?: number[]
  onChange?(data: PaginationChange): void
  query?: UseInfiniteQueryResult<any, Error>
}

export const DataTablePagination = ({
  pageSize,
  page = 1,
  onChange,
  query,
  pageSizes = [10, 20, 30, 40, 50],
  totalItems,
  ...rest
}: DataTablePaginationProps) => {
  const { t } = useTranslation()

  const { data, fetchNextPage, fetchPreviousPage, hasNextPage } = query || {}
  // If totalItem is not provided, we try to calculate it from the first page.
  if (totalItems === undefined) {
    const firstPage = data?.pages[0]
    totalItems =
      firstPage?.totalSize ??
      (firstPage?.rows && firstPage.metadata !== undefined
        ? firstPage.rows.length + (firstPage.metadata.remainingItemCount || 0)
        : undefined)
  }
  const pagesCount =
    data?.pages.length ||
    (totalItems >= 0 ? Math.ceil(totalItems / pageSize) : 0)

  const handleChange = ({ page: newPage, pageSize }: PaginationChange) => {
    // Remove focus from the next/previous page button to hide the tooltip
    removeFocusFromButton()

    onChange?.({ page: newPage, pageSize })

    if (newPage === page + 1 && newPage > pagesCount) fetchNextPage?.()

    if (newPage === page - 1) fetchPreviousPage?.()
  }

  const pagesUnknown = !totalItems || totalItems === -1

  return (
    <StyledPagination
      pageInputDisabled
      pageSizes={pageSizes}
      pageSize={pageSize}
      page={page}
      isLastPage={
        page >= pagesCount && hasNextPage !== undefined && !hasNextPage
      }
      totalItems={totalItems}
      pagesUnknown={pagesUnknown}
      onChange={handleChange}
      // @ts-expect-error // not typed
      disabled={query && (query.isError || query.isFetching)}
      backwardText={t('Pagination.PreviousPage')}
      forwardText={t('Pagination.NextPage')}
      itemText={(min, max) => t('Pagination.ItemText', { min, max })}
      itemRangeText={(min, max, total) =>
        pagesUnknown ||
        (query && (query.isError || query.isLoading || query.isFetching))
          ? ''
          : t('Pagination.ItemRangeText', {
              min,
              max,
              total,
              count: total, // Pluralization
            })
      }
      itemsPerPageText={t('Pagination.ItemsPerPage')}
      pageNumberText={t('Pagination.PageNumber')}
      {...rest}
    />
  )
}
