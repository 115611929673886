import { useMemo } from 'react'
import { CheckboxSkeleton, SkeletonText } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { useClusters } from 'src/api'
import {
  TableFilterAccordionItem,
  TableFilterCheckbox,
  TableFilterCheckboxGroup,
  useTableFilter,
} from 'src/next/components/TableFilter'
import { CSP } from './CSP'

export const TableCSPFilter = (): JSX.Element => {
  const { t } = useTranslation()

  const { isOpen } = useTableFilter()

  const { data, isLoading } = useClusters({
    enabled: isOpen,
  })

  const cspset = new Set()
  const csps: string[] = []
  data?.clusters?.forEach(cluster => {
    if (!cluster.csp) return
    if (cspset.has(cluster.csp)) return
    cspset.add(cluster.csp)
    csps.push(cluster.csp)
  })
  csps.sort((a, b) => a.localeCompare(b))

  const cspItems = useMemo(() => {
    return (
      csps.map(csp => {
        return (
          <TableFilterCheckbox
            key={csp}
            id={csp}
            activeFilterText={csp}
            labelText={<CSP key={csp} csp={csp} />}
          />
        )
      }) || []
    )
  }, [data])

  return (
    <TableFilterAccordionItem title={t('Clusters.Filters.CSPs')}>
      <TableFilterCheckboxGroup group="csps">
        {isLoading ? (
          <>
            <CheckboxSkeleton />
            <CheckboxSkeleton />
            <CheckboxSkeleton />
            <SkeletonText width="90px" />
          </>
        ) : (
          cspItems
        )}
      </TableFilterCheckboxGroup>
    </TableFilterAccordionItem>
  )
}
