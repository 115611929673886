import { useQuery, UseQueryOptions } from 'react-query'
import {
  GetTopSummariesRequest,
  GetTopSummariesResponse,
} from '@cloudnatix-types/dashboard'
import { fetchData, useRequestParams } from 'src/api'

type TopSummariesFilter = {
  startTimeNs: string
  endTimeNs: string
  orgUuid?: string
}
interface ListTopSummariesRequest extends GetTopSummariesRequest {
  filter: TopSummariesFilter
}

const url = 'v1/dashboard/kubernetes/summaries:rollup'

export function useSummariesRollupQuery<T = GetTopSummariesResponse>(
  request: ListTopSummariesRequest,
  options?: UseQueryOptions<GetTopSummariesResponse, Error, T>,
) {
  const params = useRequestParams({ request, addOrgFilter: true })

  return useQuery<GetTopSummariesResponse, any, T>(
    [url, params],
    () => fetchData<GetTopSummariesResponse>(url, params),
    {
      cacheTime: 3600000, // 60 * 60 * 1000 = 1 hour
      staleTime: 3600000, // 60 * 60 * 1000 = 1 hour
      retry: false,
      ...options,
    },
  )
}
