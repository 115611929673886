import React from 'react'
import { useTranslation } from 'react-i18next'
import { useClusters } from 'src/api'
import {
  TableFilter,
  TableFilterAccordion,
  TableFilterAccordionItem,
  TableFilterActiveItems,
  TableFilterDropdown,
  TableFilterSearchSelection,
  useTableFilter,
} from 'src/next/components/TableFilter'

export const ResourcesTableFilters = () => {
  const { t } = useTranslation()
  const { isOpen } = useTableFilter()

  const { data: clusters, isLoading } = useClusters({
    enabled: isOpen,

    select: data => {
      return data?.clusters?.map(c => c.name || c.uuid!)
    },
  })

  return (
    <TableFilter>
      <TableFilterActiveItems />
      <TableFilterAccordion>
        <TableFilterAccordionItem title={t('Organizations.Type')}>
          <TableFilterDropdown
            id="type"
            placeholder="Cluster / Instance"
            items={['CLUSTER', 'COMPUTE_INSTANCE']}
            isLoading={isLoading}
          />
        </TableFilterAccordionItem>

        <TableFilterAccordionItem title={t('Common.Region')}>
          <TableFilterSearchSelection
            id="region"
            placeholder={t('Common.Region')}
          />
        </TableFilterAccordionItem>

        <TableFilterAccordionItem title={t('Common.Cluster')}>
          <TableFilterDropdown
            id="clusterName"
            placeholder={t('ConnectCluster.SelectCluster')}
            items={clusters || []}
            isLoading={isLoading}
          />
        </TableFilterAccordionItem>

        <TableFilterAccordionItem title={t('Organizations.DataTable.Csp')}>
          <TableFilterSearchSelection
            id="csp"
            placeholder="aws / gcp / azure / oci"
          />
        </TableFilterAccordionItem>
      </TableFilterAccordion>
    </TableFilter>
  )
}
