import React, { createContext, ReactNode } from 'react'
import { useTableFilter } from '../TableFilterContext'

export interface TableFilterCheckboxGroupContextProps {
  group: string
}
export const TableFilterCheckboxGroupContext = createContext<
  TableFilterCheckboxGroupContextProps | undefined
>(undefined)
TableFilterCheckboxGroupContext.displayName = 'TableFilterCheckboxGroupContext'

interface TableFilterCheckboxGroupProps {
  children: ReactNode
  group: string
}

export const TableFilterCheckboxGroup = ({
  children,
  group,
}: TableFilterCheckboxGroupProps) => {
  // prevents a bug when the first checkbox in a group remains checked when the
  // values are reset
  const { registerDefaultValue } = useTableFilter()
  registerDefaultValue(group, [])

  return (
    <TableFilterCheckboxGroupContext.Provider value={{ group }}>
      {children}
    </TableFilterCheckboxGroupContext.Provider>
  )
}
