import React, { ReactNode } from 'react'
import {
  Tabs,
  Tab,
  TabCustomContentProvidedProps,
  TabListProps as CarbonTabListProps,
  TabList as CarbonTabList,
  TabPanel as CarbonTabPanel,
  TabPanels,
} from '@carbon/react'
import clsx from 'clsx'
import styled from 'styled-components'

interface RenderTabContentOnlyWhenSelectedProps
  extends TabCustomContentProvidedProps {
  children?: ReactNode
}

const RenderTabContentOnlyWhenSelected: React.FC<
  RenderTabContentOnlyWhenSelectedProps
> = ({ selected, children, className, ...other }) => {
  if (!selected) {
    return <div {...other} className="visually-hidden" />
  }

  return (
    <div {...other} className={clsx(className, 'tab-content')} role="tabpanel">
      {children}
    </div>
  )
}

const StyledTabList = styled(CarbonTabList)`
  .cds--tabs__nav-link {
    width: auto;
    min-width: 9rem;
  }

  &:not(.cds--tabs--light) {
    .cds--tabs--__nav-item--selected {
      // In the Carbon styleguide the page background is almost black so the active
      // tab background stands out. In our app the background is the same color as
      // the active tab so we want to outline the start and end of the tab bar a bit.
      box-shadow: 1px 0 0 inset var(--cds-layer-04);

      &:first-child {
        box-shadow: 1px 0 0 inset var(--cds-layer-03);
      }

      &:last-child {
        box-shadow: -1px 0 0 inset var(--cds-layer-03) !important;
      }
    }
  }
`

const TabPanel = styled(CarbonTabPanel)`
  padding-left: 0;
  padding-right: 0;
`

interface TabListProps extends CarbonTabListProps {
  children: any
}

export const TabList = ({ children, contained = true, ...rest }: TabProps) => (
  <StyledTabList contained={contained} {...rest}>
    {children}
  </StyledTabList>
)

export { Tab, Tabs, TabPanels, TabPanel }
export type { TabListProps }
