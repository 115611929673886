import React from 'react'
import { Add, TrashCan } from '@carbon/react/icons'
import { Button, Select, SelectItem, TextInput } from '@carbon/react'
import { useController, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useOrgs } from 'src/api'
import { AllocateFormValues } from './ResourcesAllocateModal'

const RoleGroup = styled.div`
  margin: var(--cds-spacing-05) 0 var(--cds-spacing-03) 0;
  display: flex;
  align-items: flex-end;
  gap: var(--cds-spacing-05);
`

const ButtonContainer = styled.div`
  margin: var(--cds-spacing-05) 0 var(--cds-spacing-03) 0;
`

export const ResourcesAllocateForm = ({ isEditMode = false }) => {
  const { t } = useTranslation()

  const { data } = useOrgs()

  const {
    register,
    formState: { errors },
  } = useFormContext()

  const rules = { required: true }
  const { field: name } = useController({ name: 'name', rules })
  const { field: orgUuid } = useController({
    name: 'orgUuid',
    rules,
  })
  const { fields, append, remove } = useFieldArray<AllocateFormValues>({
    name: 'labelFilters',
  })

  return (
    <>
      <TextInput
        {...name}
        autoFocus
        id="resource-allocation-name"
        labelText={t('Resources.Allocation.Form.Name')}
        invalid={!!errors.name}
        invalidText={t('Resources.Allocation.Form.NameError')}
        helperText={t('Resources.Allocation.Form.NameHelperText')}
      />
      <br />
      <Select
        {...orgUuid}
        id="resource-allocation-org"
        labelText={t('Resources.Allocation.Form.TargetOrg')}
        disabled={isEditMode}
      >
        <SelectItem text={t('Resources.Allocation.Form.SelectOrg')} value="" />
        {data?.orgs?.map(org => (
          <SelectItem key={org.uuid} value={org.uuid} text={org.name || ''} />
        ))}
      </Select>
      <br />
      <>
        {t('Resources.Allocation.Form.LabelFilters')}
        {fields.map((label, index) => {
          return (
            <RoleGroup key={label.id}>
              <TextInput
                id={`labelFilter-key-${index}`}
                labelText={t('Resources.Allocation.Form.Key')}
                {...register(`labelFilters.${index}.key` as const, {
                  required: true,
                })}
                defaultValue={label.key}
              />
              <TextInput
                id={`labelFilter-value-${index}`}
                labelText={t('Resources.Allocation.Form.Value')}
                {...register(`labelFilters.${index}.value` as const, {
                  required: true,
                })}
                defaultValue={label.value}
              />
              {fields.length > 1 && (
                <Button
                  size="md"
                  kind="ghost"
                  hasIconOnly
                  tooltipPosition="left"
                  tooltipAlignment="start"
                  renderIcon={TrashCan}
                  onClick={() => remove(index)}
                  iconDescription={t('Common.Delete')}
                />
              )}
            </RoleGroup>
          )
        })}
        <ButtonContainer>
          <Button
            kind="tertiary"
            size="sm"
            renderIcon={Add}
            onClick={() => {
              append({
                key: '',
                value: '',
              })
            }}
          >
            {t('Resources.Allocation.Form.AddLabelFilter')}
          </Button>
        </ButtonContainer>
      </>
    </>
  )
}
