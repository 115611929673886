import { useState } from 'react'
import { useUrlSearchParams } from 'src/next/hooks'

export const useTabSelection = (tabIds: string[]) => {
  const { tabs, pod: selectedPod } = useUrlSearchParams()

  const parsedTab = tabs?.split(',')[0]

  const initiallySelectedTab = parsedTab
    ? Math.max(
        0,
        tabIds.findIndex(
          value => value.toLowerCase() === parsedTab.toLowerCase(),
        ),
      )
    : 0

  const [selectedTab, setSelectedTab] = useState(initiallySelectedTab)

  return { selectedTab, setSelectedTab, selectedPod }
}
