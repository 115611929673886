import { useQuery, UseQueryOptions } from 'react-query'
import {
  ListComputeInstanceSummariesRequest,
  ListComputeInstanceSummariesResponse as ResponseType,
  SummaryInterval,
} from '@cloudnatix-types/dashboard'
import { fetchData } from 'src/api/axios'
import { useRequestParams } from 'src/api/useRequestParams'
import { useTimeRangeOverflowMenu } from 'src/next/components/dashboard/timerange'
import { TimeRange } from 'src/next/components/dashboard/timerange/TimerangeTypes'
import { useOrgSelection } from 'src/next/hooks'

const url = '/v1/dashboard/infrastructure/compute/instancesummaries'

export const useInstanceSummaries = <T = ResponseType>(
  request: ListComputeInstanceSummariesRequest,
  options?: UseQueryOptions<ResponseType, any, T>,
) => {
  const params = useRequestParams({ request, addOrgFilter: true })

  return useQuery<ResponseType, any, T>(
    [url, params],
    () => fetchData<ResponseType>(url, params),
    {
      cacheTime: 3600000, // 60 * 60 * 1000 = 1 hour
      retry: false,
      ...options,
    },
  )
}

export const useSummariesMetrics = (id: string) => {
  const { selectedItem, startTimeNs, endTimeNs } = useTimeRangeOverflowMenu({
    id,
  })

  const [orgUuid] = useOrgSelection()

  return useInstanceSummaries(
    {
      filter: {
        startTimeNs,
        endTimeNs,
        grouping: {
          groupingValue: orgUuid,
          duration: getFilterGroupingDuration(selectedItem.id) as any,
          groupingKey: {
            key: 'ORG' as any,
          },
        },
      },
    },
    {
      select: data => ({
        ...data,
        // Return the selected time range for easy access
        selectedTimeRange: selectedItem,
      }),
    },
  )
}

export const getFilterGroupingDuration = (
  timeRange: TimeRange,
): keyof typeof SummaryInterval => {
  switch (timeRange) {
    case 'week':
      return 'DAILY'
    case 'month':
      return 'DAILY'
    case '6months':
      return 'WEEKLY'
    default:
      return 'DAILY'
  }
}
