import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { WorkloadResources as WorkloadResourcesType } from '@cloudnatix-types/dashboard'
import { Tooltip, TooltipButton } from 'src/next/components/Tooltip'
import { ProgressDonut } from 'src/next/components/ui'
import { bytesToUserFriendlySize } from 'src/next/utils'

const RightAlignedCell = styled.td`
  text-align: right;
  padding-right: 0.2em;
`

const LeftAlignedCell = styled.td`
  text-align: left;
  padding-left: 0.2em;
  padding-right: 0.2em;
`

const StyledTooltipButton = styled(TooltipButton)`
  color: var(--data-table-cell-text-color, var(--cds-text-secondary));
  &&:hover {
    color: var(--data-table-cell-text-color, var(--cds-text-secondary));
  }
`

interface WorkloadResourcesProps {
  resources: WorkloadResourcesType
  hideTooltips?: boolean
}

const formatMillis = (value: number) => {
  if (value === 0) {
    return '---'
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
  }).format(value)
}

interface ResourceFieldProps {
  limit: number
  request: number
  usage: number
  // eslint-disable-next-line @typescript-eslint/ban-types
  label?: (value: number) => {}
}

const CpuLabel = ({ limit, request, usage, label }: ResourceFieldProps) => {
  if (request === 0) {
    return <div>---</div>
  }

  return (
    <ProgressDonut
      percent={Math.round((usage / (limit || request)) * 100)}
      label={`${formatMillis(request)} ${label ? label(request) : null}`}
    />
  )
}

const MemoryLabel = ({ limit, request, usage }: ResourceFieldProps) => {
  if (request === 0) {
    return <div>---</div>
  }

  return (
    <ProgressDonut
      percent={Math.round((usage / (limit || request)) * 100)}
      label={bytesToUserFriendlySize(request)}
    />
  )
}

export const WorkloadResources = ({
  resources,
  hideTooltips,
}: WorkloadResourcesProps) => {
  const { t } = useTranslation()

  const cpu = {
    usage: Number(resources?.cpu?.usage) / 1000,
    limit: Number(resources?.cpu?.limit) / 1000,
    request: Number(resources?.cpu?.request) / 1000,
    label: (value: number) => t('Common.Cores', { count: value }),
  }

  const memory = {
    usage: Number(resources?.memory?.usage),
    limit: Number(resources?.memory?.limit),
    request: Number(resources?.memory?.request),
  }

  const resourcesLabel = (
    <StyledTooltipButton>
      <CpuLabel {...cpu} />
      <MemoryLabel {...memory} />
    </StyledTooltipButton>
  )

  if (hideTooltips || (cpu.request === 0 && memory.request === 0)) {
    return resourcesLabel
  }

  const tooltipLabel = (
    <>
      <strong>{t('Common.CPU')}</strong>
      <table>
        <tbody>
          {/* TODO: "used" is not filled in by the back-end yet */}
          {/*<tr>*/}
          {/*  <CellValue>*/}
          {/*    {formatMillis(cpu.usage)} {cpu.label(cpu.usage)}*/}
          {/*  </CellValue>*/}
          {/*  <td>{t('Workload.Resources.used')}</td>*/}
          {/*</tr>*/}
          <tr>
            <LeftAlignedCell>
              {t('Workload.Resources.requested')}
            </LeftAlignedCell>
            <RightAlignedCell>
              {formatMillis(cpu.request)} {cpu.label(cpu.request)}
            </RightAlignedCell>
          </tr>
          <tr>
            <LeftAlignedCell>{t('Workload.Resources.limit')}</LeftAlignedCell>
            <RightAlignedCell>
              {formatMillis(cpu.limit)} {cpu.label(cpu.limit)}
            </RightAlignedCell>
          </tr>
        </tbody>
      </table>
      <br />
      <strong>{t('Common.Memory')}</strong>
      <table>
        <tbody>
          {/* "used" is not filled in by the back-end yet */}
          {/*<tr>*/}
          {/*  <CellValue>{usage}</CellValue>*/}
          {/*  <td>{t('Workload.Resources.used')}</td>*/}
          {/*</tr>*/}
          <tr>
            <LeftAlignedCell>
              {t('Workload.Resources.requested')}
            </LeftAlignedCell>
            <RightAlignedCell>
              {bytesToUserFriendlySize(memory.request)}
            </RightAlignedCell>
          </tr>
          <tr>
            <LeftAlignedCell>{t('Workload.Resources.limit')}</LeftAlignedCell>
            <RightAlignedCell>
              {bytesToUserFriendlySize(memory.limit)}
            </RightAlignedCell>
          </tr>
        </tbody>
      </table>
    </>
  )

  return (
    <Tooltip label={tooltipLabel} tabIndex={-1} align="bottom">
      {resourcesLabel}
    </Tooltip>
  )
}
