import { DefinitionTooltip } from '@carbon/react'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'

export interface TooltipProps {
  children: string | ReactElement | ReactElement[]
  tooltipText: string
  onClick?: () => void
  afterClickText?: string
  direction?: 'top' | 'bottom'
}

const StyledDefinitionTooltip = styled(DefinitionTooltip)`
  display: flex;
  align-items: center;
  white-space: nowrap;

  .cds--definition-tooltip {
    max-inline-size: none;
  }

  .cds--definition-term {
    font-size: var(--cds-body-01-font-size);
    border-bottom: none;
    color: inherit;
    cursor: pointer;
  }
`

export const Tooltip = ({
  children,
  tooltipText,
  onClick,
  afterClickText,
  direction,
}: TooltipProps) => {
  const [showAfterClickText, setShowAfterClickText] = useState(false)

  useEffect(() => {
    if (showAfterClickText) {
      setTimeout(() => {
        setShowAfterClickText(false)
      }, 2000)
    }
  }, [showAfterClickText])

  return (
    <StyledDefinitionTooltip
      direction={direction}
      definition={
        showAfterClickText && afterClickText ? afterClickText : tooltipText
      }
      openOnHover={!onClick}
      onClick={() => {
        onClick && onClick()
        setShowAfterClickText(true)
      }}
    >
      {children}
    </StyledDefinitionTooltip>
  )
}
