import { TFunction } from 'react-i18next'
import { TimeRange, TimeRangeOption } from './TimerangeTypes'

// Disabled in CNATIX-2199 - will be enabled again once supported by BE
// {
//   id: 'hour',
//   label: t('Common.PastX', { val: '$t(Common.Time.Hour)' }),
// },
// {
//   id: '12_hours',
//   label: t('Common.PastX', { val: '12 $t(Common.Time.Hours)' }),
// },

interface Props {
  t: TFunction
  show24HoursOption: boolean
}

export const getTimeRangeOptions = ({
  t,
  show24HoursOption = true,
}: Props): TimeRangeOption[] => [
  ...(show24HoursOption
    ? [
        {
          id: '24_hours' as TimeRange,
          label: t('Common.PastX', { val: '24 $t(Common.Time.Hours)' }),
        },
      ]
    : []),
  {
    id: 'week',
    label: t('Common.PastX', { val: '$t(Common.Time.Week)' }),
  },
  {
    id: 'month',
    label: t('Common.PastX', { val: '$t(Common.Time.Month)' }),
  },
  {
    id: '6months',
    label: t('Common.PastX', { val: '6 $t(Common.Time.Months)' }),
  },
]

// t(Common.Time.Hour)
// t(Common.Time.Hours)
// t(Common.Time.Hours)
// t(Common.Time.Week)
// t(Common.Time.Month)
// t(Common.Time.Months)
